import { AccountRepository } from '../domain/AccountRepository'

export class CheckBranchName {
  private readonly repository: AccountRepository

  constructor(repository: AccountRepository) {
    this.repository = repository
  }

  public async execute(branch_name: string): Promise<boolean> {
    return this.repository.checkBranchName(branch_name);
  }
}
