import { Directive, ElementRef, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[default-img]'
})
export class Environments {

  constructor() {
  }

  public static getS3UrlEnvironment(): string {
    if(environment.production){
        return 'https://s3.eu-west-3.amazonaws.com/liceo.web/production';
    }else{
        return 'https://s3.eu-west-3.amazonaws.com/liceo.web/staging';
    }
  }
}