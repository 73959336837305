import { AccountRepository } from '../domain/AccountRepository'
import { Logout } from '../domain/Account'

export class AccountLogout {
  private readonly repository: AccountRepository

  constructor(repository: AccountRepository) {
    this.repository = repository
  }

  public async execute(identifier: number, token: string): Promise<Logout | undefined> {
    return this.repository.logout(identifier, token)
  }
}
