import { Component, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable, Subscription } from 'rxjs'
import { updateErrorSelector } from 'src/app/state/error.selectors';

@Component({
  selector: 'app-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.scss']
})
export class ModalErrorComponent implements OnInit, OnDestroy {
  private readonly store: Store
  private readonly updateErrorSelector$: Observable<readonly string[]> = new Observable()
  private subcriptionError!: Subscription | undefined

  constructor(
    store: Store
  ) {
    this.store = store
    this.updateErrorSelector$ = this.store.select(updateErrorSelector)
  }

  ngOnInit(): void {
    this.subcriptionError = this.updateErrorSelector$.subscribe(([message]) => {
      if (message) {
        // alert(message)
      }
    })
  }

  ngOnDestroy(): void {
    // If this component destroy remove the subscription
    this.subcriptionError?.unsubscribe()
  }
}
