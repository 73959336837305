<div *ngIf="buttonAvaible && !isVisibleSelectSpaces" class="button">
    <div (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" (click)="toggleModal()" class="icon-wrap-desktop">
        <img *ngIf="!modalOpen" [src]="iconSrc" alt="">
        <img *ngIf="modalOpen" class="close-icon" src="../../../../assets/svg/close_icon.svg" alt="">
    </div>
    <div (click)="toggleModal()" class="icon-wrap-mobile">
        <img *ngIf="!modalOpen" [src]="iconSrc" alt="">
        <img *ngIf="modalOpen" class="close-icon" src="../../../../assets/svg/close_icon.svg" alt="">
    </div>
</div>
<div *ngIf="buttonAvaible && modalOpen" class="modal glass-1">
    <div class="field-wrap">
        <p class="semibold">TEMA DE LA CONSULTA</p>
        <app-lf-input-text #themeInput placeholder="Ej: Cursos" (input)="onChangeTheme($event)"></app-lf-input-text>
    </div>
    <div class="field-wrap">
        <p class="semibold">DESCRIPCIÓN</p>
        <app-lf-text-area #descInput placeholder="¿En qué podemos ayudarte? Por favor, proporciona un breve resumen de tu consulta."
            (input)="onChangeDescription($event)" height="92px"></app-lf-text-area>
    </div>
    <p class="info">Daremos respuesta a tu consulta lo antes posible, a través de email</p>
    <app-button (onClick)="onSendSupportRequest()" link="/login" [disable]="!sendAvaible" [type]="1" text="ENVIAR"></app-button>
</div>
<div *ngIf="buttonAvaible && !modalOpen" class="tooltip-desktop glass-2">
    <p class="bold">Soporte Liceo</p>
    <p>¿Cómo podemos ayudarte?</p>
</div>