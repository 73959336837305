import { CourseRepository } from '../domain/CourseRepository'
import { Category } from '../domain/Course'

export class GetCategories {
  private readonly repository: CourseRepository

  constructor(repository: CourseRepository) {
    this.repository = repository
  }

  public async execute(): Promise<Category[] | undefined> {
    return this.repository.getCategories()
  }
}
