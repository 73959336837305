import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ChangePassword } from 'src/app/cross/account/application/ChangePassword';
import { Account } from 'src/app/cross/account/domain/Account';
import { LoggerProviderService } from 'src/app/services/logger/logger-provider.service';
import { StorageService } from 'src/app/services/storage.service';
import { TranslateService } from 'src/app/services/translate.service';
import { CustomValidators } from 'src/app/utils/CustomValidators';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  
  @Output() changePasswordSubmit = new EventEmitter<boolean>();

  public formGroup!: FormGroup;
  public hasPasswordError: boolean = false
  public passwordMessageError: string = ''
  public hasPasswordConfirmationError: boolean = false
  public passwordConfirmationMessageError: string = ''

  public matchError: boolean = false;

  private readonly _changePassword: ChangePassword;

  constructor(
    private translateService: TranslateService,
    private loggerProviderService: LoggerProviderService,
    private storageService: StorageService,
    changePassword: ChangePassword,
    private customValidators: CustomValidators
  ) { 
    this._changePassword = changePassword;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm() {
    this.formGroup = new FormGroup({
      password: new FormControl(null, [Validators.required,  Validators.minLength(8)]),
      passwordConfirmation: new FormControl(null, ([Validators.required, Validators.minLength(8)])),
    });
  }

  public onSubmit(){
    this.sendForm(this.formGroup?.value.password)
  }

  public async sendForm(password: string) {
    this.checkErrorInputpassword()
    if(this.checkErrorInputpasswordConfirmation())return
    
    if(!this.formGroup.valid) {
      return
    }
    
    try {
      const userData: Account | undefined = await JSON.parse(this.storageService.find(this.storageService.USER_DATA))
      if(userData != null){
        const result = await this._changePassword.execute(userData.token, userData.email, password)
        this.changePasswordSubmit.emit(result);
      }else{
        throw 'Can´t find localStorage user_data';
      }
      
    } catch (err: any) {
      const { message } = err
      this.loggerProviderService.error(err);
    }
  }

  checkErrorInputpassword(): void {
    this.hasPasswordError = this.formGroup.get('password')?.hasError('required') || 
      this.formGroup.get('password')?.hasError('minlength') || false

    this.passwordMessageError = this.formGroup.get('password')?.hasError('required')
      ? this.translateService.translate('login.input_email_message_empty_error')
        : this.formGroup.get('password')?.hasError('minlength')
        ? 'La contraseña debe tener una langitud mínima de 8 caracteres'
            :''
  }
  checkErrorInputpasswordConfirmation(): boolean {
    this.hasPasswordConfirmationError = this.formGroup.get('passwordConfirmation')?.hasError('required') || 
      this.formGroup.get('passwordConfirmation')?.hasError('minlength') || false

    this.passwordMessageError = this.formGroup.get('password')?.hasError('required')
      ? this.translateService.translate('login.input_email_message_empty_error')
        : this.formGroup.get('password')?.hasError('minlength')
        ? 'La contraseña debe tener una langitud mínima de 8 caracteres'
            :''
    this.matchError = this.formGroup.get('password')?.value != this.formGroup.get('passwordConfirmation')?.value;
    return this.formGroup.get('password')?.value != this.formGroup.get('passwordConfirmation')?.value;
  }
}
