import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ManageLoadingScreenService } from './manage-loading-screen.service';
import { AnimationService } from 'src/app/services/animations/animation/animation.service';

export type animationNames = {
  none: string,
  sending: string,
}

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit {

  @ViewChild('text') text!: ElementRef;

  constructor(
    private manageLoadingScreen: ManageLoadingScreenService,
    private animationService: AnimationService
  ) {
    this.animationService.createTimeline();
  }

  show: boolean = false;
  texts: string[] = [];
  animation: keyof animationNames = 'none';


  ngOnInit(): void {
    this.manageLoadingScreen.actionLoadingScreen$().subscribe(
      data => {
        this.texts = data.texts;
        this.updateTexts(data.texts);
        this.show = data.show;
        this.animation = data.animation;
        if (this.show) {
          this.deactivateScroll();
        } else {
          this.activateScroll();
        }
      })
  }

  updateTexts(texts: string[]) {
    if(texts.length == 0){
      this.text.nativeElement.innerHTML = '';
      this.animationService.stopTypeWriter();
      return;
    } 
    this.animationService.typeWriter('.text', texts);
  }

  activateScroll() {
    document.getElementsByTagName('body')[0].setAttribute('style', 'overflow: inherit;')
    document.getElementsByTagName('html')[0].setAttribute('style', 'overflow: inherit;')
  }

  deactivateScroll() {
    document.getElementsByTagName('body')[0].setAttribute('style', 'overflow: hidden;')
    document.getElementsByTagName('html')[0].setAttribute('style', 'overflow: hidden;')
  }

}
