import { Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from '../services/translate.service'

@Pipe({
  name: 'translate',
  pure: true
})
export class TranslatePipe implements PipeTransform {
  private lastKey: string | null = null

  private translation: string = ''

  constructor(private translateService: TranslateService) { }

  transform(key: string, args?: any): string {
    if (key === this.lastKey) return this.translation

    const translation = this.translateService.translate(key)
    this.lastKey = key
    this.translation = translation

    return translation
  }
}
