import { EventAction } from '../EventAction';
import { EventBus } from '../EventBus';
import { EventHandler } from '../EventHandler';

export class EventBusInMemory implements EventBus {
  private eventHandlers: EventHandler[]

  constructor() {
    this.eventHandlers = []
  }

  // Method in order to subscribe observers.
  subscribe(eventHandler: EventHandler) {
    this.eventHandlers.push(eventHandler)
  }

  // Method in order to unsubscribe observers.
  unsubscribe(eventHandler: EventHandler) {
    this.eventHandlers = this.eventHandlers.filter((item) => item !== eventHandler)
  }

  // Method in order to notify all observers.
  notify(action: EventAction, data: any) {
    for (const eventHandler of this.eventHandlers) {
      if (eventHandler.action === action) {
        eventHandler.execute(data)
      }
    }
  }
}
