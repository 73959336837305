import { Injectable } from '@angular/core';
// import * as Sentry from '@sentry/browser';
import { environment } from 'src/environments/environment';
import { LoggerProviderService } from './logger-provider.service';
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: 'root'
})
export class LoggerSentryProviderService implements LoggerProviderService {
  constructor() { }

  init(key: string, ): void {
    if (!environment.production)return; 
    Sentry.init({ 
      dsn: key,
      environment: 'production',
      integrations:[
        new Sentry.BrowserTracing({
          tracePropagationTargets: ['liceodefarmacia.com'],
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
        // new Sentry.Replay()
      ],
      tracesSampleRate: 0.2,
      replaysSessionSampleRate: 0.2,
      replaysOnErrorSampleRate: 0.2,
    });
  }
  
  error(err: Error): void {
    Sentry.captureException(err)
  }
  
}
