<div class="modal-background" *ngIf="modalVisible">
    <div class="bk-gradient">
        <div class="bk-gradient-green"></div>
        <div class="bk-gradient-blue"></div>
        <div class="bk-gradient-blue2"></div>
    </div>
    <div class="content">
        <div class=" modal">
            <div *ngIf="step == send_email" class="wrap wrap-send-email">
            <!-- <div *ngIf="false" class="wrap wrap-send-email"> -->
                <h1 class=" underlined lf-second-subtitle bold">{{'forgot_password.recover-text' | translate}}</h1>
                <p class="lf-p-1 regular"><b>{{'forgot_password.text_1' | translate}}</b> {{'forgot_password.text_2' | translate}}</p>
                <div class="center-input">
                    <app-forgot-password class="input-pass" (forgotPasswordSubmit)="onForgotPasswordSubmit($event)"></app-forgot-password>
                </div>
            </div>
            
            <div *ngIf="step == temp_pass" class="wrap wrap-temp-pass">
            <!-- <div *ngIf="false" class="wrap wrap-temp-pass"> -->
                <h1 class="lf-second-subtitle bold underlined">{{'forgot_password.look_email' | translate}}</h1>
                <p class="lf-p-1-2 regular">{{'forgot_password.text_3' | translate}}<b>{{'forgot_password.text_3_1' | translate}} </b>{{'forgot_password.text_3_2' | translate}}<b>{{'forgot_password.text_3_3' | translate}} </b>{{'forgot_password.text_3_4' | translate}}</p>
                <!-- INPUT DE CONTRASEÑA -->
                <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
                    <app-input
                        type="text"
                        [hasError]="hasPasswordError"
                        [messageError]="passwordMessageError"
                        placeholder="Nueva contraseña"
                        (change)="checkErrorInputpassword()"
                        formControlName="password"
                    ></app-input>
                    <app-button [type]="3" [submit]="true" text="{{'forgot_password.btn' | translate}}"></app-button>
                </form>
            </div>
            <div *ngIf="step == change_pass" class="wrap wrap-change-pass">
            <!-- <div *ngIf="false" class="wrap wrap-change-pass"> -->
                <h1 class="lf-second-subtitle bold underlined">{{'forgot_password.change_pass' | translate}}</h1>
                <p>{{'forgot_password.text_4' | translate}} <b>{{'forgot_password.text_4_1' | translate}}</b> {{'forgot_password.text_4_2' | translate}}</p>
                <div class="requirments">
                    <p class="lf-p-1-2 regular">{{'forgot_password.minimun' | translate}}<b>{{'forgot_password.characters' | translate}}</b></p>
                    <p class="lf-p-1-2 regular">{{'forgot_password.at_less' | translate}}<b>{{'forgot_password.one_number' | translate}}</b></p>
                </div>
                <app-change-password (changePasswordSubmit)="onSubmitChangePassword($event)"></app-change-password>
            </div>
            <div *ngIf="step == ready" class="wrap wrap-ready">
            <!-- <div *ngIf="true" class="wrap wrap-ready"> -->
                <div class="ready-block">
                    <img src="../../../../assets/img/Check.png" alt="">
                    <div class="text">
                        <h1 class="lf-second-subtitle bold underlined">{{'forgot_password.ready' | translate}}</h1>
                        <h1 class="lf-second-subtitle bold underlined">{{'forgot_password.password_reseted' | translate}}</h1>
                    </div>
                </div>
                <p class="lf-p-1-2 regular">{{'forgot_password.you' | translate}}<b>{{'forgot_password.pass_saved' | translate}}</b></p>
                <p class="lf-p-1-2 regular">{{'forgot_password.come_back' | translate}} <b>{{'forgot_password.in_site' | translate}}</b></p>
                <app-button [type]="3" (onClick)="closeModal()" [submit]="false" text="IR A LICEO"></app-button>
            </div>
        </div>
        <p *ngIf="step == send_email || step == temp_pass" (click)="closeModal()" class="close-modal underline lf-p-1-2 regular">{{'forgot_password.go_to' | translate}}</p>
    </div>
</div>
