import { EventAction } from 'src/app/cross/share/domain/EventAction'
import { EventHandler } from 'src/app/cross/share/domain/EventHandler'
import { CartRepository } from '../../domain/CartRepository'

export class TheUserLogged implements EventHandler {
  public readonly action = EventAction.THE_USER_HAS_LOGGED_IN
  private readonly repository: CartRepository

  constructor(repository: CartRepository) {
    this.repository = repository
  }

  public async execute({ userToken, saleCode }: { userToken: string, saleCode: string }): Promise<void> {    
    this.repository.setOwnerCart({ userToken, saleCode })
  }
}