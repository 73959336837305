import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';

// Router and Guard
import { AuthGuardService } from './services/auth-guard.service';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { TranslateService } from './services/translate.service';

// Cross
import { AccountSignUp } from './cross/account/application/AccountSignUp'
import { AccountLogin } from './cross/account/application/AccountLogin'
import { AccountLogout } from './cross/account/application/AccountLogout'
import { AccountRepositoryRest } from './cross/account/infrastructure/AccountRepositoryRest';

import { GetAllCourses } from './cross/course/application/GetAllCourses'
import { GetLastCourses } from './cross/course/application/GetLastCourses'
import { GetCategories } from './cross/course/application/GetCategories'
import { GetCourseById } from './cross/course/application/GetCourseById';
import { GetCoursesByCategory } from './cross/course/application/GetCoursesByCategory'
import { CourseRepositoryRest } from './cross/course/infrastructure/CourseRepositoryRest';

import { GetProgramByCourseId } from './cross/program/application/GetProgramByCourseId';
import { ProgramRepositoryRest } from './cross/program/infrastructure/ProgramRepositoryRest';

import { CartSet } from './cross/cart/application/CartSet'
import { CartGet } from './cross/cart/application/CartGet'
import { CartPaid } from './cross/cart/application/CartPaid'
import { CartRepositoryRest } from './cross/cart/infrastructure/CartRepositoryRest';

import { GetAllPlans } from './cross/plan/application/GetAllPlans'
import { PlanRepositoryRest } from './cross/plan/infrastructure/PlanRepositoryRest';


import { Unsubscribe } from './cross/subcription/application/Unsubscribe'
import { SubcriptionRepositoryRest } from './cross/subcription/infrastructure/SubcriptionRepositoryRest';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GetSubscriptions } from './cross/subcription/application/GetSubscriptions';
import { PlansServiceService } from './services/plans-service.service';

// Stripe
import { NgxStripeModule } from "ngx-stripe";

// State Management
import { ROOT_REDUCERS } from './state/app.state'
import { StateService } from './services/state.service';

// Payment Services
import { environment } from 'src/environments/environment';
import { PaymentStripeProviderService } from './services/payment/payment-stripe-provider.service';
import { StorageService } from './services/storage.service';
import { PaymentProviderService } from './services/payment/payment-provider.service';
import { LoggerProviderService } from './services/logger/logger-provider.service';
import { LoggerSentryProviderService } from './services/logger/logger-sentry-provider.service';
import { GetProgram } from './cross/course/application/GetProgram';
import { CheckBranchName } from './cross/account/application/CheckBranchName';
import { HttpClientModule } from '@angular/common/http';

const accountRepositoryRest = new AccountRepositoryRest()
const courseRepositoryRest = new CourseRepositoryRest()
const itineraryRepositoryRest = new ItineraryRepositoryRest()
const crmRepositoryRest = new CrmRepositoryRest()
const certificateRepositoryRest = new CertificateRepositoryRest()
const dashboardRepositoryRest = new DashboardRepositoryRest()
const supportRepositoryRest = new SupportRepositoryRest()
const cartRepositoryRest = new CartRepositoryRest()
const subcriptionRepositoryRest = new SubcriptionRepositoryRest()
const programRepositoryRest = new ProgramRepositoryRest()
const planRepositoryRest = new PlanRepositoryRest()

// Global Error Handler
import { ErrorHandlerService } from './services/error-handler.service';
import { GetFilteredCourses } from './cross/course/application/GetFilteredCourses';

// Event Handlers
import { EventBusInMemory } from "./cross/share/domain/infrastructure/EventBusInMemory";
import { TheUserLogged } from './cross/cart/application/event-handlers/TheUserLogged';
import { SubscribeNewsLetter } from './cross/account/application/SubscribeNewsLetter';
import { GetLmsLoginUrl } from './cross/account/application/GetLmsLoginUrl';
import { PayConfirmation } from './cross/cart/application/PayConfirmation';
import { PasswordForgot } from './cross/account/application/PasswordForgot';
import { ChangePassword } from './cross/account/application/ChangePassword';
import { GetCourses } from './cross/dashboard/aplication/GetCourses';
import { DashboardRepositoryRest } from './cross/dashboard/infrastructure/DashboardRepositoryRest';
import { ReportHolderByUser } from './cross/dashboard/aplication/ReportHolderByUser';
import { GetItineraries } from './cross/dashboard/aplication/GetItineraries';
import { GetUrlFromCourse } from './cross/dashboard/aplication/GetUrlFromCourse';
import { MarkNewsAsViewed } from './cross/dashboard/aplication/MarkNewsAsViewed';
import { DropdownMenuComponent } from './components/atoms/dropdown-menu/dropdown-menu.component';
import { CheckSubscriptions } from './cross/subcription/application/CheckSubscriptions';
import { GetPharmacyDashboard } from './cross/dashboard/aplication/GetPharmacyDashboard';
import { GetGroupDashboard } from './cross/dashboard/aplication/GetGroupDashboard';
import { GetAvailableNews } from './cross/dashboard/aplication/GetAvailableNews';
import { EnrrollInTheCourse } from './cross/dashboard/aplication/EnrrollInTheCourse';
import { GetCertificateData } from './cross/dashboard/aplication/GetCertificateData';
import { CertificateRepositoryRest } from './cross/certificate/infrastructure/CertificateRepositoryRest';
import { GetCertificatePublicData } from './cross/certificate/application/GetCertificatePublicData';
import { GetUserCourses } from './cross/itineraries/application/GetUserCourses';
import { ItineraryRepositoryRest } from './cross/itineraries/infrastucture/ItineraryRepositoryRest';
import { GetBranchItineraries } from './cross/itineraries/application/GetBranchItineraries';
import { CreateItinerary } from './cross/itineraries/application/CreateItinerary';
import { RemoveItinerary } from './cross/itineraries/application/RemoveItinerary';
import { GetCourseContentTypes } from './cross/itineraries/application/GetCourseContentTypes';
import { EditItinerary } from './cross/itineraries/application/EditItinerary';
import { DuplicateItinerary } from './cross/itineraries/application/DuplicateItinerary';
import { AutoLogin } from './cross/account/application/AutoLogin';
import { AcceptPolicies } from './cross/account/application/AcceptPolicies';
import { ChangeBranchSelected } from './cross/account/application/ChangeBranchSelected';
import { HasPlansGuard } from './guards/has-plans.guard';
import { NoStudentGuard } from './guards/noStudent.guard';
import { GetItineraryTemplates } from './cross/itineraries/application/GetItineraryTemplates';
import { SupportRequest } from './cross/support/application/SupportRequest';
import { SupportRepositoryRest } from './cross/support/infrastructure/SupportRepositoryRest';
// import { GetUsers } from './cross/itineraries/application/GetUsers';
import { NavModule } from './components/organism/nav/nav.module';
import { ModalErrorModule } from './components/organism/modal-error/modal-error.module';
import { ErrorModalModule } from './components/molecules/error-modal/error-modal.module';
import { ForgotPasswordModule } from './components/molecules/forgot-password/forgot-password.module';
import { SupportModule } from './components/molecules/support/support.module';
import { FooterModule } from './components/organism/footer/footer.module';
import { SidePopupModule } from './components/atoms/side-popup/side-popup.module';
import { LoadingScreenModule } from './components/atoms/loading-screen/loading-screen.module';
import { InfoBtnPopupModule } from './components/atoms/info-btn-popup/info-btn-popup.module';
import { CommonModule } from '@angular/common';
import { GetPharmacies } from './cross/itineraries/application/GetPharmacies';
import { ForgotPasswordModalModule } from './components/pages/forgot-password-modal/forgot-password-modal.module';
import { PreviousRouteService } from './services/previousRoute/previousRoute.service';
import { PharmacyPlanRequest } from './cross/cart/application/PharmacyPlanRequest';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { checkUsedDemo } from './cross/cart/application/CheckUserDemo';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GetPharmacyData } from './cross/dashboard/aplication/GetPharmacyData';
import { GetPharmacyDashboardFilterCourses } from './cross/dashboard/aplication/GetPharmacyDashboardFilterCourses';
import { GetPharmacyDashboardFilterItineraries } from './cross/dashboard/aplication/GetPharmacyDashboardFilterItineraries';
import { CrmRepositoryRest } from './cross/crm/infrastructure/CrmRepositoryRest';
import { AddToNewsLetter } from './cross/crm/application/AddToNewsLetter';
import { AddToLeadList } from './cross/crm/application/AddToLeadList';
import { UpdatePassword } from './cross/account/application/UpdatePassword';
import { GetUsers } from './cross/itineraries/application/GetUsers';
import { ApplyCoupons } from './cross/cart/application/ApplyCoupons';
import { CheckCoupons } from './cross/cart/application/CheckCoupons';
import { DownloadPharmacyStats } from './cross/dashboard/aplication/DownloadPharmacyStats';
import { GetGroupData } from './cross/dashboard/aplication/GetGroupData';
import { DownloadGroupStats } from './cross/dashboard/aplication/DownloadGroupStats';
import { AccessTokenAccountLogin } from './cross/account/application/AccessTokenAccountLogin';

const eventBusInMemory = new EventBusInMemory()
eventBusInMemory.subscribe(new TheUserLogged(cartRepositoryRest))

registerLocaleData(localeEs);
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NavModule,
    ModalErrorModule,
    ErrorModalModule,
    ForgotPasswordModule,
    SupportModule,
    AppRoutingModule,
    CommonModule,
    FooterModule,
    SidePopupModule,
    LoadingScreenModule,
    InfoBtnPopupModule,
    StoreModule.forRoot(ROOT_REDUCERS),
    NgxStripeModule.forRoot(environment.stripePublicKey),
    ForgotPasswordModalModule,
    BrowserAnimationsModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-ES' }, // Asegúrate de usar el código de locale correcto
    HasPlansGuard,
    NoStudentGuard,
    AuthGuardService,
    TranslateService,
    PreviousRouteService,
    PlansServiceService,
    StateService,
    PaymentStripeProviderService,
    provideClientHydration(),
    StorageService,
    { provide: PaymentProviderService, useClass: PaymentStripeProviderService },
    { provide: LoggerProviderService, useClass: LoggerSentryProviderService },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    {
      provide: APP_INITIALIZER,
      useFactory: function configFactory(translateService: TranslateService) {
        return () => translateService.setTranslationsLanguage('es')
      },
      deps: [TranslateService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: function configFactory(stateService: StateService) {
        return () => stateService.init()
      },
      deps: [StateService],
      multi: true
    },
    { provide: AccountSignUp, useValue: new AccountSignUp(accountRepositoryRest, eventBusInMemory) },
    { provide: AccountLogin, useValue: new AccountLogin(accountRepositoryRest, eventBusInMemory) },
    { provide: AccessTokenAccountLogin, useValue: new AccessTokenAccountLogin(accountRepositoryRest, eventBusInMemory) },
    { provide: PasswordForgot, useValue: new PasswordForgot(accountRepositoryRest) },
    { provide: ChangePassword, useValue: new ChangePassword(accountRepositoryRest) },
    { provide: UpdatePassword, useValue: new UpdatePassword(accountRepositoryRest) },
    { provide: AcceptPolicies, useValue: new AcceptPolicies(accountRepositoryRest) },
    { provide: ChangeBranchSelected, useValue: new ChangeBranchSelected(accountRepositoryRest) },
    { provide: AccountLogout, useValue: new AccountLogout(accountRepositoryRest) },
    { provide: CheckBranchName, useValue: new CheckBranchName(accountRepositoryRest) },
    { provide: MarkNewsAsViewed, useValue: new MarkNewsAsViewed(dashboardRepositoryRest) },
    { provide: GetLmsLoginUrl, useValue: new GetLmsLoginUrl(accountRepositoryRest) },
    { provide: AutoLogin, useValue: new AutoLogin(accountRepositoryRest) },
    { provide: SubscribeNewsLetter, useValue: new SubscribeNewsLetter(accountRepositoryRest) },
    { provide: GetAllCourses, useValue: new GetAllCourses(courseRepositoryRest) },
    { provide: GetCourses, useValue: new GetCourses(dashboardRepositoryRest) },
    { provide: SupportRequest, useValue: new SupportRequest(supportRepositoryRest) },
    { provide: GetItineraries, useValue: new GetItineraries(dashboardRepositoryRest) },
    { provide: ReportHolderByUser, useValue: new ReportHolderByUser(dashboardRepositoryRest) },
    { provide: GetPharmacyDashboard, useValue: new GetPharmacyDashboard(dashboardRepositoryRest) },
    { provide: GetPharmacyData, useValue: new GetPharmacyData(dashboardRepositoryRest) },
    { provide: GetGroupData, useValue: new GetGroupData(dashboardRepositoryRest) },
    { provide: DownloadPharmacyStats, useValue: new DownloadPharmacyStats(dashboardRepositoryRest) },
    { provide: DownloadGroupStats, useValue: new DownloadGroupStats(dashboardRepositoryRest) },
    { provide: GetGroupDashboard, useValue: new GetGroupDashboard(dashboardRepositoryRest) },
    { provide: GetAvailableNews, useValue: new GetAvailableNews(dashboardRepositoryRest) },
    { provide: GetUrlFromCourse, useValue: new GetUrlFromCourse(dashboardRepositoryRest) },
    { provide: EnrrollInTheCourse, useValue: new EnrrollInTheCourse(dashboardRepositoryRest) },
    { provide: GetCertificateData, useValue: new GetCertificateData(dashboardRepositoryRest) },
    { provide: GetPharmacyDashboardFilterCourses, useValue: new GetPharmacyDashboardFilterCourses(dashboardRepositoryRest) },
    { provide: GetPharmacyDashboardFilterItineraries, useValue: new GetPharmacyDashboardFilterItineraries(dashboardRepositoryRest) },
    { provide: GetLastCourses, useValue: new GetLastCourses(courseRepositoryRest) },
    { provide: GetCategories, useValue: new GetCategories(courseRepositoryRest) },
    { provide: GetFilteredCourses, useValue: new GetFilteredCourses(courseRepositoryRest) },
    { provide: AddToNewsLetter, useValue: new AddToNewsLetter(crmRepositoryRest) },
    { provide: AddToLeadList, useValue: new AddToLeadList(crmRepositoryRest) },
    // { provide: GetUsers, useValue: new GetUsers(itineraryRepositoryRest) },
    { provide: GetPharmacies, useValue: new GetPharmacies(itineraryRepositoryRest) },
    { provide: GetUsers, useValue: new GetUsers(itineraryRepositoryRest) },
    { provide: GetUserCourses, useValue: new GetUserCourses(itineraryRepositoryRest) },
    { provide: GetBranchItineraries, useValue: new GetBranchItineraries(itineraryRepositoryRest) },
    { provide: GetItineraryTemplates, useValue: new GetItineraryTemplates(itineraryRepositoryRest) },
    { provide: CreateItinerary, useValue: new CreateItinerary(itineraryRepositoryRest) },
    { provide: EditItinerary, useValue: new EditItinerary(itineraryRepositoryRest) },
    { provide: DuplicateItinerary, useValue: new DuplicateItinerary(itineraryRepositoryRest) },
    { provide: RemoveItinerary, useValue: new RemoveItinerary(itineraryRepositoryRest) },
    { provide: GetCourseContentTypes, useValue: new GetCourseContentTypes(itineraryRepositoryRest) },
    { provide: GetCourseById, useValue: new GetCourseById(courseRepositoryRest) },
    { provide: GetProgram, useValue: new GetProgram(courseRepositoryRest) },
    { provide: GetCertificatePublicData, useValue: new GetCertificatePublicData(certificateRepositoryRest) },
    { provide: GetCoursesByCategory, useValue: new GetCoursesByCategory(courseRepositoryRest) },
    { provide: GetProgramByCourseId, useValue: new GetProgramByCourseId(programRepositoryRest) },
    { provide: CartSet, useValue: new CartSet(cartRepositoryRest) },
    { provide: CartGet, useValue: new CartGet(cartRepositoryRest) },
    { provide: PharmacyPlanRequest, useValue: new PharmacyPlanRequest(cartRepositoryRest) },
    { provide: CartPaid, useValue: new CartPaid(cartRepositoryRest) }  ,
    { provide: checkUsedDemo, useValue: new checkUsedDemo(cartRepositoryRest) }  ,
    { provide: PayConfirmation, useValue: new PayConfirmation(cartRepositoryRest) }  ,
    { provide: Unsubscribe, useValue: new Unsubscribe(subcriptionRepositoryRest) }  ,
    { provide: GetSubscriptions, useValue: new GetSubscriptions(subcriptionRepositoryRest) }  ,
    { provide: CheckSubscriptions, useValue: new CheckSubscriptions(subcriptionRepositoryRest) }  ,
    { provide: GetAllPlans, useValue: new GetAllPlans(planRepositoryRest) },
    { provide: ApplyCoupons, useValue: new ApplyCoupons(cartRepositoryRest) },
    { provide: CheckCoupons, useValue: new CheckCoupons(cartRepositoryRest) },
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
