import { NgModule } from '@angular/core';
import { ErrorModalComponent } from './error-modal.component';
import { ButtonModule } from '../../atoms/button/button.module';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        ErrorModalComponent
    ],
    imports: [
        ButtonModule,
        CommonModule
    ],
    exports: [
        ErrorModalComponent
    ],
})
export class ErrorModalModule { }