//#region addToNewsletter
export interface ResponseAddToNewsletter {
    data: {
        addToNewsletter: any,
        result: 'ok' | 'ko'
    },
    ok: true
}
export const toDomainGetById = function (response: ResponseAddToNewsletter): boolean {
    return response.data.addToNewsletter == 'ok'
}
//#endregion
//#region addToLeadList
export interface ResponseAddToLeadList {
    data: {
        addToLeadList: any,
        result: 'ok' | 'ko'
    },
    ok: true
}
export const toDomainAddToLeadList = function (response: ResponseAddToLeadList): boolean {
    return response.data.addToLeadList == 'ok'
}
//#endregion