import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MaganeSimpleBannerService {

  private banner$: Subject<string>;

  constructor() {this.banner$ = new Subject(); }

  showBanner(bannerhtml: string){
    this.banner$.next(bannerhtml);
  }

  hideBanner(){
    this.banner$.next('');
  }

  getBannerData$(): Observable<string>{
    return this.banner$.asObservable();
  }
}
