import { Injectable } from '@angular/core';
import { SidePopupData } from './side-popup.component';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MaganeSidePopupService {

  private popup$: Subject<SidePopupData>;

  constructor() {this.popup$ = new Subject(); }

  openPopup(popupData: SidePopupData){
    this.popup$.next(popupData);
  }

  getPopupData$(): Observable<SidePopupData>{
    return this.popup$.asObservable();
  }
}
