import { Content } from '../domain/Course'
import { CourseRepository } from '../domain/CourseRepository'

export class GetProgram {
  private readonly repository: CourseRepository

  constructor(repository: CourseRepository) {
    this.repository = repository
  }

  public async execute(courseId: number): Promise<Content[] | undefined> {
    return this.repository.getProgram(courseId);
  }
}
