import { CustomError } from "../../share/domain/CustomError"

export enum AccountErrorList {
  EMAIL_NOT_FOUND = 'Account not found with the email',
  PASSWORD_INCORRECT = 'The password is incorrect',
  EMAIL_INVALID_FIELD = 'Email invalid field',
  PASSWORD_INVALID_FIELD = 'Password invalid field',
  EMAIL_USED = 'Email already used',
  PHARMA_USED = 'Pharma name already used',
  NICK_USED = 'Nick already used',
  INVALID_FLASH_LINK = 'flash link invalid',
}

export class AccountError extends Error implements CustomError {
  public override readonly name: string = 'account.error'

  public static emailNotFound (): AccountError {
    return new this(AccountErrorList.EMAIL_NOT_FOUND)
  }

  public static passwordIncorrect (): AccountError {
    return new this(AccountErrorList.PASSWORD_INCORRECT)
  }

  public static emailInvalidField (): AccountError {
    return new this(AccountErrorList.EMAIL_INVALID_FIELD)
  } 

  public static passwordInvalidField (): AccountError {
    return new this(AccountErrorList.PASSWORD_INVALID_FIELD)
  } 

  public static emailUsed (): AccountError {
    return new this(AccountErrorList.EMAIL_USED)
  }

  public static pharmaUsed (): AccountError {
    return new this(AccountErrorList.PHARMA_USED)
  } 

  public static nickUsed (): AccountError {
    return new this(AccountErrorList.NICK_USED)
  }

  public static invalidFlashLink (): AccountError {
    return new this(AccountErrorList.INVALID_FLASH_LINK)
  } 
}
