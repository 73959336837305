import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { MaganeSidePopupService } from './magane-side-popup.service';

type iconNames = {
  success: string,
  error: string,
}
type underlineColor = {
  green: string,
  pink: string,
}

@Component({
  selector: 'app-side-popup',
  templateUrl: './side-popup.component.html',
  styleUrls: ['./side-popup.component.scss']
})
export class SidePopupComponent implements OnInit {

  text?: string;
  iconName?: keyof iconNames;
  underlineColor?: keyof underlineColor;
  
  hide: boolean = true;


  public ICON_LIST: iconNames = {
    success: "../../../../assets/svg/liceo-icons/success_popup.svg",
    error: '../../../../assets/svg/liceo-icons/error_popup.svg',
  }
  public UNDERLINE_COLOR: underlineColor = {
    green: "#60fcbd",
    pink: '#FF69FF',
  }

  constructor(
    private elementRef: ElementRef,
    private manageSidePopup: MaganeSidePopupService,
  ) { }

  ngOnInit(): void {
    this.elementRef.nativeElement.style.setProperty('--underlineColor', `#FF69FF`);
    if (this.underlineColor) {
      this.elementRef.nativeElement.style.setProperty('--underlineColor', `${this.UNDERLINE_COLOR[this.underlineColor]}`);
    }
    this.manageSidePopup.getPopupData$().subscribe(
      popupData => this.openPopup(popupData)
      )
    }
    
    onCloseBtn(){
      this.hide = true; 
    }

    openPopup(popupData: SidePopupData){
      this.resetPopupData();
      this.setPopupData(popupData);
      setTimeout(() => {
        this.hide = false;
      }, 400);
      setTimeout(() => {
        this.hide = true;
      }, 4400);
    }
    
    setPopupData(popupData: SidePopupData){
      this.text = popupData.text;
      if(popupData.iconName){
        this.iconName = popupData.iconName;
      }
      if(popupData.underlineColor){
      this.underlineColor = popupData.underlineColor;
      this.elementRef.nativeElement.style.setProperty('--underlineColor', `${this.UNDERLINE_COLOR[popupData.underlineColor]}`);
    }
  }

  resetPopupData(){
    this.text = '';
    this.iconName = undefined;
    this.underlineColor = undefined;
  }
}

export interface SidePopupData{
  text: string;
  iconName?: keyof iconNames;
  underlineColor?: keyof underlineColor;
}
