import { AccountRepository } from '../domain/AccountRepository'

export class ChangePassword {
  private readonly repository: AccountRepository

  constructor(repository: AccountRepository) {
    this.repository = repository
  }

  public async execute(token: string, email: string, password: string): Promise<boolean> {
    const result = await this.repository.changePassword(token, email, password)
    return result
  }
}
