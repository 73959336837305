import { CourseRepository } from '../domain/CourseRepository'
import { Course, Courses } from '../domain/Course'

export class GetFilteredCourses {
  private readonly repository: CourseRepository

  constructor(repository: CourseRepository) {
    this.repository = repository
  }

  public async execute({ identifier, category, search, limit, order, target}: { identifier?: string, category?: string[],search?: string, limit?: number, order?: number, target?: number[] }): Promise<Courses | undefined> {
    return this.repository.getFilteredCourses({ identifier, category, search, limit, order, target })
}
}
