import { PlanRepository } from '../domain/PlanRepository'
import { Plan, toDomainGetAll } from '../domain/Plan'
import { environment } from '../../../../environments/environment'
import { PlanError } from '../domain/PlanError'
import { RequestError } from '../../share/domain/RequestError'

export class PlanRepositoryRest extends PlanRepository {
  private readonly HOST = environment.host
  private readonly PCEK = environment.pcek

  public async getAll(identifier?: string): Promise<Plan[] | undefined> {
    const response = await fetch(`${this.HOST}/buy/getPlans`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK
      },
      body: JSON.stringify({ pid: identifier })
    })    

    const result = await response.json()

    // Uncaught generic error
    if(response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }
        
    // plans not avaible
    if(result.data?.result === 'pid_nf'
      || result.data?.result === 'pid_int'
      ) {
      throw PlanError.plansNotFound()
    }

    // if (response.ok === false) {
    //   return
    // }
    
    return toDomainGetAll(result);
  }
}
