import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { DocumentService } from 'src/app/services/document/document.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-lottie-animation',
  templateUrl: './lottie-animation.component.html',
  styleUrls: ['./lottie-animation.component.scss']
})
export class LottieAnimationComponent implements OnInit, AfterViewInit {

  @ViewChild('container') container!: ElementRef;

  @Input() jsonRoute: string = '';
  @Input() width: string = '400';
  @Input() height: string = '400';
  @Input() mode: 'loop' | 'scroll' = 'scroll';

  private isBrowser: boolean;

  constructor(
    private httpClient: HttpClient,
    private documentService: DocumentService,
    @Inject(PLATFORM_ID) private platformId: object,      
    private elRef: ElementRef
  ){
    this.isBrowser = isPlatformBrowser(this.platformId);    
  }

  uniqId: string = 'id' + (new Date()).getTime();
  data: LottieAnimationData = {op: 96};
  lottie = this.documentService.getElementById(this.uniqId) as HTMLElement | null;
  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    if(!this.isBrowser){return;}
    this.init();
  }
  async init(){
    this.httpClient.get(this.jsonRoute).subscribe(data =>{
      this.data = JSON.parse(JSON.stringify(data))
      const script = document.createElement('script');
    
      if (this.mode === 'loop') {
        const dot = document.createElement('div');
        dot.innerHTML = 
        `<dotlottie-player
          src="${this.jsonRoute}" 
          background="transparent"
          speed="1" 
          style="width: ${this.width}px; height: ${this.height}px"
          direction="1"
          playMode="normal"
          loop autoplay
        ></dotlottie-player>`;
        this.container.nativeElement.appendChild(dot);
        // document.getElementByid('body')[0].appendChild(dot);
      } else {
        script.innerHTML = `
          document.getElementById("${this.uniqId}").load('${this.jsonRoute}');
          LottieInteractivity.create({
            player: '#${this.uniqId}',
            mode: '${this.mode}',
            actions: [
              {
                visibility: [0, 1.0],
                type: "seek",
                frames: [0, ${this.data.op}],
              },
            ]
          });
        `;
        document.getElementsByTagName('body')[0].appendChild(script);
      }
    })

  }
}
export interface LottieAnimationData{
  op: number
}
