import { CartRepository } from '../domain/CartRepository'
import { CheckCouponsResponse } from '../domain/Cart'

export interface CheckCouponsRequest {
  coupon: string,
}

export class CheckCoupons {
  private readonly repository: CartRepository

  constructor(repository: CartRepository) {
    this.repository = repository
  }

  public async execute(params: CheckCouponsRequest): Promise<CheckCouponsResponse> {
    return this.repository.checkCoupons({ ...params });
  }
}
