import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthGuardService } from '../services/auth-guard.service';

@Injectable({
  providedIn: 'root'
})
export class NoStudentGuard  {
  
  constructor(
    private router: Router,
    private authGuardService: AuthGuardService
  ){

  }

  resolve(): Promise<boolean> {
    return this.authGuardService.isStudent().then(res=>{
        if(res){
            this.router.navigate(['/']);
        }
        return !res;
    });
  }
}
