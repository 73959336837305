import { SubcriptionRepository } from '../domain/SubcriptionRepository'
import { ActivePlans } from '../domain/Subcription'

export class GetSubscriptions {
  private readonly repository: SubcriptionRepository

  constructor(repository: SubcriptionRepository) {
    this.repository = repository
  }

  public async execute(token: string): Promise<ActivePlans | undefined> {
    return this.repository.getSubscriptions(token);
  }
}
