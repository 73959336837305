import { SupportRepository } from '../domain/SupportRepository'
import { environment } from '../../../../environments/environment'
import { RequestError } from '../../share/domain/RequestError'
import { toDomainsupportRequest } from '../domain/Support'

export class SupportRepositoryRest extends SupportRepository {
    private readonly HOST = environment.host
    private readonly PCEK = environment.pcek

    public async supportRequest(token: string, subject: string, description: string): Promise<boolean> {
        const response = await fetch(`${this.HOST}/lms/supportRequest`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'pcek': this.PCEK,
                '_at': token
            },
            body: JSON.stringify({ sub: subject, des: description })
        })

        let result;
    try {
      result = await response.json();
    } catch (error) {
      throw RequestError.generic(this.constructor.name, {err: "Unexpected end of JSON input", data: response})
    }

        // Uncaught generic error
        if (response.ok === false || result.ok === false || !result.data) {
            throw RequestError.generic(this.constructor.name, result)
        }
        if (result.data?.result !== 'ok') {
            throw RequestError.generic(this.constructor.name, result)
        }

        return toDomainsupportRequest(result);
    }

}
