import { AccountRepository } from '../domain/AccountRepository'

export class UpdatePassword {
  private readonly repository: AccountRepository

  constructor(repository: AccountRepository) {
    this.repository = repository
  }

  public async execute(token: string, email: string, password: string, customAtributes: any): Promise<boolean> {
    const result = await this.repository.updatePassword(token, email, password, customAtributes)
    return result
  }
}
