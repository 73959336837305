import { AccountRepository } from '../domain/AccountRepository'

export class SubscribeNewsLetter {
  private readonly repository: AccountRepository

  constructor(repository: AccountRepository) {
    this.repository = repository
  }

  public async execute(name: string, email: string, list_id: number[],url:string, user_type?: number): Promise<boolean> {
    return await this.repository.subscribeNewsLetter(name, email, list_id, url,user_type);
  }
}
