import { lmsLinks } from '../domain/Account';
import { AccountRepository } from '../domain/AccountRepository'
 
export class GetLmsLoginUrl {
  private readonly repository: AccountRepository
 
  constructor(repository: AccountRepository) {
    this.repository = repository
  }
 
  public async execute(token: string): Promise<lmsLinks> {
    return this.repository.getLmsLoginUrl(token);
  }
}
