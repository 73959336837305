import { AccountRepository } from '../domain/AccountRepository'
import { Account } from '../domain/Account'
import { EventAction } from '../../share/domain/EventAction'
import { EventBus } from '../../share/domain/EventBus'

export class PasswordForgot {
  private readonly repository: AccountRepository

  constructor(repository: AccountRepository) {
    this.repository = repository
  }

  public async execute(email: string): Promise<boolean> {
    const result = await this.repository.forgotPassword(email)
    return result
  }
}
