import { Router, NavigationEnd, ActivatedRoute, Data, RoutesRecognized } from '@angular/router'
import { AfterViewInit, Component, HostListener, Inject, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoggerProviderService } from './services/logger/logger-provider.service';
import { Account } from './cross/account/domain/Account';
import { StorageService } from './services/storage.service';
import { ForgotPasswordService } from './services/forgot-password/forgot-password.service';
import { ManageSupportChatService } from './services/manageSupportChat/manage-support-chat.service';
import { isPlatformBrowser } from '@angular/common';
import { AuthGuardService } from './services/auth-guard.service';
import { VisibilityService } from './services/visibilityService/visibility.service';
import { PreviousRouteService } from './services/previousRoute/previousRoute.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { CookiesService } from './services/cookies.service';
import { AccessTokenAccountLogin } from './cross/account/application/AccessTokenAccountLogin';

declare let gtag: Function;

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  private readonly loggerProviderService: LoggerProviderService
  private readonly router: Router;
  private isBrowser: boolean;

  #cookiesService = inject(CookiesService);

  constructor(
    router: Router,
    loggerProviderService: LoggerProviderService,
    private manageSupportChatService: ManageSupportChatService,
    private authGuardService: AuthGuardService,
    private storageService: StorageService,
    private visibilityService: VisibilityService,
    private previousRouteService: PreviousRouteService,
    private tokenAccountLogin: AccessTokenAccountLogin,
    private forgotPasswordService: ForgotPasswordService,
    private googleAnalitycsService: GoogleAnalyticsService,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {
    this.router = router
    this.loggerProviderService = loggerProviderService
    this.isBrowser = isPlatformBrowser(this.platformId);


    if (environment.gtmKey) {
      // Inicializa el dataLayer
      window.dataLayer = window.dataLayer || [];

      // Define la función gtag
      window.gtag = function() {
        window.dataLayer.push(arguments);
      };

      // Configura la primera llamada a gtag
      window.gtag('js', new Date());

      // Carga el script de GTM
      (function(w: any, d, s, l: string, i) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s) as HTMLScriptElement, // Asegúrate de que TypeScript sabe que 'j' es un HTMLScriptElement
            dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode?.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', environment.gtmKey);
    }

  }
  title = 'web-app';
  initialScrollPosition: number = 0;
  scrollPosition: number = 0;
  scrolled: boolean = false;
  // private routeData: any;
  showNav: boolean = true;
  showFooter: boolean = true;

  hasRoute(route: string) {
    return this.router.url.includes(route);
  }

  @HostListener("scroll", ['$event'])
  doSomethingOnInternalScroll($event: Event) {
    this.scrolled = this.initialScrollPosition != window.scrollY;
  }

  setUpAnalytics() {
    if(!this.isBrowser){return;}
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.googleAnalitycsService.eventEmitter('page_path', event.urlAfterRedirects);
        // gtag('config', gKey, { 'page_path': event.urlAfterRedirects });
      }
    })
  }

  ngOnInit() {
    // this.checkAccessToken();
    this.setUpAnalytics();
    this.loggerProviderService.init(environment.sentryKey)
    this.initialScrollPosition = window.scrollY;
    this.visibilityService.getShowNav$().subscribe((showNav: boolean) => {
        this.showNav = showNav
    });
    this.visibilityService.getShowFooter$().subscribe((showFooter: boolean) => {
        this.showFooter = showFooter
    });
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkMustChangePassword();
        this.manageSupportChatService.checkUrl();
      }
    });
    this.authGuardService.updateStatus();
    this.checkAccessToken();
  }

  checkAccessToken(){
    const accessToken = this.#cookiesService.getAccessToken();
    if(accessToken == null || accessToken == undefined || accessToken == ''){
      this.storageService.clearSessionData();
    } else {
      this.tokenAccountLogin.execute(accessToken).then((result) => {
        if(!result.name){
          this.storageService.clearSessionData();
        }else{
          this.storageService.set(this.storageService.USER_DATA, JSON.stringify(result));
          this.#cookiesService.setAccessToken(accessToken);
        }
      });
    }
  }

  async checkMustChangePassword() {
    const userData: Account | undefined = await JSON.parse(this.storageService.find(this.storageService.USER_DATA))
    if (userData != undefined && userData.mustChangePwd) {
      this.forgotPasswordService.dispatchModal(null);
    }
  }
}