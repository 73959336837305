import { CertificateRepository } from '../domain/CertificateRepository'
import {  Certificate } from '../domain/Certificate'

export class GetCertificatePublicData {
  private readonly repository: CertificateRepository

  constructor(repository: CertificateRepository) {
    this.repository = repository
  }

  public async execute(certificateId: string): Promise<Certificate | undefined> {
    return this.repository.getCertificatePublicData(certificateId);
  }
}
