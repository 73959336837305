<div #footerAnimation class="footer glass-1">
    <div>
        <a href="/"><img class="logo-footer" [src]="assetsEnvironment+'/img/nav/nav_logo.svg'"></a>
        <p class="txt-footer lf-p-5 bold"> {{'footer.title' | translate}}</p>
        <p class="txt-footer lf-p-5 regular">{{'footer.sub_title' | translate}}</p>
        <div class="rrss_wrap">
            <a href="https://www.facebook.com/Liceo-de-Farmacia-100803841697747" target="_blank"><img class="rrss-icon" [src]="assetsEnvironment+'/img/footer/social_networks/fb.webp'"></a>
            <a href="https://www.instagram.com/liceodefarmacia/" target="_blank"><img class="rrss-icon" [src]="assetsEnvironment+'/img/footer/social_networks/ig.webp'"></a>
            <a href="https://www.linkedin.com/company/liceo-de-farmacia/" target="_blank"><img class="rrss-icon" [src]="assetsEnvironment+'/img/footer/social_networks/ln.webp'"></a>
            <a href="https://www.youtube.com/channel/UCVBQrRJT4EKSIjBlmD-saEw" target="_blank"><img class="rrss-icon" [src]="assetsEnvironment+'/img/footer/social_networks/yt.webp'"></a>
            <a href="https://twitter.com/LiceoFarmacia" target="_blank"><img class="rrss-icon" [src]="assetsEnvironment+'/img/footer/social_networks/tw.webp'"></a>
        </div>
    </div>
    <div class="right_wrap">
        <div [ngClass]="{hidden: isDashboardRoute()}" class="footer-paginas">
            <p class="lf-e-3 semibold underline"><span>{{'footer.t_pages' | translate}}</span></p>
            <a *ngIf="!isHideAllRoute()" href="/cursos"><p class="lf-p-5 medium">{{'footer.t_courses' | translate}}</p></a>
            <a *ngIf="!isHideAllRoute()" href="/metodo-liceo"><p class="lf-p-5 medium">{{'footer.t_method' | translate}}</p></a>
            <a *ngIf="!isHideAllRoute()" href="/mi-perfil"><p class="lf-p-5 medium">{{'footer.t_profile' | translate}}</p></a>
            <a *ngIf="!isHideAllRoute()" href="/prueba-gratis/seleccion" (click)="gotoFreeTest()">
                <p class="lf-p-5 medium">{{'footer.t_test' | translate}}</p>
            </a>
            <a href="/preguntas-frecuentes" target="_blank"><p class="lf-p-5 medium">{{'footer.faqs' | translate}}</p></a>
        </div>
        <div [ngClass]="{hidden: !isDashboardRoute()}" class="footer-paginas">
            <p class="lf-e-3 semibold underline"><span>{{'footer.t_pages' | translate}}</span></p>
            <a routerLink="aula/estadisticas"><p class="lf-p-5 medium">{{'footer.t_estats' | translate}}</p></a>
            <a routerLink="aula/la-infiltrada"><p class="lf-p-5 medium">{{'footer.t_liceo_play' | translate}}</p></a>
            <a routerLink="aula/protocolos-de-consejo"><p class="lf-p-5 medium">{{'footer.t_protocolos' | translate}}</p></a>
            <a routerLink="aula/mis-suscripciones" ><p class="lf-p-5 medium">{{'footer.t_suscpription' | translate}}</p></a>
        </div>
        <div class="footer-contacto">
            <p class="lf-e-3 semibold underline"><span>{{'footer.t_contact' | translate}}</span></p>
            <a href="mailto:info@liceodefarmacia.com" target="_blank"><p class="lf-p-5 medium">{{'footer.email' | translate}}</p></a>
            <p class="lf-e-3 semibold underline txt-legales"><span>{{'footer.t_legal' | translate}}</span></p>
            <p class="lf-p-5 medium">{{'footer.copy' | translate}}</p>
            <a href="/aviso-legal" target="_blank"><p class="lf-p-5 medium">{{'footer.legal' | translate}}</p></a>
            <a href="/politica-de-privacidad" target="_blank"><p class="lf-p-5 medium">{{'footer.privacy' | translate}}</p></a>
            <a href="/politica-de-cookies" target="_blank"><p class="lf-p-5 medium">{{'footer.cookies' | translate}}</p></a>
        </div>


    </div>
</div>
