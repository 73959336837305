import { CartRepository } from '../domain/CartRepository'

export interface PharmacyPlanQuery {
  formType: number, 
  firstName: string,
  lastName: string, 
  phone: number, 
  email: string,
  pharmacyName: string, 
  acceptNewsLetter: boolean
  licenseId: number,
  coupon?: string
}

export class PharmacyPlanRequest {
  private readonly repository: CartRepository

  constructor(repository: CartRepository) {
    this.repository = repository
  }

  public async execute(params: PharmacyPlanQuery): Promise<boolean> {
    return this.repository.pharmacyPlanRequest({ ...params });
  }
}
