<div *ngIf="popupOpen" class="mobile-modal">
    <div class="bg" (click)="closeModal()"></div>
    <div class="window">
        <div class="close-icon" (click)="closeModal()">
            <img src="../../../../assets/svg/liceo-icons/close_slim.svg" alt="">
        </div>
        <div class="icon">
            <img src="../../../../assets/svg/liceo-icons/info_icon.svg" alt="">
        </div>
        <div class="lf-p-4 bold">{{popupData.title}}</div>
        <h4 class="lf-p-5 bold">{{'info_btn.desc' | translate}}</h4>
        <div class="description lf-p-5 regular">{{popupData.description}}</div>
        <div class="sponsors" *ngIf="popupData.sponsors.length > 0">
            <div class="sponsor" *ngFor="let sponsor of popupData.sponsors">
                <img [src]="imgSponsorUrl+sponsor.img" alt="">
            </div>
        </div>
    </div>
</div>