<div #menu (click)="toggleMenu()" class="content">
    <div class="user-icon" [ngClass]="{small: isReducedDesign}">
    </div>
    <p *ngIf="!isReducedDesign && userName == ''" class="lf-e-3 semibold name">Hola</p>
    <p *ngIf="!isReducedDesign && userName != ''" class="lf-e-3 semibold name">Hola, {{userName}}</p>
    <img class="arrow" [ngClass]="{open: showMenu}" src="../../../../assets/svg/chevron-left.svg" alt="">
</div>
<div *ngIf="showMenu" class="menu glass-1" [ngClass]="{'align-right': alignRight}">
    <div class="options-list">
        <div *ngFor="let option of options" (click)="onClickOption(option)" class="option">
            <img src="../../../../assets/svg/options_icons/{{option}}.svg" alt="">
            <p class="lf-p-3 regular option-name">{{option}}</p>
        </div>
    </div>
</div>
