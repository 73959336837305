import { ItineraryEdit } from "../domain/Itinerary"
import { ItineraryRepository } from "../domain/ItineraryRepository"

export class EditItinerary {
  private readonly repository: ItineraryRepository

  constructor(repository: ItineraryRepository) {
    this.repository = repository
  }

  public async execute(token: string, itinerary:ItineraryEdit): Promise<number | undefined> {
    return this.repository.editItinerary(token, itinerary)
  }
}