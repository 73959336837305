import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-lf-text-area',
  templateUrl: './lf-text-area.component.html',
  styleUrls: ['./lf-text-area.component.scss']
})
export class LfTextAreaComponent implements OnInit {

  @ViewChild('input') input!: ElementRef;
  
  constructor() { }
  
  @Input() placeholder: string = '';
  @Input() height?: string;

  ngOnInit(): void {
  }

  getValue(): string {
    return this.input.nativeElement.value;
  }

  setValue(newValue: string){
    this.input.nativeElement.value = newValue;
  }

}
