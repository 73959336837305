import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-lf-input-text',
  templateUrl: './lf-input-text.component.html',
  styleUrls: ['./lf-input-text.component.scss']
})
export class LfInputTextComponent {

  @ViewChild('input') input!: ElementRef;

  @Input() public type: string = 'text'
  @Input() public placeholder: string = ''
  @Input() public hasError: boolean = false
  @Input() public messageError: string = ''
  @Input() public limitCharacter?: number;

  public value: string = ''
  onChange = (_: any) => { }
  onTouch = () => { }

  constructor() { }

  onInput(event: Event) {
    const { value } = event.target as HTMLInputElement;
    this.value = value;
    this.onTouch();
    this.onChange(this.value);
  }

  writeValue(value: string): void {
    this.value = value || '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setValue(newValue: string) {
    this.input.nativeElement.value = newValue;
  }
  focus() {
    this.input.nativeElement.focus();
  }
  getValue(): string {
    return this.input.nativeElement.value;

  }

  setDisabledState(isDisabled: boolean): void { }


}
