import { CartRepository } from '../domain/CartRepository'
import { Cart } from '../domain/Cart'

export class CartGet {
  private readonly repository: CartRepository

  constructor(repository: CartRepository) {
    this.repository = repository
  }

  public async execute({ userToken, saleCode }: { userToken?: string, saleCode: string }): Promise<Cart> {
    return this.repository.get({ userToken, saleCode })
  }
}
