import { UserModel } from "../domain/Itinerary"
import { ItineraryRepository } from "../domain/ItineraryRepository"

export class GetUsers {
  private readonly repository: ItineraryRepository

  constructor(repository: ItineraryRepository) {
    this.repository = repository
  }

  public async execute(token: string, itineraryId: number): Promise<UserModel[]> {
    return this.repository.getUsers(token, itineraryId)
  }
}
