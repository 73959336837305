import { CartRepository } from '../domain/CartRepository'
import { LicenseId, Set, Paiment, toDomainCartGet, toDomainCartSet, toDomainCartPaid, Cart, SetOwnerCart, toDomainCartSetOwnerCart, toDomainPayConfirmation, toDomainCheckUsedDemo, PayConfirmationData, toDomainApplyCoupons, ApplyCouponsResponse, CheckCouponsResponse, toDomainCheckCoupons } from '../domain/Cart'
import { environment } from '../../../../environments/environment'
import { RequestError } from '../../share/domain/RequestError'
import { CartError } from '../domain/CartError'
import { ApplyCouponsRequest } from '../application/ApplyCoupons'
import { IPaidCart } from '../application/CartPaid'
import { CheckCouponsRequest } from '../application/CheckCoupons'
import { PharmacyPlanQuery } from '../application/PharmacyPlanRequest'

export class CartRepositoryRest extends CartRepository {
  private readonly HOST = environment.host
  private readonly PCEK = environment.pcek
  private readonly APP = environment.app

  public async set({ userToken, license, saleCode }: { userToken?: string, license: LicenseId[], saleCode?: string }): Promise<Set> {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'pcek': this.PCEK
    }

    if (userToken) {
      //@ts-ignore
      headers._at = userToken
    }

    const response = await fetch(`${this.HOST}/buy/setCart`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ itl: license, scd: saleCode, app: this.APP })
    })

    let result;
    try {
      result = await response.json();
    } catch (error) {
      throw RequestError.generic(this.constructor.name, { err: "Unexpected end of JSON input", data: response })
    }

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    // cart already finished
    if (result.data?.result === 'fd_cart') {
      throw CartError.finishedCart()
    }

    // demo already used
    if (result.data?.result === 'dm_au') {
      throw CartError.demoAlreadyUsed()
    }

    return toDomainCartSet(result)
  }

  public async paid(params: IPaidCart): Promise<Paiment> {
    const body: { [key: string]: string } = {
      scd: params.saleCode,
      spid: params.paymentId
    }

    if(params.coupon) body['cpn'] = params.coupon;

    const response = await fetch(`${this.HOST}/buy/paidCart`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': params.userToken
      },
      body: JSON.stringify(body)
    })
    let result;
    try {
      result = await response.json();
    } catch (error) {
      throw RequestError.generic(this.constructor.name, { err: "Unexpected end of JSON input", data: response })
    }

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    //  already have an active subscription
    if (result.data?.result === 'aasubs') {
      throw CartError.alreadySubscribe()
    }

    // empty cart
    if (result.data?.result === 'emp_cart') {
      throw CartError.emptyCart()
    }

    // demo already used
    if (result.data?.result === 'dm_au') {
      throw CartError.demoAlreadyUsed()
    }

    // Expired session
    if (result.data?.result === 'sc_nf'
      || result.data?.result === 'sc_inv'
      || result.data?.result === 'exps'
    ) {
      throw CartError.expiredSession()
    }

    return toDomainCartPaid(result);
  }

  public async payConfirmation({ userToken, saleCode }: { userToken: string, saleCode: string }): Promise<PayConfirmationData> {
    const response = await fetch(`${this.HOST}/buy/payConfirmation`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': userToken
      },
      body: JSON.stringify({
        scd: saleCode
      })
    })
    let result;
    try {
      result = await response.json();
    } catch (error) {
      throw RequestError.generic(this.constructor.name, { err: "Unexpected end of JSON input", data: response })
    }

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    // empty cart
    if (result.data?.result === 'emp_cart') {
      throw CartError.emptyCart()
    }

    // demo already used
    if (result.data?.result === 'dm_au') {
      throw CartError.demoAlreadyUsed()
    }

    // Expired session
    if (result.data?.result === 'sc_nf'
      || result.data?.result === 'sc_inv'
      || result.data?.result === 'exps'
    ) {
      throw CartError.expiredSession()
    }

    return toDomainPayConfirmation(result);
  }

  public async get({ userToken, saleCode }: { userToken?: string, saleCode: string }): Promise<Cart> {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'pcek': this.PCEK
    }

    if (userToken) {
      //@ts-ignore
      headers._at = userToken
    }

    const response = await fetch(`${this.HOST}/buy/getCart`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ scd: saleCode, app: this.APP })
    })

    let result;
    try {
      result = await response.json();
    } catch (error) {
      throw RequestError.generic(this.constructor.name, { err: "Unexpected end of JSON input", data: response })
    }

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    // sale code not found
    if (result.data?.result === 'scd_nf') {
      throw CartError.saleCodeNotFound()
    }

    return toDomainCartGet(result);
  }

  public async setOwnerCart({ userToken, saleCode }: { userToken: string, saleCode: string }): Promise<SetOwnerCart> {

    const response = await fetch(`${this.HOST}/buy/setOwnerCart`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        _at: userToken
      },
      body: JSON.stringify({ scd: saleCode, app: this.APP })
    })

    let result;
    try {
      result = await response.json();
    } catch (error) {
      throw RequestError.generic(this.constructor.name, { err: "Unexpected end of JSON input", data: response })
    }

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    return toDomainCartSetOwnerCart(result)
  }

  public async checkUsedDemo(userToken: string): Promise<boolean> {
    const response = await fetch(`${this.HOST}/buy/checkUsedDemo`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        _at: userToken
      },
      body: JSON.stringify({})
    });

    let result;
    try {
      result = await response.json();
    } catch (error) {
      throw RequestError.generic(this.constructor.name, { err: "Unexpected end of JSON input", data: response })
    }

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    return toDomainCheckUsedDemo(result);
  }

  public async pharmacyPlanRequest(params: PharmacyPlanQuery): Promise<boolean> {
    const body: { [key: string]: string | boolean | number } = {
      ftyp: params.formType,
      fnm: params.firstName,
      lnm: params.lastName,
      ph: params.phone,
      email: params.email,
      pnm: params.pharmacyName,
      wn: params.acceptNewsLetter,
      lid: params.licenseId,
    }

    if (params.coupon) body['cpn'] = params.coupon;

    const response = await fetch(`${this.HOST}/buy/pharmacyPlanRequest`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK
      },
      body: JSON.stringify(body)
    })
    let result;
    try {
      result = await response.json();
    } catch (error) {
      throw RequestError.generic(this.constructor.name, { err: "Unexpected end of JSON input", data: response })
    }

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    return result.data?.result === 'ok';
  }

  public async applyCoupons(params: ApplyCouponsRequest): Promise<ApplyCouponsResponse> {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'pcek': this.PCEK,
    }

    const response = await fetch(`${this.HOST}/buy/applyCoupons`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ 
        cpn: params.coupon, 
        item: params.item, 
        app: this.APP })
    })

    let result;
    try {
      result = await response.json();
    } catch (error) {
      throw RequestError.generic(this.constructor.name, { err: "Unexpected end of JSON input", data: response })
    }

    return toDomainApplyCoupons(result);
  }

  public async checkCoupons(params: CheckCouponsRequest): Promise<CheckCouponsResponse> {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'pcek': this.PCEK,
    }

    const response = await fetch(`${this.HOST}/buy/CheckCoupons`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        cpn: params.coupon,
        app: this.APP
      })
    })

    let result;
    try {
      result = await response.json();
    } catch (error) {
      throw RequestError.generic(this.constructor.name, { err: "Unexpected end of JSON input", data: response })
    }

    return toDomainCheckCoupons(result);
  }
}
