import { CustomError } from "../../share/domain/CustomError"

export enum PlanErrorList {
  PLANS_NOT_FOUND = 'Plans not found',
}

export class PlanError extends Error implements CustomError {
  public override readonly name: string = 'plan.error'

  public static plansNotFound (): PlanError {
    return new this(PlanErrorList.PLANS_NOT_FOUND)
  }
}
