import { DashboardRepository } from '../domain/DashboardRepository'
import { AvailableNew } from '../domain/Dashboard';

export class MarkNewsAsViewed {
  private readonly repository: DashboardRepository

  constructor(repository: DashboardRepository) {
    this.repository = repository
  }

  public async execute(accessToken: string, listNews : number[]): Promise<boolean> {
    return this.repository.MarkNewsAsViewed(accessToken, listNews);
  }
}
