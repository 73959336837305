<div (click)="closeModal()" *ngIf="modalVisible" class="glass-1 modal-background">
    <div class="glass-1 modal">
        <p class="lf-maintitle bold title">{{errorData.title}}</p>
        <p class="lf-p-2 semibold sub-title">{{errorData.subTitle}}</p>
        <div class="error-msg">
            <p class="lf-p-3 bold">{{errorData.msg}} </p>
            <p class="lf-p-3 bold error-code">{{errorData.errorCode}}</p>
        </div>
        <p class="lf-p-5 regular error-description">{{errorData.description}}</p>
        <p class="lf-p-6 light info">{{errorData.info}}</p>
        <app-button [type]="1" text="{{errorData.btnText}}"></app-button>
        <img src="../../../../assets/svg/error_popup.svg" alt="">
    </div>
</div>
<div *ngIf="modalAdviceVisible" class="glass-1 modal-background">
    <div class="glass-1 modal">
        <p class="lf-maintitle bold title">{{adviceData.title}}</p>
        <div class="error-msg">
            <p class="lf-p-3 bold">{{adviceData.msg}} </p>
        </div>
        <app-button link="/" (onClick)="closeAdviceModal()" [type]="1" text="{{adviceData.btnText}}"></app-button>
    </div>
</div>
