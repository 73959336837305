import { ItineraryData } from "../domain/Itinerary"
import { ItineraryRepository } from "../domain/ItineraryRepository"

export class GetBranchItineraries {
  private readonly repository: ItineraryRepository

  constructor(repository: ItineraryRepository) {
    this.repository = repository
  }

  public async execute(token: string): Promise<ItineraryData | undefined> {
      return this.repository.getBranchItineraries(token)
  }
}
