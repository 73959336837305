import { ItineraryCreate } from "../domain/Itinerary"
import { ItineraryRepository } from "../domain/ItineraryRepository"

export class CreateItinerary {
  private readonly repository: ItineraryRepository

  constructor(repository: ItineraryRepository) {
    this.repository = repository
  }

  public async execute(token: string, itinerary:ItineraryCreate): Promise<number | undefined> {
    return this.repository.createItinerary(token, itinerary)
  }
}
