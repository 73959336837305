import { AvailableNew, CertificateData, CourseProgress, GroupDashboard, GroupData, Itinerary, PharmacyDashboard, PharmacyDashboardFilterCourseList, PharmacyDashboardFilterItineraryList, PharmacyData, UserData } from './Dashboard'

export abstract class DashboardRepository {
  abstract ReportHolderByUser(
    accessToken: string, personId?: number
  ): Promise<UserData | undefined>

  abstract GetCourses(
    accessToken: string, personId?: number
  ): Promise<CourseProgress[] | undefined>

  abstract GetItineraries(
    accessToken: string, idLms?: number
  ): Promise<Itinerary[] | undefined>

  abstract GetPharmacyDashboard(
    accessToken: string, pharmacyId?: string
  ): Promise<PharmacyDashboard | undefined>

  abstract GetGroupDashboard(
    accessToken: string
  ): Promise<GroupDashboard | undefined>

  abstract GetUrlFromCourse(
    branchName: string, courseId: string, accessToken: string
  ): Promise<string | undefined>

  abstract EnrrollInTheCourse(
    branchName: string, courseId: string, accessToken: string
  ): Promise<string | undefined>

  abstract GetCertificateData(
    courseId: string, accessToken: string
  ): Promise<CertificateData | undefined>

  abstract GetAvailableNews(
    accessToken: string
  ): Promise<AvailableNew[] | undefined>

  abstract MarkNewsAsViewed(
    accessToken: string, listNews: number[]
  ): Promise<boolean>

  abstract GetPharmacyData(
    accessToken: string, showFilter: string, pharmacyId: number, filterData?: { order?: string, search?: string, itinerary?: string, course?: string }
  ): Promise<PharmacyData | undefined>
  
  abstract GetGroupData(
    accessToken: string, showFilter: string, filterData?: { order?: string, search?: string, itinerary?: string, course?: string }
  ): Promise<GroupData | undefined>

  abstract DownloadPharmacyStats(
    accessToken: string, showFilter: string, pharmacyId: number, filterData?: { order?: string, search?: string, itinerary?: string, course?: string }
  ): Promise<string | undefined>

  abstract DownloadGroupStats(
    accessToken: string, showFilter: string, filterData?: { order?: string, search?: string, itinerary?: string, course?: string }
  ): Promise<string | undefined>

  abstract GetPharmacyDashboardFilterItinerary(
    accessToken: string, pharmacyId?: number
  ): Promise<PharmacyDashboardFilterItineraryList | undefined>

  abstract GetPharmacyDashboardFilterCourses(
    accessToken: string, pharmacyId?: number
  ): Promise<PharmacyDashboardFilterCourseList | undefined>
}