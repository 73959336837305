import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { Environments } from 'src/app/utils/Environments';
import { gsap } from 'gsap'
import { ExpoScaleEase } from "gsap/EasePack";
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements AfterViewInit {

  @ViewChild("footerAnimation") footerAnimation!: ElementRef;

  assetsEnvironment: string = Environments.getS3UrlEnvironment()+'/assets';

  constructor( 
    public googleAnalyticsService: GoogleAnalyticsService,
    public router: Router
  ){
    gsap.registerPlugin(ExpoScaleEase);
  }

  ngAfterViewInit(): void {
    this.initAnimations();
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 0);
  }

  initAnimations() {
    this.footerAnimation.nativeElement.style.overflow = 'hidden';
    gsap.from(this.footerAnimation.nativeElement, {
      height: '0px',
      padding: '0px',
      opacity: 0,
      duration: .8,
      delay: 1,
    });
  }

  isDashboardRoute() {
    return this.router.url.includes('aula');
  }

  isHideAllRoute(){
    return this.router.url.includes('salud_capilar');
  }

  gotoFreeTest(){
    let data = { eventAction: 'click_btn', eventLabel: 'footer' };
    this.googleAnalyticsService.eventEmitter("prueba_gratis", data);
  }
}
