<div class="btn-content">
    <button 
        *ngIf="submit"
        (click)="click()" 
        [disabled]="disable"
        class="btn-{{type}}" 
        type="submit"
        [ngClass]="{loading: loading}"
        [ngClass]="{loading: loading, 'width-hundred-percent': hundredPercent}"
    >
        <p *ngIf="!loading"
            [ngClass]="{
                'desktop-font-size': desktopFontSize !== 0,
                'mobile-font-size': mobileFontSize !== 0
            }">{{text}}</p>
        <div *ngIf="loading" class="loading-img">{{text}}</div>
        <img fill="currentColor" *ngIf="icon && !loading" [src]="ICON_LIST[icon]" alt="" [ngClass]="{
                'icon-width': iconWidth !== 0,
                'icon-height': iconHeight !== 0
            }" [ngStyle]="{
                'margin-right': iconMarginRight + 'px',
                'margin-left': iconMarginLeft + 'px',
                'font-size': iconMarginRight !== 0 ? 'blue' : null
            }">
    </button>
    <button 
        *ngIf="!submit"
        (click)="click()" 
        type="button"
        [disabled]="disable"
        class="btn-{{type}}"
        [ngClass]="{loading: loading}"
        [ngClass]="{loading: loading, 'width-hundred-percent': hundredPercent}"
    >
        <p *ngIf="!loading"
        [ngClass]="{
            'desktop-font-size': desktopFontSize !== 0,
            'mobile-font-size': mobileFontSize !== 0
        }">{{text}}</p>
        <div *ngIf="loading" class=" loading-img">{{text}}</div>
        <img fill="currentColor" *ngIf="icon && !loading" [src]="ICON_LIST[icon]" alt="" [ngClass]="{
            'icon-width': iconWidth !== 0,
            'icon-height': iconHeight !== 0
        }" [ngStyle]="{
                'margin-right': iconMarginRight + 'px',
                'margin-left': iconMarginLeft + 'px',
                'font-size': iconMarginRight !== 0 ? 'blue' : null
            }">
    </button>
    <a class="link" *ngIf="link != ''" [href]="link"></a>
</div>