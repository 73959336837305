<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div class="inputs">
        <app-input
            type="text"
            [hasError]="hasPasswordError"
            [messageError]="passwordMessageError"
            placeholder="{{'change_password.pass_placeholder' | translate}}"
            (change)="checkErrorInputpassword()"
            formControlName="password"
        ></app-input>
        <app-input
            type="text"
            [hasError]="hasPasswordConfirmationError"
            [messageError]="passwordConfirmationMessageError"
            placeholder="{{'change_password.pass_confirm_placeholder' | translate}}"
            (change)="checkErrorInputpasswordConfirmation()"
            formControlName="passwordConfirmation"
        ></app-input>
    </div>
    <p *ngIf="matchError" class="error">{{'change_password.error_text' | translate}}</p>
    <app-button [type]="3" [submit]="true" text="{{'change_password.btn' | translate}}"></app-button>
</form>