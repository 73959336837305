import { DashboardRepository } from '../domain/DashboardRepository'
import { CourseProgress } from '../domain/Dashboard'

export class GetCourses {
  private readonly repository: DashboardRepository

  constructor(repository: DashboardRepository) {
    this.repository = repository
  }

  public async execute(accessToken: string, personId?: number): Promise<CourseProgress[] | undefined> {
    if(personId){
      return this.repository.GetCourses(accessToken, personId);
    }
    return this.repository.GetCourses(accessToken);
  }
}