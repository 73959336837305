import { ContentType } from "../domain/Itinerary"
import { ItineraryRepository } from "../domain/ItineraryRepository"

export class GetCourseContentTypes {
  private readonly repository: ItineraryRepository

  constructor(repository: ItineraryRepository) {
    this.repository = repository
  }

  public async execute(id: number, token: string): Promise<ContentType[] | undefined> {
    return this.repository.getCourseContentTypes(id, token)
  }
}
