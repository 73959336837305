import { ContentType, Course, Itinerary, ItineraryCreate, ItineraryData, ItineraryEdit, PharmacyModel, UserModel } from './Itinerary';

export abstract class ItineraryRepository {
  abstract getUserCourses(token: string): Promise<Course[] | undefined>
  abstract getPharmacies(token: string, itineraryId: number): Promise<PharmacyModel[]>
  abstract getUsers(token: string, itineraryId: number): Promise<UserModel[]>
  abstract getBranchItineraries(token: string): Promise<ItineraryData | undefined>
  abstract getItineraryTemplates(token: string): Promise<Itinerary[] | undefined>
  abstract createItinerary(token: string, itinerary: ItineraryCreate): Promise<number | undefined>
  abstract editItinerary(token: string, itinerary: ItineraryEdit): Promise<number | undefined>
  abstract removeItinerary(token:string, id: number): Promise<'ok' | 'ko' | undefined>
  abstract duplicateItinerary(token:string, id: number): Promise<'ok' | 'ko' | undefined>
  abstract getCourseContentTypes(id: number, token: string): Promise<ContentType[] | undefined>
}
