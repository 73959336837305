import { CartRepository } from '../domain/CartRepository'

export class checkUsedDemo {
    private readonly repository: CartRepository

    constructor(repository: CartRepository) {
        this.repository = repository
    }

    public async execute(userToken: string): Promise<boolean> {
        return this.repository.checkUsedDemo(userToken);
    }
}
