import { NgModule } from '@angular/core';
import { ForgotPasswordComponent } from './forgot-password.component';
import { InputModule } from '../../atoms/inputs/input/input.module';
import { TranslateModule } from 'src/app/pipes/translate.module';
import { ButtonModule } from '../../atoms/button/button.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        ForgotPasswordComponent
    ],
    imports: [
        InputModule,
        TranslateModule,
        ButtonModule,
        CommonModule,
        ReactiveFormsModule
    ],
    exports: [
        ForgotPasswordComponent
    ],
})
export class ForgotPasswordModule { }