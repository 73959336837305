import { Course } from "../domain/Itinerary"
import { ItineraryRepository } from "../domain/ItineraryRepository"

export class GetUserCourses {
  private readonly repository: ItineraryRepository

  constructor(repository: ItineraryRepository) {
    this.repository = repository
  }

  public async execute(token: string): Promise<Course[] | undefined> {
    return this.repository.getUserCourses(token)
  }
}
