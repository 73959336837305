import { AccountRepository } from '../domain/AccountRepository'
import { Account } from '../domain/Account'
import { EventBus } from '../../share/domain/EventBus'

export class AccessTokenAccountLogin {
  private readonly repository: AccountRepository
  private readonly eventBus: EventBus

  constructor(repository: AccountRepository, eventBus: EventBus) {
    this.repository = repository
    this.eventBus = eventBus
  }

  public async execute(accessToken: string): Promise<Account> {
    const result = await this.repository.tokenLogin(accessToken)
    return result
  }
}
