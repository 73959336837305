import { CartRepository } from '../domain/CartRepository'
import { ApplyCouponsResponse, Cart } from '../domain/Cart'

export interface ItemRequest {
  price: string,
  quantity: number,
}

export interface ApplyCouponsRequest {
  coupon: string,
  item: ItemRequest[]
}

export class ApplyCoupons {
  private readonly repository: CartRepository

  constructor(repository: CartRepository) {
    this.repository = repository
  }

  public async execute(params: ApplyCouponsRequest): Promise<ApplyCouponsResponse> {
    return this.repository.applyCoupons({ ...params });
  }
}
