import { CartRepository } from '../domain/CartRepository'
import { Paiment } from '../domain/Cart'

export interface IPaidCart {
  userToken: string, 
  saleCode: string, 
  paymentId: string,
  coupon?: string
}

export class CartPaid {
  private readonly repository: CartRepository

  constructor(repository: CartRepository) {
    this.repository = repository
  }

  public async execute(params: IPaidCart): Promise<Paiment | undefined> {
    return this.repository.paid({ ...params })
  }
}
