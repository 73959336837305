export interface ProgramDetails {
  position: number
  topic: string
  content: {
    name: string
    type: number
    position: number
  }[]
}

export interface ResponseProgramGetById {
  data: {
    getProgram: {
      prg: {
        pos: number
        top: string
        cnt: {
          cntn: string
          cntt: number
          pos: number
        }[]
      }[]
    },
    result: 'ok' | 'ko'
  }
  ok: boolean
}

export const toDomainGetByCourseId = function(response: ResponseProgramGetById): ProgramDetails[] {
  return response.data.getProgram.prg.map(item => ({
    position: item.pos,
    topic: item.top,
    content: item.cnt.map(item => ({
      name: item.cntn,
      type: item.cntt,
      position: item.pos
    }))
  }))
}
