import { CourseRepository } from '../domain/CourseRepository'
import { CourseDetails } from '../domain/Course'

export class GetCourseById {
  private readonly repository: CourseRepository

  constructor(repository: CourseRepository) {
    this.repository = repository
  }

  public async execute(path: string): Promise<CourseDetails | undefined> {
    return this.repository.getById(path)
  }
}
