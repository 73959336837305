import { Component, OnInit } from '@angular/core';
import { MaganeSimpleBannerService } from './manage-simple-banner.service';

@Component({
  selector: 'app-simple-banner',
  templateUrl: './simple-banner.component.html',
  styleUrls: ['./simple-banner.component.scss']
})
export class SimpleBannerComponent implements OnInit{

  isShowBanner: boolean = false;
  bannerhtml: string = ''; 
  backgroundcolor: string = '#85dfff';

  constructor(
    private manageSimpleBanner: MaganeSimpleBannerService,
  ) { }

  ngOnInit(): void {
    this.manageSimpleBanner.getBannerData$().subscribe(
      bannerhtml =>{
        this.bannerhtml = bannerhtml;
        if (this.bannerhtml){
          this.showBanner(this.bannerhtml);
        }else{
          this.hideBanner();
        }
      } 
    )
  }

  showBanner(bannerhtml: string){
    this.isShowBanner = true;
  }

  hideBanner(){
    this.isShowBanner = false;
  }
}

