import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare let gtag:Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  public eventEmitter( eventName: string, data: Object){
    gtag('event', eventName, data);
  }

  public dataLayerPush(data: any){
    window.dataLayer.push(data);
  }

  async getGAClientId(): Promise<string> {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; _ga=`);
    if (!parts || parts.length !== 2) {
      return '';
    }
    const gaPart = parts.pop();
    if (!gaPart) {
      return '';
    }
    const clientIdParts = gaPart.split(';').shift();
    if (!clientIdParts) {
      return '';
    }
    const clientId = clientIdParts.split('.').slice(2, 4).join('.');
    return clientId;
  }
}

export interface googleAnalitycData{
  eventName: string,
  eventAction: string,
  eventLabel?: string
}
