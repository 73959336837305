import { ErrorHandler, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RequestErrorList } from '../cross/share/domain/RequestError';
import { showGenericError } from '../state/error.actions';
import { LoggerProviderService } from './logger/logger-provider.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
  private readonly store: Store
  private readonly loggerProviderService: LoggerProviderService

  constructor(
    store: Store,
    loggerProviderService: LoggerProviderService,
  ) {
    this.store = store
    this.loggerProviderService = loggerProviderService
  }

  handleError(err: Error) {
    this.loggerProviderService.error(err)

    const { message } = err

    if(message.includes(RequestErrorList.GENERIC)) {
      this.store.dispatch(showGenericError({ message: message }));
      return
    }

  }
}
