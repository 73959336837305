import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ModalData } from '../components/molecules/error-modal/error-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorModalService {

  private error$: Subject<ModalData>;

  constructor() {this.error$ = new Subject(); }

  dispatchModalError(modalData:ModalData){
    this.error$.next(modalData);
  }

  getError$(): Observable<ModalData>{
    return this.error$.asObservable();
  }
}
