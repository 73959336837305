import { CrmRepository } from '../domain/CrmRepository'

export class AddToLeadList {
  private readonly repository: CrmRepository

  constructor(repository: CrmRepository) {
    this.repository = repository
  }

  public async execute(
    app: number,
    licenseId: number,
    funnel: number,
    type: number,
    email: string,
    firstName: string): Promise<boolean | undefined> {
    return this.repository.addToLeadList(app, licenseId, funnel, type, email, firstName);
  }
}
