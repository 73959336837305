import { AccountRepository } from '../domain/AccountRepository'
import { Account } from '../domain/Account'
import { EventBus } from '../../share/domain/EventBus';
import { EventAction } from '../../share/domain/EventAction';

export class AccountSignUp {
  private readonly repository: AccountRepository
  private readonly eventBus: EventBus

  constructor(repository: AccountRepository, eventBus: EventBus) {
    this.repository = repository
    this.eventBus = eventBus
  }

  public async execute(name: string,lastName: string, 
                       email:string, password: string, 
                       nick: string, news_letter: boolean,
                       type: 1 | 2 | 3,
                       pharmacy_name?: string, branch_name?: string,
                       saleCode?: string): Promise<Account | undefined> {
    const signUp = await this.repository.signUp(name, lastName, email, password, nick, news_letter, type, pharmacy_name, branch_name);

    if(!signUp) {
      return
    }

    const result = await this.repository.login(email, password)

    if (saleCode) {
      this.eventBus.notify(EventAction.THE_USER_HAS_LOGGED_IN, {
        userToken: result.token,
        saleCode
      })
    }

    return result
  }
}
