import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { InfoBtnPopupData } from './info-btn-popup.component';

@Injectable({
  providedIn: 'root'
})
export class InfoBtnPopupService {
  private infoPopUp$: Subject<{ show: boolean, data?: InfoBtnPopupData }>;

  constructor() { this.infoPopUp$ = new Subject(); }

  toggleInfoPopup(show: boolean, data?: InfoBtnPopupData) {
    if (data) {
      this.infoPopUp$.next({ show, data });
    } else {
      this.infoPopUp$.next({ show });
    }
  }

  actioninfoPopUp$(): Observable<{ show: boolean, data?: InfoBtnPopupData }> {
    return this.infoPopUp$;
  }
}
