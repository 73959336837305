import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { animationNames } from './loading-screen.component';

@Injectable({
  providedIn: 'root'
})
export class ManageLoadingScreenService {

  private loadingScreen$: Subject<{show: boolean, texts: string[], animation: keyof animationNames}>;

  constructor() {this.loadingScreen$ = new Subject(); }


  toggleLoadingScreen(show: boolean, texts: string[] = [], animation: keyof animationNames = 'none'){
    if(!show){
      setTimeout(() => this.loadingScreen$.next({show: show, texts: texts, animation: animation}), 500);
      return;
    } 
    this.loadingScreen$.next({show: show, texts: texts, animation: animation});
  }

  actionLoadingScreen$(): Observable<{show: boolean, texts: string[], animation: keyof animationNames}>{
    return this.loadingScreen$.asObservable();
  }
}
