import { ActionReducerMap } from '@ngrx/store';
import { Cart, LicenseId } from '../cross/cart/domain/Cart';
import { licenseReducer, cartReducer } from './cart.reducer';
import { errorReducer } from './error.reducer';

export interface AppState {
  license: ReadonlyArray<LicenseId>
  cart: ReadonlyArray<Cart>
  error: ReadonlyArray<string>
}

export const ROOT_REDUCERS: ActionReducerMap<AppState> = {
  license: licenseReducer,
  cart: cartReducer,
  error: errorReducer
}