import { CustomError } from "../../share/domain/CustomError"

export enum RequestErrorList {
  GENERIC = 'The request cant be process'
}

export class RequestError extends Error implements CustomError {
  public override readonly name: string = 'request.error'

  public static generic(context: string, data: unknown): RequestError {
    return new this(`Context: ${context} | The request cant be process: ${JSON.stringify(data)}`)
  }
}
