import { DashboardRepository } from '../domain/DashboardRepository'
import { AvailableNew } from '../domain/Dashboard'

export class GetAvailableNews {
  private readonly repository: DashboardRepository

  constructor(repository: DashboardRepository) {
    this.repository = repository
  }

  public async execute(accessToken: string): Promise<AvailableNew[] | undefined> {
    return this.repository.GetAvailableNews(accessToken)
  }
}