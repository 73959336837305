import { environment } from '../../../../environments/environment'
import { RequestError } from '../../share/domain/RequestError'
import { CrmRepository } from '../domain/CrmRepository'
import { toDomainAddToLeadList, toDomainGetById } from '../domain/Crm'

export class CrmRepositoryRest extends CrmRepository {
  private readonly HOST = environment.host
  // private readonly HOST = 'https://d3v-web-api.liceodefarmacia.com'
  private readonly PCEK = environment.pcek
  // private readonly PCEK = 'ceroyuno'

  public async addToNewsLetter( app: number, email: string, firstName: string, type?: number): Promise<boolean | undefined> {
    const response = await fetch(`${this.HOST}/crm/addToNewsletter`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK
      },
      body: JSON.stringify({ app: app, ...(type && {typ: type}), email: email, f_name: firstName })
    })

    let result;
    try {
      result = await response.json();
    } catch (error) {
      throw RequestError.generic(this.constructor.name, {err: "Unexpected end of JSON input", data: response})
    }

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    // course not avaible
    // if (result.data?.result === 'cid_ms'
    //   || result.data?.result === 'pid_int'
    //   || result.data?.result === 'p_nf'
    //   || result.data?.result === 'cid_int'
    //   || result.data?.result === 'co_nf'
    // ) {
    //   throw CourseError.courseNotFound()
    // }

    // if (response.ok === false) {
    //   return
    // }

    return toDomainGetById(result);
  }

  public async addToLeadList(app: number, licenseId: number, funnel: number, 
                            type: number, email: string, firstName: string): Promise<boolean | undefined> {
    const response = await fetch(`${this.HOST}/crm/addToLeadList`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK
      },
      body: JSON.stringify({ app: app, lid: licenseId, fnl: funnel, typ: type, email: email, f_name: firstName })
    })

    let result;
    try {
      result = await response.json();
    } catch (error) {
      throw RequestError.generic(this.constructor.name, {err: "Unexpected end of JSON input", data: response})
    }

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    // course not avaible
    // if (result.data?.result === 'cid_ms'
    //   || result.data?.result === 'pid_int'
    //   || result.data?.result === 'p_nf'
    //   || result.data?.result === 'cid_int'
    //   || result.data?.result === 'co_nf'
    // ) {
    //   throw CourseError.courseNotFound()
    // }

    // if (response.ok === false) {
    //   return
    // }

    return toDomainAddToLeadList(result);
  }
}
