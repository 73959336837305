import { CustomError } from "../../share/domain/CustomError"

export enum CourseErrorList {
  COURSES_NOT_FOUND = 'Courses not found',
  COURSE_NOT_FOUND = 'Course not found',
  PROGRAM_NOT_FOUND = 'Program not found',
  CATEGORIES_NOT_FOUND = ' Categories not found',
}

export class CourseError extends Error implements CustomError {
  public override readonly name: string = 'course.error'

  public static coursesNotFound (): CourseError {
    return new this(CourseErrorList.COURSES_NOT_FOUND)
  }

  public static courseNotFound (): CourseError{
    return new this(CourseErrorList.COURSE_NOT_FOUND)
  }

  public static programNotFound (): CourseError{
    return new this(CourseErrorList.PROGRAM_NOT_FOUND)
  }

  public static categoriesNotFound (): CourseError{
    return new this(CourseErrorList.CATEGORIES_NOT_FOUND)
  }
}
