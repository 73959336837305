import { Injectable } from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { BrowserService } from './browser.service';

export const COOKIES_KEYS = {
  ACCESS_TOKEN: 'at',
}

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  private DOMAIN: string = ".liceodefarmacia.com";
  private SUFFIX: string = "_dev";

  constructor(
    private cookieService: CookieService,
    private browserService: BrowserService,
  ) { }

  clear(): void{
    this.browserService.onlyBrowser(() => {
      this.cookieService.deleteAll();
    });
  }

  set(key: string, data: any): void{
    if(!environment.production) key += this.SUFFIX;
    this.browserService.getDocument((_document: any) => {
      const domain = _document.location.hostname == "localhost" ? "localhost" : this.DOMAIN;
      this.cookieService.set(key, data, { domain, path: "/", expires: 365 });
    });
  }

  get(key: string): any{
    if (!environment.production) key += this.SUFFIX;
    let resp = ""
    this.browserService.onlyBrowser(() => {
      resp = this.cookieService.get(key);
    });
    return resp;
  }

  getAll(): unknown{
    let resp = {}
    this.browserService.onlyBrowser(() => {
      let data = this.cookieService.getAll();
      Object.keys(data).forEach(key => {
        data[key] = data[key];
      })
      resp = data;
    });
    return resp;
  }

  delete(key: string): void{
    if (!environment.production) key += this.SUFFIX;
    this.browserService.getDocument((_document: any) => {
      const domain = _document.location.hostname == "localhost" ? "localhost" : this.DOMAIN;
      this.cookieService.delete(key, "/", domain);
    });
  }

  setAccessToken(data: any): void{
    this.set(COOKIES_KEYS.ACCESS_TOKEN, data);
  }

  getAccessToken(): any{
    return this.get(COOKIES_KEYS.ACCESS_TOKEN);
  }

  removeAccessToken(): void{
    this.delete(COOKIES_KEYS.ACCESS_TOKEN);
  }
}
