<div class="input">
    <textarea
    #input
    name=""
    id=""
    cols="30"
    rows="10"
    maxlength="500"
    [style.height]="height"
    [placeholder]="placeholder"
    class="glass-2 lf-p-5 regular"></textarea>
</div>
