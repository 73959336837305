import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PlanData } from '../components/molecules/plan/plan.component';
import { TranslateService } from './translate.service';

@Injectable({
    providedIn: 'root'
})
export class PlansServiceService {

    public static TITULAR: 1 = 1;
    public static PROFESSIONAL: 2 = 2;
    public static STUDENT: 3 = 3;

    public planList: PlanModel[] = environment.production? [
        {
            title: 'Plan profesional de farmacia',
            license_type: 2,
            plan_info: [
                '+ de 150 cursos',
                'Nuevos cursos cada mes',
                'Formación creada por profesionales',
                'Formación teórico y práctica',
                'Píldoras de 5 minutos',
                'Acceso desde el móvil u ordenador'
            ],
            licenses: [
                {
                    per: 2,
                    demo: true,
                    li: 12,
                    pr: 13.00,
                    stp: "price_1Lk9uZK3SQ7Vv3Rw4cwOkMSl",
                    bst: false
                },
                {
                    per: 1,
                    demo: true,
                    li: 13,
                    pr: 132.00,
                    stp: "price_1Lk9vUK3SQ7Vv3Rwkj0BQWsO",
                    bst: false
                },
                {
                    per: 1,
                    demo: false,
                    li: 4,
                    pr: 132.00,
                    stp: "price_1Lk9uyK3SQ7Vv3RwcFB4RODN",
                    bst: true
                },
                {
                    per: 2,
                    demo: false,
                    li: 3,
                    pr:13.00,
                    stp: "price_1Lk9twK3SQ7Vv3Rw4phwDJhw",
                    bst: false
                }
            ],
            condition: '',
        },
        {
            title: 'Plan estudiantes de farmacia',
            license_type: 3,
            plan_info: [
                '20 cursos esenciales de farmacia',
                'Formación creada por profesionales',
                'Contenido actualizado',
                'Formación teórico y práctica',
                'Píldoras de 5 minutos',
                'Acceso desde el móvil u ordenador'
            ],
            licenses: [
                {
                    per: 2,
                    demo: true,
                    li: 14,
                    pr: 6.00,
                    stp: "price_1Lk9xvK3SQ7Vv3Rw0ER1IVHZ",
                    bst: false
                },
                {
                    per: 1,
                    demo: true,
                    li: 15,
                    pr: 48.00,
                    stp: "price_1Lk9ygK3SQ7Vv3RwU04tphlF",
                    bst: false
                },
                {
                    per: 1,
                    demo: false,
                    li: 2,
                    pr: 48.00,
                    stp: "price_1Lk9yGK3SQ7Vv3RwVly018Ge",
                    bst: true
                },
                {
                    per: 2,
                    demo: false,
                    li: 1,
                    pr: 6.00,
                    stp: "price_1Lk9wyK3SQ7Vv3RwrFryUpzR",
                    bst: false
                }
            ],
            condition: '',
        },
        {
            title: 'Farmacia M',
            license_type: 1,
            plan_info: [
                '+ de 150 cursos',
                'Todos las semanas nuevos cursos',
                'Formación creada por profesionales',
                'Contenido actualizado',
                'Formación teórico y práctica',
                'Píldoras de 5 minutos',
                'Acceso desde el móvil u ordenador',
                'Escenas de mostrador'
            ],
            plan_include: [
                '4 usuarios',
                'Espacio con logo de farmacia',
            ],
            licenses: [
                {
                    per: 2,
                    demo: true,
                    li: 16,
                    pr: 59.29,
                    stp: "price_1Lk9rSK3SQ7Vv3Rw68ib7Nre",
                    bst: false
                },
                {
                    per: 1,
                    demo: true,
                    li: 17,
                    pr: 595.32,
                    stp: "price_1Lk9sxK3SQ7Vv3RwJCsRHPip",
                    bst: false
                },
                {
                    per: 1,
                    demo: false,
                    li: 6,
                    pr: 595.32,
                    stp: "price_1Lk9sVK3SQ7Vv3RwwUzE3XBi",
                    bst: false
                },
                {
                    per: 2,
                    demo: false,
                    li: 5,
                    pr: 59.29,
                    stp: "price_1Lk9r0K3SQ7Vv3Rw0VYw0SKU",
                    bst: false
                }
            ],
            condition: '',
        },
        {
            title: 'Farmacia L',
            license_type: 1,
            plan_info: [
                '+ de 150 cursos',
                'Todos las semanas nuevos cursos',
                'Formación creada por profesionales',
                'Contenido actualizado',
                'Formación teórico y práctica',
                'Píldoras de 5 minutos',
                'Acceso desde el móvil u ordenador',
                'Escenas de mostrador'
            ],
            plan_include: [
                '10 usuarios',
                'Espacio con logo de farmacia',
                'Sube tus propias formaciones',
            ],
            licenses: [
                {
                    per: 2,
                    demo: true,
                    li: 18,
                    pr: 119.79,
                    stp: "price_1Lk9opK3SQ7Vv3RwUZ8AUY4t",
                    bst: false
                },
                {
                    per: 1,
                    demo: true,
                    li: 19,
                    pr: 1205.16,
                    stp: "price_1Lk9qDK3SQ7Vv3Rw1GNhB9Q4",
                    bst: true
                },
                {
                    per: 1,
                    demo: false,
                    li: 8,
                    pr: 1205.16,
                    stp: "price_1Lk9pdK3SQ7Vv3RwuPVkhDuZ",
                    bst: false
                },
                {
                    per: 2,
                    demo: false,
                    li: 7,
                    pr: 119.79,
                    stp: "price_1Lk9o5K3SQ7Vv3RwnxvV4KjU",
                    bst: false
                }
            ],
            condition: '',
        },
        {
            title: 'Farmacia XL',
            license_type: 1,
            plan_info: [
                '+ de 150 cursos',
                'Todos los meses nuevos cursos',
                'Formación creada por profesionales',
                'Contenido actualizado',
                'Formación teórico y práctica',
                'Píldoras de 5 minutos',
                'Acceso desde el móvil u ordenador',
                'Escenas de mostrador'
            ],
            plan_include: [
                '20 usuarios',
                'Espacio con logo de farmacia',
                'Sube tus propias formaciones',
                'Informes de seguimiento',
                'Itinerarios formativos personalizados para cada usuario'
            ],
            licenses: [
                {
                    per: 2,
                    demo: true,
                    li: 20,
                    pr: 192.39,
                    stp: "price_1Lk9ckK3SQ7Vv3Rw8aYQcGDf",
                    bst: false
                },
                {
                    per: 1,
                    demo: true,
                    li: 21,
                    pr: 1931.16,
                    stp: "price_1Lk9f1K3SQ7Vv3RwQ3fRU0CG",
                    bst: false
                },
                {
                    per: 1,
                    demo: false,
                    li: 10,
                    pr: 1931.16,
                    stp: "price_1Lk9eLK3SQ7Vv3RwkBBx5GfM",
                    bst: false
                },
                {
                    per: 2,
                    demo: false,
                    li: 9,
                    pr: 192.39,
                    stp: "price_1Lk8YlK3SQ7Vv3RwT3lqKrtf",
                    bst: false
                }
            ],
            condition: '',
        }
    ] : 
    [
        {
            title: 'Plan profesional de farmacia',
            license_type: 2,
            plan_info: [
                '+ de 150 cursos',
                'Nuevos cursos cada mes',
                'Formación creada por profesionales',
                'Formación teórico y práctica',
                'Píldoras de 5 minutos',
                'Acceso desde el móvil u ordenador'
            ],
            licenses: [
                {
                    per: 2,
                    demo: true,
                    li: 12,
                    pr: 13.00,
                    stp: "price_1LQyhRGxw8BJK0e0l0oBWCr2",
                    bst: false
                },
                {
                    per: 1,
                    demo: true,
                    li: 13,
                    pr: 132.00,
                    stp: "price_1LQyjeGxw8BJK0e0lRu1ikPG",
                    bst: false
                },
                {
                    per: 1,
                    demo: false,
                    li: 4,
                    pr: 132.00,
                    stp: "price_1LQyhRGxw8BJK0e0LsDx70s8",
                    bst: true
                },
                {
                    per: 2,
                    demo: false,
                    li: 3,
                    pr:13.00,
                    stp: "price_1LQyhRGxw8BJK0e04Hrh0xwg",
                    bst: false
                }
            ],
            condition: '',
        },
        {
            title: 'Plan estudiantes de farmacia',
            license_type: 3,
            plan_info: [
                '20 cursos esenciales de farmacia',
                'Formación creada por profesionales',
                'Contenido actualizado',
                'Formación teórico y práctica',
                'Píldoras de 5 minutos',
                'Acceso desde el móvil u ordenador'
            ],
            licenses: [
                {
                    per: 2,
                    demo: true,
                    li: 14,
                    pr: 6.00,
                    stp: "price_1LQyfvGxw8BJK0e00mwPNCGH",
                    bst: false
                },
                {
                    per: 1,
                    demo: true,
                    li: 15,
                    pr: 48.00,
                    stp: "price_1LQykMGxw8BJK0e0KVFdrsoV",
                    bst: false
                },
                {
                    per: 1,
                    demo: false,
                    li: 2,
                    pr: 48.00,
                    stp: "price_1LQydwGxw8BJK0e02HQyF3KO",
                    bst: true
                },
                {
                    per: 2,
                    demo: false,
                    li: 1,
                    pr: 6.00,
                    stp: "price_1LQyd9Gxw8BJK0e0Weh9aTPs",
                    bst: false
                }
            ],
            condition: '',
        },
        {
            title: 'Farmacia M',
            license_type: 1,
            plan_info: [
                '+ de 150 cursos',
                'Todos las semanas nuevos cursos',
                'Formación creada por profesionales',
                'Contenido actualizado',
                'Formación teórico y práctica',
                'Píldoras de 5 minutos',
                'Acceso desde el móvil u ordenador',
                'Escenas de mostrador'
            ],
            plan_include: [
                '4 usuarios',
                'Espacio con logo de farmacia',
            ],
            licenses: [
                {
                    per: 2,
                    demo: true,
                    li: 16,
                    pr: 59.29,
                    stp: "price_1LQymAGxw8BJK0e0xJ1zlqUt",
                    bst: false
                },
                {
                    per: 1,
                    demo: true,
                    li: 17,
                    pr: 595.32,
                    stp: "price_1LQymAGxw8BJK0e0XNAWW4LJ",
                    bst: false
                },
                {
                    per: 1,
                    demo: false,
                    li: 6,
                    pr: 595.32,
                    stp: "price_1LQymAGxw8BJK0e0t73sD9sd",
                    bst: false
                },
                {
                    per: 2,
                    demo: false,
                    li: 5,
                    pr: 59.29,
                    stp: "price_1LQymAGxw8BJK0e04VDp4GCx",
                    bst: false
                }
            ],
            condition: '',
        },
        {
            title: 'Farmacia L',
            license_type: 1,
            plan_info: [
                '+ de 150 cursos',
                'Todos las semanas nuevos cursos',
                'Formación creada por profesionales',
                'Contenido actualizado',
                'Formación teórico y práctica',
                'Píldoras de 5 minutos',
                'Acceso desde el móvil u ordenador',
                'Escenas de mostrador'
            ],
            plan_include: [
                '10 usuarios',
                'Espacio con logo de farmacia',
                'Sube tus propias formaciones'
            ],
            licenses: [
                {
                    per: 2,
                    demo: true,
                    li: 18,
                    pr: 119.79,
                    stp: "price_1LQynNGxw8BJK0e0yu0ROiKL",
                    bst: false
                },
                {
                    per: 1,
                    demo: true,
                    li: 19,
                    pr: 1205.16,
                    stp: "price_1LQynOGxw8BJK0e0cKPFxuo8",
                    bst: true
                },
                {
                    per: 1,
                    demo: false,
                    li: 8,
                    pr: 1205.16,
                    stp: "price_1LQynNGxw8BJK0e0oUn22urB",
                    bst: false
                },
                {
                    per: 2,
                    demo: false,
                    li: 7,
                    pr: 119.79,
                    stp: "price_1LdArOGxw8BJK0e0INwuFPnP",
                    bst: false
                }
            ],
            condition: '',
        },
        {
            title: 'Farmacia XL',
            license_type: 1,
            plan_info: [
                '+ de 150 cursos',
                'Todos las semanas nuevos cursos',
                'Formación creada por profesionales',
                'Contenido actualizado',
                'Formación teórico y práctica',
                'Píldoras de 5 minutos',
                'Acceso desde el móvil u ordenador',
                'Escenas de mostrador'
            ],
            plan_include: [
                '20 usuarios',
                'Espacio con logo de farmacia',
                'Sube tus propias formaciones',
                'Informes de seguimiento',
                'Itinerarios formativos personalizados para cada usuario'
            ],
            licenses: [
                {
                    per: 2,
                    demo: true,
                    li: 20,
                    pr: 192.39,
                    stp: "price_1LQyoqGxw8BJK0e0rvEfYefo",
                    bst: false
                },
                {
                    per: 1,
                    demo: true,
                    li: 21,
                    pr: 1931.16,
                    stp: "price_1LdAp3Gxw8BJK0e0InDoxzqv",
                    bst: false
                },
                {
                    per: 1,
                    demo: false,
                    li: 10,
                    pr: 1931.16,
                    stp: "price_1LQyoqGxw8BJK0e0ZaWJ6zmx",
                    bst: false
                },
                {
                    per: 2,
                    demo: false,
                    li: 9,
                    pr: 192.39,
                    stp: "price_1LQyoqGxw8BJK0e0XO84FZzP",
                    // pra:1919.88,
                    bst: false
                }
            ],
            condition: '',
        }
    ] 

    constructor(
        private translateService: TranslateService,

    ) { }

    public getPlan(id: number): PlanModel | undefined {
        let finalPlan: PlanModel | undefined = this.planList.find(plan =>
            plan.licenses.find(license =>
                license.li == id
            ) != undefined
        );
        return finalPlan;
    }

    public getLicense(id:number): PlanData | undefined{
        let finalPlan: PlanModel | undefined = this.planList.find(plan =>
            plan.licenses.find(license =>
                license.li == id
            ) != undefined
        );
        let finalLicense: PlanData |undefined = undefined;
        finalPlan?.licenses.forEach((license)=>{
            if(license.li == id && finalPlan){
                finalLicense = {
                    title: finalPlan.title,
                    plan_info: finalPlan.plan_info,
                    license_type: finalPlan.license_type, //(profesional: 1, farmacia: 2 o estudiante: 3)
                    license: license,
                    condition: finalPlan.condition,
                }
            }
        })
        return finalLicense;
    }

    public getPlansOfType(licenseType: (1|2|3)[]): PlanModel[]{ //(profesional: 1, farmacia: 2 o estudiante: 3)
        let filteredList: PlanModel[] = [];
        licenseType.forEach(type => {
            this.planList.forEach((plan)=>{
                if(plan.license_type == type){
                    filteredList.push(plan);
                }
            });
        });
        return filteredList;
    }

    getLicensesId(demo: boolean): number[]{
        let licenses: number[] = [];
        this.planList.forEach((plan)=>{
            plan.licenses.forEach((lic)=>{
                if(demo){
                    if(lic.demo){
                        licenses.push(lic.li);
                    }
                }else{
                    if(!lic.demo){
                        licenses.push(lic.li);
                    }
                }
            })
        })
        return licenses;
    }

    getMonthlyLicense(plan: PlanModel | undefined): {per: number, demo: boolean,
                                            li: number, pr: number,
                                            stp: string,bst: boolean} | undefined{
        return plan?.licenses.find((license)=>
            license.per == 2 && license.demo == false
        );
    }
    getAnualLicense(plan: PlanModel | undefined): {per: number, demo: boolean,
                                            li: number, pr: number,
                                            stp: string,bst: boolean} | undefined{
        return plan?.licenses.find((license)=>
            license.per == 1 && license.demo == false
        );
    }
    getAnualDemoLicense(plan: PlanModel | undefined): {per: number, demo: boolean,
                                            li: number, pr: number,
                                            stp: string,bst: boolean} | undefined{
        return plan?.licenses.find((license)=>
            license.per == 1 && license.demo == true
        );
    }
    getMonthlyDemoLicense(plan: PlanModel | undefined): {per: number, demo: boolean,
                                            li: number, pr: number,
                                            stp: string,bst: boolean} | undefined{
        return plan?.licenses.find((license)=>
            license.per == 2 && license.demo == true
        );
    }

    periodToInt(period: string){
        if(period == this.translateService.translate('plans_sec.periods.monthly')){
            return 2;
        }
        return 1;
    }
    typeToInt(type: 'individual'|'pharma'): (1|2|3)[]{
        if(type == 'individual'){
            return [PlansServiceService.PROFESSIONAL,PlansServiceService.STUDENT];
        }
        return [PlansServiceService.TITULAR];

    }
    numberToType(typeNumber: number): string{
        let type: string= 'desconocido';
        switch(typeNumber){
            case 1:
                type = 'titular'
                break;
            case 2:
                type = 'profesional'
                break;
            case 3:
                type = 'farmacia'
                break;
        }
        return type;

    }

    generateList(type: (1|2|3)[], period: 1|2, demo?: boolean): PlanData[]{
        let plansList: PlanData[] = [];
        this.getPlansOfType(type).forEach((plan)=>{
          let planLicense: {
            per: number,// (1: anual, 2: mensual)
            demo: boolean,
            li: number,
            pr: number,
            stp: string,
            bst: boolean
          } | undefined;
          if(demo){
            if(period == 1){
              planLicense = this.getAnualDemoLicense(plan);
            } else {
              planLicense = this.getMonthlyDemoLicense(plan);
            }
          }else{
            if(period == 1){
              planLicense = this.getAnualLicense(plan);
            } else {
              planLicense = this.getMonthlyLicense(plan);
            }
          }
          plansList.push({
            title: plan.title,
            plan_info: plan.plan_info,
            license_type: plan.license_type,
            license: planLicense,
            condition: plan.condition
          });
        })
        return plansList;
      }
}

export interface PlanModel {
    title: string,
    plan_info: string[],
    plan_include?: string[],
    license_type: 1 | 2 | 3, //(titular: 1, profesional: 2 o estudiante: 3)
    licenses:{
        per: number,// (1: anual, 2: mensual)
        demo: boolean,
        li: number,
        pr: number,
        stp: string,
        bst: boolean
    }[],
    condition: string,
}
