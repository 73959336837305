import { SubcriptionRepository } from '../domain/SubcriptionRepository'
import { Unsubscription, toDomainUnsubscription, ActivePlans, toDomainActivePlans, toDomainCheckSubscriptions } from '../domain/Subcription'
import { environment } from '../../../../environments/environment'
import { RequestError } from '../../share/domain/RequestError'
import { SubcriptionError } from '../domain/SubcriptionError'

export class SubcriptionRepositoryRest extends SubcriptionRepository {
  private readonly HOST = environment.host
  private readonly PCEK = environment.pcek

  public async unsubscribe(accessToken: string, enrollmentId: number): Promise<Unsubscription | undefined> {
    const response = await fetch(`${this.HOST}/buy/unsubscribe`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': accessToken
      },
      body: JSON.stringify({ eid: enrollmentId })
    })

    let result;
    try {
      result = await response.json();
    } catch (error) {
      throw RequestError.generic(this.constructor.name, {err: "Unexpected end of JSON input", data: response})
    }

    // Uncaught generic error
    if(response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }
        
    // subscriptions not found
    if(result.data?.result === 'pid_nf'
      || result.data?.result === 'ens_nf'
      ) {
      throw SubcriptionError.susbcriptionsNotFound()
    }

    // Expired session
    if(result.data?.result === 'sc_nf'
      || result.data?.result === 'sc_inv'
      || result.data?.result === 'exps'
      ) {
      throw SubcriptionError.expiredSession()
    }

    // if (response.ok === false) {
    //   return
    // }

    
    return toDomainUnsubscription(result);
  }

  public async getSubscriptions(token: string): Promise<ActivePlans | undefined> {
    const response = await fetch(`${this.HOST}/buy/getSubscriptions`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': token
      },
      body: JSON.stringify({})
    })

    let result;
    try {
      result = await response.json();
    } catch (error) {
      throw RequestError.generic(this.constructor.name, {err: "Unexpected end of JSON input", data: response})
    }

    // Uncaught generic error
    if(response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }
        
    // subscriptions already expired
    if(result.data?.result === 'eid_exp') {
      throw SubcriptionError.subAlreadyExpired()
    }
    
    // Expired session
    if(result.data?.result === 'sc_nf'
    || result.data?.result === 'sc_inv'
    || result.data?.result === 'exps'
    ) {
      throw SubcriptionError.expiredSession()
    }
    
    
    // enrrolment not found
    if(result.data?.result === 'ens_nf') {
      return {licenses: []};
    }

    // if (response.ok === false) {
    //   return
    // }
    return toDomainActivePlans(result);
  }

  public async checkSubscriptions(token: string): Promise<boolean> {
    const response = await fetch(`${this.HOST}/buy/checkSubscriptions`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': token
      },
      body: JSON.stringify({})
    })

    let result;
    try {
      result = await response.json();
    } catch (error) {
      throw RequestError.generic(this.constructor.name, {err: "Unexpected end of JSON input", data: response})
    }

    // Uncaught generic error
    if(response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }
        
    // subscriptions already expired
    if(result.data?.result === 'eid_exp') {
      throw SubcriptionError.subAlreadyExpired()
    }

    // Expired session
    if(result.data?.result === 'sc_nf'
      || result.data?.result === 'sc_inv'
      || result.data?.result === 'exps'
      ) {
      throw SubcriptionError.expiredSession()
    }

    // if (response.ok === false) {
    //   return
    // }
    return toDomainCheckSubscriptions(result);
  }
}
