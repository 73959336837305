import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { googleAnalitycData, GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

type iconNames = {
  arrow: string,
  certificate: string,
  plus: string,
  linkedin: string,
  left_arrow: string
}
@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})

export class ButtonComponent implements OnInit, OnChanges {
  
  ICON_LIST: iconNames = {
    arrow: "../../../../assets/svg/liceo-icons/Flecha-der.svg",
    certificate: '../../../../assets/svg/liceo-icons/certificado.svg',
    plus: '../../../../assets/svg/liceo-icons/plus_icon.svg',
    linkedin: '../../../../assets/img/linkedin.png',
    left_arrow: '../../../../assets/svg/liceo-icons/Flecha-izq.svg'
  }
  
  @Input() disable?: boolean = false;
  @Input() link: string = '';
  @Input() text: string = '';
  @Input() type: 1 | 2 | 3 | 4 = 1;
  @Input() loading: boolean = false;
  @Input() submit: boolean = false;
  @Input() icon?: keyof iconNames;
  @Input() googleAnalitycData?: googleAnalitycData; 
  @Input() hundredPercent: boolean = false;
  @Input() iconMarginRight: number = 0;
  @Input() iconMarginLeft: number = 0;
  @Input() iconWidth: number = 0;
  @Input() iconHeight: number = 0;
  @Input() horizontalPadding: number = 30;
  @Input() desktopFontSize: number = 0;
  @Input() mobileFontSize: number = 0;
  @Input() iconSide: 'right' | 'left' = 'right';

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.desktopFontSize !== 0) {
      this.elementRef.nativeElement.style.setProperty('--desktop-font-size', `${this.desktopFontSize}px`);
    }
    if(this.iconWidth){
      this.elementRef.nativeElement.style.setProperty('--icon-width', `${this.iconWidth}px`);
    }
    
    this.elementRef.nativeElement.style.setProperty('--btn-h-padding', `${this.horizontalPadding}px`);

    if (this.mobileFontSize !== 0) {
      this.elementRef.nativeElement.style.setProperty('--mobile-font-size', `${this.mobileFontSize}px`);
    }
    if(this.iconHeight){
      this.elementRef.nativeElement.style.setProperty('--icon-height', `${this.iconHeight}px`);
    }
  }

  click(){
    if(this.googleAnalitycData){
      let googleAnalitycEventLabel = this.googleAnalitycData.eventLabel;
      let googleAnalitycData = { eventAction: this.googleAnalitycData.eventAction, googleAnalitycEventLabel };
      this.googleAnalyticsService.eventEmitter(this.googleAnalitycData.eventName, googleAnalitycData);
    }
    this.onClick.emit(null);
  }
}
