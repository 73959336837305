import { DashboardRepository } from '../domain/DashboardRepository'
import { GroupData, PharmacyData } from '../domain/Dashboard'

export class GetGroupData {
  private readonly repository: DashboardRepository

  constructor(repository: DashboardRepository) {
    this.repository = repository
  }

  public async execute(
    accessToken: string,
    showFilter: string,
    filterData?: { order?: string, search?: string, itinerary?: string, course?: string }
  ): Promise<GroupData | undefined> {
    if (filterData ) {
      return this.repository.GetGroupData(accessToken, showFilter, filterData)
    }
    return this.repository.GetGroupData(accessToken, showFilter)
  }
}
