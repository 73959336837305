import { DashboardRepository } from '../domain/DashboardRepository'
import { GroupDashboard, PharmacyDashboard, UserData } from '../domain/Dashboard'

export class GetGroupDashboard {
  private readonly repository: DashboardRepository

  constructor(repository: DashboardRepository) {
    this.repository = repository
  }

  public async execute(accessToken: string): Promise<GroupDashboard | undefined> {
    return this.repository.GetGroupDashboard(accessToken)
  }
}
