import { ProgramRepository } from '../domain/ProgramRepository'
import { ProgramDetails, toDomainGetByCourseId } from '../domain/Program'
import { environment } from '../../../../environments/environment'

export class ProgramRepositoryRest extends ProgramRepository {
  private readonly HOST = environment.host
  private readonly PCEK = environment.pcek

  public async getByCourseId(courseId: number): Promise<ProgramDetails[] | undefined> {
    const response = await fetch(`${this.HOST}/courses/getProgram`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK
      },
      body: JSON.stringify({ cid: courseId })
    })

    if(response.ok === false) {
      return
    }
    
    return toDomainGetByCourseId(await response.json());
  }
}
