import { Injectable } from '@angular/core';
import { gsap } from 'gsap'
import { ExpoScaleEase } from "gsap/EasePack";
import { TextPlugin } from 'gsap/TextPlugin';


@Injectable({
    providedIn: 'root'
})
export class AnimationService {

    public timeline: any;

    constructor() {
        gsap.registerPlugin(ExpoScaleEase);
        gsap.registerPlugin(TextPlugin);
    }

    public createTimeline(): void {
        this.timeline = gsap.timeline();
    }

    public fadeInUp(
        selector: GSAPTweenTarget, opt: GSAPTweenVars = {}, pos: string = "-=0.2"
    ): GSAPTween {
        let gsapOptions = {
            y: 50,
            opacity: 0,
            duration: 1,
            display: "inherit",
            ease: "power4.out",
            ...opt
        }
        return this.runFromMethod(selector, gsapOptions, pos);
    }

    public fadeInDown(
        selector: GSAPTweenTarget, opt: GSAPTweenVars = {}, pos: string = "-=0.2"
    ): GSAPTween {
        let gsapOptions = {
            y: "-100%",
            opacity: 0,
            duration: 1,
            display: "inherit",
            ease: "power4.out",
            ...opt
        }
        return this.runFromMethod(selector, gsapOptions, pos);
    }

    public fadeOutDown(
        selector: GSAPTweenTarget, opt: GSAPTweenVars = {}, pos: string = "-=0.2"
    ): GSAPTween {
        let gsapOptions = {
            y: -50,
            opacity: 0,
            display: "none",
            duration: 1,
            ease: "power4.out",
            ...opt
        }
        return this.runToMethod(selector, gsapOptions, pos);
    }

    public fadeOutLeft(
        selector: GSAPTweenTarget, opt: GSAPTweenVars = {}, pos: string = "-=0.2"
    ): GSAPTween {
        
        let gsapOptions = {
            x: -50,
            opacity: 0,
            display: "none",
            duration: 1,
            ease: "power4.out",
            ...opt
        }
        return this.runToMethod(selector, gsapOptions, pos);
    }

    public runFadeOutLeft(selector: GSAPTweenTarget, opt: GSAPTweenVars = {}){
        let gsapOptions = {
            x: -50,
            opacity: 0,
            display: "none",
            duration: 1,
            ease: "power4.out",
            ...opt
        }
        return gsap.to(selector, gsapOptions);
    }

    public zooming(
        selector: GSAPTweenTarget, opt: GSAPTweenVars = {}, pos: string = "-=0.2"
    ): GSAPTween {
        let gsapOptions = {
            transformOrigin: "center",
            scale: 0.2,
            opacity: 0,
            duration: 1,
            ease: "expo.out",
            ...opt
        }
        return this.runFromMethod(selector, gsapOptions, pos);
    }

    public leftToRight(
        selector: GSAPTweenTarget, opt: GSAPTweenVars = {}, pos: string = "-=0.2"
    ): GSAPTween {
        let gsapOptions = {
            x: "-100%",
            opacity: 0,
            duration: 1,
            ease: "power4.out",
            ...opt
        }
        return this.runFromMethod(selector, gsapOptions, pos);
    }

    private typeWriterTimeline: GSAPTimeline | null = null; // Variable for storing the timeline of the animation

    public typeWriter(selector: GSAPTweenTarget, texts: string[], pos: string = "-=0.2") {
        let currentIndex = 0;

        const typeWriterCallback = () => {
            currentIndex = (currentIndex + 1) % texts.length; // Go back to the beginning of the array when reaching the end
            if (this.typeWriterTimeline) {
                this.typeWriterTimeline.to(selector, { text: texts[currentIndex], duration: 4 });
                this.typeWriterTimeline.to(selector, { text: '', duration: 2, delay: 4, onComplete: typeWriterCallback });
            }
        };

        if (this.typeWriterTimeline) {
            this.typeWriterTimeline.kill(); // Stop the existing animation if there is one in progress
        } else {
            this.typeWriterTimeline = gsap.timeline(); // Create a new timeline if there is none
        }

        if (this.typeWriterTimeline) {
            this.typeWriterTimeline.to(selector, { text: texts[currentIndex], duration: 4 });
            this.typeWriterTimeline.to(selector, { text: '', duration: 2, delay: 4, onComplete: typeWriterCallback });
        }
    }

    public stopTypeWriter() {
        if (this.typeWriterTimeline) {
            this.typeWriterTimeline.kill(); // Stop the animation and clear the timeline
            this.typeWriterTimeline = null; // Reset the timeline variable to null
        }
    }

    private runToMethod(
        selector: GSAPTweenTarget, options: GSAPTweenVars, pos: string
    ): GSAPTween {
        if (this.timeline) return this.timeline.to(selector, options, pos);
        return gsap.to(selector, options);
    }

    private runFromMethod(
        selector: GSAPTweenTarget, options: GSAPTweenVars, pos: string
    ): GSAPTween {
        if (this.timeline) return this.timeline.from(selector, options, pos);
        return gsap.from(selector, options);
    }
}

