import { ProgramRepository } from '../domain/ProgramRepository'
import { ProgramDetails } from '../domain/Program'

export class GetProgramByCourseId {
  private readonly repository: ProgramRepository

  constructor(repository: ProgramRepository) {
    this.repository = repository
  }

  public async execute(courseId: number): Promise<ProgramDetails[] | undefined> {
    return this.repository.getByCourseId(courseId)
  }
}
