import { Itinerary } from "../domain/Itinerary"
import { ItineraryRepository } from "../domain/ItineraryRepository"

export class GetItineraryTemplates {
  private readonly repository: ItineraryRepository

  constructor(repository: ItineraryRepository) {
    this.repository = repository
  }

  public async execute(token: string): Promise<Itinerary[] | undefined> {
      return this.repository.getItineraryTemplates(token)
  }
}
