import { DashboardRepository } from '../domain/DashboardRepository'
import { CertificateData, CourseProgress } from '../domain/Dashboard'

export class GetCertificateData {
  private readonly repository: DashboardRepository

  constructor(repository: DashboardRepository) {
    this.repository = repository
  }

  public async execute(courseId: string, accessToken: string): Promise<CertificateData | undefined> {
    return this.repository.GetCertificateData(courseId, accessToken)
  }
}