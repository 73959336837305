import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  private activate$: Subject<any> = new Subject();

  constructor() { }

  dispatchModal(activate:any){
    this.activate$.next(activate);
  }

  getModal$(): Observable<any>{
    return this.activate$.asObservable();
  }
}
