export interface Plan {
  licenseId: number
  type: number
  periodicity: number
  price: number
}

export interface ResponsePlanGetAll {
  data: {
    getPlans: {
      lis: {
        lid: number
        lit: number
        per: number
        pr: number | string
      }[]
    }
    result: 'ok' | 'ko'
  }
  ok: boolean
}

export const toDomainGetAll = function(response: ResponsePlanGetAll): Plan[] {
  return response.data.getPlans.lis.map((item) => ({
    licenseId: item.lid,
    type: item.lit,
    periodicity: item.per,
    price: typeof item.pr === 'string' ? parseFloat(item.pr) : item.pr
  }))
}
