import { CustomError } from "../../share/domain/CustomError"

export enum SubcriptionErrorList {
  EXPIRED_SESSION = 'Expired session',
  SUBSCRIPTIONS_NOT_FOUND = 'Subscriptions not found',
  SUBSCRIPTION_ALREADY_EXPIRED= 'Subscriptions already expired'
}

export class SubcriptionError extends Error implements CustomError {
  public override readonly name: string = 'course.error'

  public static expiredSession (): SubcriptionError {
    return new this(SubcriptionErrorList.EXPIRED_SESSION)
  }

  public static susbcriptionsNotFound (): SubcriptionError {
    return new this(SubcriptionErrorList.SUBSCRIPTIONS_NOT_FOUND)
  }
  
  public static subAlreadyExpired (): SubcriptionError {
    return new this(SubcriptionErrorList.SUBSCRIPTION_ALREADY_EXPIRED)
  }
  
}
