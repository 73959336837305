import { DashboardRepository } from '../domain/DashboardRepository'
import { PharmacyDashboard, UserData } from '../domain/Dashboard'

export class GetPharmacyDashboard {
  private readonly repository: DashboardRepository

  constructor(repository: DashboardRepository) {
    this.repository = repository
  }

  public async execute(accessToken: string, pharmacyId?: string): Promise<PharmacyDashboard | undefined> {
    if(pharmacyId){
      return this.repository.GetPharmacyDashboard(accessToken, pharmacyId)
    }
    return this.repository.GetPharmacyDashboard(accessToken)
  }
}
