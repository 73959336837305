export interface SignUp {
  id: number
  token: string
}

export const PHARMACY_HOLDER_NO_GROUP = 1;
export const PHARMACY_HOLDER_GROUP = 3;
export const STUDENT = 2;
export const GROUP_RESPONSIBLE = 4;
export const SUPER_ADMIN = 5;

export interface Account extends SignUp {
  nick: string
  email: string
  mustChangePwd: boolean
  type: number
  typeLms: number
  name: string,
  branchSelected: number,
  branches: Branches[],
  customAtributes: any,
  demo: boolean
}
export interface AutoLoginAccount extends Account {
  policies:boolean
}

export interface Logout {
  logout: boolean,
}

export interface lmsLinks {
  branches: 
    {
      id: string,
      nm: string,
      url: string
    }[]
}

export interface RespondeAccountSignUp {
  data: {
    signUp: {
      person_id: number,
      session_token: string
    },
    result: 'ok' | 'ko'
  },
  ok: boolean
}

export interface Branches {
  brid: number,
  brnm: string,
}

export interface RespondeAccountLogin {
  data: {
    login: {
      email: string,
      fnm: string
      must_change_pwd: boolean,
      nick: string,
      person_id: number,
      session_token: string,
      typ: number,
      typ_lms: number,
      br:number,
      brs:Branches[];
      cas: any,
      dm: boolean
    },
    result: 'ok' | 'ko'
    ok: boolean
  }
}
export interface RespondeAccountAutoLogin {
  data: {
    login: {
      email: string,
      policies: boolean,
      fnm: string
      must_change_pwd: boolean,
      nick: string,
      person_id: number,
      session_token: string,
      typ: number,
      typ_lms: number,
      br:number,
      brs:Branches[],
      cas: any,
      dm: boolean
    },
    result: 'ok' | 'ko'
    ok: boolean
  }
}
export interface RespondeForgotPassword {
  data: {
    password_forgot: {
      e_email: string
    },
    result: 'ok' | 'ko'
  },
  ok: boolean
}
export interface RespondeAccountLogout {
  data: {
    logout: boolean,
    result: 'ok' | 'ko'
  }
  ok: boolean
}

export interface RespondeSubscribeNewsLetter {
  data: {
    addToList: string,
    result: 'ok' | 'ko'
  }
  ok: boolean
}

export interface RespondeUsrSetAttributes {
  data: {
    result: 'ok' | 'ko',
    usrSetAttributes: {
      cas: 'ok' | 'no',
      email: 'ok' | 'no',
      policies: 'ok' | 'no',
      nick: 'ok' | 'no',
      pwd: 'ok' | 'no',
      typ: 'ok' | 'no'
    }
  },
  ok: boolean
}

export interface RespondeGetLmsLoginUrl {
  data: {
    lmsLoginUrl: {
      branches: [
        {
          id: string,
          nm: string,
          url: string
        }
      ],
      result: 'ok' | 'ko'
    },
    ok: boolean
  }
}


export interface RespondeAccountCheckBranchName {
  data: {
    checkBranchName: string,
    result: 'ok' | 'ko'
  }
  ok: boolean
}

export const toDomainSignUp = function (response: RespondeAccountSignUp): SignUp {
  return {
    id: response.data.signUp.person_id,
    token: response.data.signUp.session_token,
  }
}

export const toDomainLogin = function (response: RespondeAccountLogin): Account {
  return {
    id: response.data.login.person_id,
    nick: response.data.login.nick,
    email: response.data.login.email,
    token: response.data.login.session_token,
    mustChangePwd: response.data.login.must_change_pwd,
    type: response.data.login.typ,
    typeLms: response.data.login.typ_lms,
    name: response.data.login.fnm,
    branchSelected: response.data.login.br,
    branches: response.data.login.brs,
    customAtributes: response.data.login.cas,
    demo: response.data.login.dm
  }
}

export const toDomainAutoLogin = function (response: RespondeAccountAutoLogin): AutoLoginAccount {
  return {
    id: response.data.login.person_id,
    policies: response.data.login.policies,
    nick: response.data.login.nick,
    email: response.data.login.email,
    token: response.data.login.session_token,
    mustChangePwd: response.data.login.must_change_pwd,
    type: response.data.login.typ,
    typeLms: response.data.login.typ_lms,
    name: response.data.login.fnm,
    branchSelected: response.data.login.br,
    branches: response.data.login.brs,
    customAtributes: response.data.login.cas,
    demo: response.data.login.dm
  }
}

export const toDomainLogout = function (response: RespondeAccountLogout): Logout {
  return {
    logout: response.data.logout
  }
}

export const toDomainCheckBranchName = function (response: RespondeAccountCheckBranchName): boolean {
  return response.data.checkBranchName == 'ok';
}


export const toDomainSubscribeNewsLetter = function (response: RespondeSubscribeNewsLetter): boolean {
  return response.data.addToList == 'ok';
}

export const toDomainGetLmsLoginUrl = function (response: RespondeGetLmsLoginUrl): lmsLinks {
  let links: lmsLinks = {branches: []};
  response.data.lmsLoginUrl.branches.forEach((branch)=>{
    links.branches.push({
      id: branch.id,
      nm: branch.nm,
      url: branch.url
    })
  })
  return links;
}

export const toDomainForgotPassword = function (response: RespondeForgotPassword): boolean {
  return response.data.result == 'ok';
}

export const toDomainChangePassword = function (response: RespondeUsrSetAttributes): boolean {
  return response.data.usrSetAttributes.pwd == 'ok';
}

export const toDomainAcceptPolicies = function (response: RespondeUsrSetAttributes): boolean {
  return response.data.usrSetAttributes.policies == 'ok';
}

export const toDomainChangeBranch = function (response: RespondeUsrSetAttributes): boolean {
  return response.data.usrSetAttributes.cas == 'ok';
}
