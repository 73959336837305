import { createReducer, on } from '@ngrx/store'
import { Cart, LicenseId } from '../cross/cart/domain/Cart'
import { addLicenseAction, removeLicenseAction, updateCartAction,emptyCartAction } from './cart.actions'

export const initialLicenseState: ReadonlyArray<LicenseId> = []
 
export const licenseReducer = createReducer(
  initialLicenseState,
  on(emptyCartAction, (actualState) => actualState = []),
  on(removeLicenseAction, (actualState, { licenseId }) => actualState.filter((item) => item !== licenseId)),
  on(addLicenseAction, (actualState, { licenseId }) => {
    const offerFound = actualState.find(item => (item === licenseId))
    return offerFound ? actualState : [licenseId];
  })
)

export const initialCartState: ReadonlyArray<Cart> = []

export const cartReducer = createReducer(
  initialCartState,
  on(updateCartAction, (actualState, { cart }) => {
    const cartFound = actualState.find(item => (JSON.stringify(item) === JSON.stringify(cart)))
    return cartFound ? actualState : [cart]
  })
)