export interface Certificate {
    certificateImg: string
    title: string
    description: string
    path: string
}

export interface ResponseGetCertificatePublicData {
    data: {
        getCertificatePublicData: {
            cei: string
            tit: string
            des: string
            path: string
        }
        result: 'ok' | 'ko'
    }
    ok: boolean
}

export const toDomainGetCategories = function (response: ResponseGetCertificatePublicData): Certificate {
    return {
        certificateImg: response.data.getCertificatePublicData.cei,
        title: response.data.getCertificatePublicData.tit,
        description: response.data.getCertificatePublicData.des,
        path: response.data.getCertificatePublicData.path
    }
}