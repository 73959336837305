import { AccountRepository } from '../domain/AccountRepository'

export class ChangeBranchSelected {
  private readonly repository: AccountRepository

  constructor(repository: AccountRepository) {
    this.repository = repository
  }

  public async execute(customAtributes: object, email:string, callback : any): Promise<boolean> {
    const result = await this.repository.changeBranchSelected(customAtributes, email)
    callback(result)
    return result
  }
}
