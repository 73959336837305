import { DashboardRepository } from '../domain/DashboardRepository'
import { UserData } from '../domain/Dashboard'

export class ReportHolderByUser {
  private readonly repository: DashboardRepository

  constructor(repository: DashboardRepository) {
    this.repository = repository
  }

  public async execute(accessToken: string, personId?: number): Promise<UserData | undefined> {
    if(personId){
      return this.repository.ReportHolderByUser(accessToken, personId)
    }
    return this.repository.ReportHolderByUser(accessToken)
  }
}
