import { AccountRepository } from '../domain/AccountRepository'

export class AcceptPolicies {
  private readonly repository: AccountRepository

  constructor(repository: AccountRepository) {
    this.repository = repository
  }

  public async execute(email: string, token: string): Promise<boolean> {
    const result = await this.repository.acceptPolicies(email, token)
    return result
  }
}
