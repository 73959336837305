import { ItineraryCreate } from "../domain/Itinerary"
import { ItineraryRepository } from "../domain/ItineraryRepository"

export class DuplicateItinerary {
  private readonly repository: ItineraryRepository

  constructor(repository: ItineraryRepository) {
    this.repository = repository
  }

  public async execute(token: string, id: number): Promise<'ok' | 'ko' | undefined> {
    return this.repository.duplicateItinerary(token, id);
  }
}
