import { CustomError } from "../../share/domain/CustomError"

export enum CartErrorList {
  FINISHED_CART = 'Finished cart',
  EMPTY_CART = 'Empty cart',
  EXPIRED_SESSION = 'Expired session',
  DEMO_ALREADY_USED = 'Demo already used',
  SALE_CODE_NOT_FOUND = 'Sale code not found',
  ALREADY_HAVE_SUBSCRIPTION = 'Already have an active subscription'
}

export class CartError extends Error implements CustomError {
  public override readonly name: string = 'cart.error'

  public static finishedCart (): CartError {
    return new this(CartErrorList.FINISHED_CART)
  }

  public static demoAlreadyUsed (): CartError {
    return new this(CartErrorList.DEMO_ALREADY_USED)
  }

  public static emptyCart (): CartError {
    return new this(CartErrorList.EMPTY_CART)
  }

  public static alreadySubscribe (): CartError {
    return new this(CartErrorList.ALREADY_HAVE_SUBSCRIPTION)
  }

  public static expiredSession (): CartError {
    return new this(CartErrorList.EXPIRED_SESSION)
  }

  public static saleCodeNotFound (): CartError {
    return new this(CartErrorList.SALE_CODE_NOT_FOUND)
  }
  
}
