import { NgModule } from '@angular/core';
import { FooterComponent } from './footer.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from 'src/app/pipes/translate.module';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        FooterComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule
    ],
    exports: [
        FooterComponent
    ],
})
export class FooterModule { }