import { PharmacyDashboardFilterItineraryList } from '../domain/Dashboard';
import { DashboardRepository } from '../domain/DashboardRepository'

export class GetPharmacyDashboardFilterItineraries {
  private readonly repository: DashboardRepository

  constructor(repository: DashboardRepository) {
    this.repository = repository
  }

  public async execute(accessToken: string, pharmacyId?: number): Promise<PharmacyDashboardFilterItineraryList | undefined> {
    if (pharmacyId) {
      return this.repository.GetPharmacyDashboardFilterItinerary(accessToken, pharmacyId);
    }
    return this.repository.GetPharmacyDashboardFilterItinerary(accessToken);
  }
}