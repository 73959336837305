import { CourseRepository } from '../domain/CourseRepository'
import { Course } from '../domain/Course'

export class GetCoursesByCategory {
  private readonly repository: CourseRepository

  constructor(repository: CourseRepository) {
    this.repository = repository
  }

  public async execute({ identifier, category, limit}: { identifier?: string, category: string[], limit?: number }): Promise<Course[] | undefined> {
    if(limit){
      return this.repository.getByCategory({ identifier, category, limit })
    }
    return this.repository.getByCategory({ identifier, category })
  }
}
