import { CourseRepository } from '../domain/CourseRepository'
import { Category, Content, Course, CourseDetails, Courses, toDomainGetAll, toDomainGetById, toDomainGetCategories, toDomainGetProgram } from '../domain/Course'
import { environment } from '../../../../environments/environment'
import { RequestError } from '../../share/domain/RequestError'
import { CourseError } from '../domain/CourseError'

export class CourseRepositoryRest extends CourseRepository {
  private readonly HOST = environment.host
  // private readonly HOST = 'https://d3v-web-api.liceodefarmacia.com'
  private readonly PCEK = environment.pcek
  // private readonly PCEK = 'ceroyuno'

  public async getById(path: string): Promise<CourseDetails | undefined> {
    const response = await fetch(`${this.HOST}/courses/getCourse`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK
      },
      body: JSON.stringify({ path: path })
    })

    let result;
    try {
      result = await response.json();
    } catch (error) {
      throw RequestError.generic(this.constructor.name, {err: "Unexpected end of JSON input", data: response})
    }

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    // course not avaible
    if (result.data?.result === 'cid_ms'
      || result.data?.result === 'pid_int'
      || result.data?.result === 'p_nf'
      || result.data?.result === 'cid_int'
      || result.data?.result === 'co_nf'
    ) {
      throw CourseError.courseNotFound()
    }

    // if (response.ok === false) {
    //   return
    // }

    return toDomainGetById(result);
  }

  public async getProgram(courseId: number): Promise<Content[] | undefined> {
    const response = await fetch(`${this.HOST}/courses/getProgram`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK
      },
      body: JSON.stringify({ cid: courseId })
    })

    let result;
    try {
      result = await response.json();
    } catch (error) {
      throw RequestError.generic(this.constructor.name, {err: "Unexpected end of JSON input", data: response})
    }

    // Uncaught generic error
    if (response.ok === false || result.ok === false || !result.data) {
      throw RequestError.generic(this.constructor.name, result)
    }

    // course not avaible
    if (result.data?.result !== 'ok') {
      if (result.data?.result === 'cid_ms'
        || result.data?.result === 'cid_int'
        || result.data?.result === 'co_nf'
      ) {
        throw CourseError.programNotFound()
      }
      throw RequestError.generic(this.constructor.name, result)
    }
    return toDomainGetProgram(result);
  }

  public async getAll(limit?: number): Promise<Courses | undefined> {
    let body = {};
    if (limit) {
      body = { "rbp": limit, "od": 1 }
    }
    const response = await fetch(`${this.HOST}/courses/getCourses`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK
      },
      body: JSON.stringify(body)
    })

    let result;
    try {
      result = await response.json();
    } catch (error) {
      throw RequestError.generic(this.constructor.name, {err: "Unexpected end of JSON input", data: response})
    }

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    // courses not found
    if (result.data?.result === 'co_nf'
      || result.data?.result === 'pid_int'
      || result.data?.result === 'p_nf'
      || result.data?.result === 'max_int'
      || result.data?.result === 'rbp_int'
      || result.data?.result === 'pn_int'
      || result.data?.result === 'od_int'
      || result.data?.result === 'ct_nf'
    ) {
      throw CourseError.coursesNotFound()
    }

    // if (response.ok === false) {
    //   return
    // }

    return toDomainGetAll(result);
  }

  public async getLastCourses(limit?: number): Promise<Courses | undefined> {
    let body = {};
    if (limit) {
      body = {
        "rbp": limit,
        "od": 0
      }
    }
    const response = await fetch(`${this.HOST}/courses/getCourses`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK
      },
      body: JSON.stringify(body)
    })

    let result;
    try {
      result = await response.json();
    } catch (error) {
      throw RequestError.generic(this.constructor.name, {err: "Unexpected end of JSON input", data: response})
    }

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    // courses not found
    if (result.data?.result === 'co_nf'
      || result.data?.result === 'pid_int'
      || result.data?.result === 'p_nf'
      || result.data?.result === 'max_int'
      || result.data?.result === 'rbp_int'
      || result.data?.result === 'pn_int'
      || result.data?.result === 'od_int'
      || result.data?.result === 'ct_nf'
    ) {
      throw CourseError.coursesNotFound()
    }

    // if (response.ok === false) {
    //   return
    // }

    return toDomainGetAll(result);
  }

  public async getCategories(): Promise<Category[] | undefined> {
    const response = await fetch(`${this.HOST}/courses/getCategories`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK
      },
      body: JSON.stringify({})
    })

    let result;
    try {
      result = await response.json();
    } catch (error) {
      throw RequestError.generic(this.constructor.name, {err: "Unexpected end of JSON input", data: response})
    }

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    // categories not found
    if (result.data?.result === 'ct_nf') {
      throw CourseError.categoriesNotFound()
    }

    return toDomainGetCategories(result);
  }

  public async getByCategory({ identifier, category, limit }: { identifier?: string, category: string[], limit?: number }): Promise<Course[] | undefined> {
    let body: { pid?: string, cl: string[], rbp?: number } = {
      ...(identifier && { pid: identifier }),
      cl: category,
      ...(limit && { rbp: limit })
    };
    const response = await fetch(`${this.HOST}/courses/getCourses`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK
      },
      body: JSON.stringify(body)
    })
    let result;
    try {
      result = await response.json();
    } catch (error) {
      throw RequestError.generic(this.constructor.name, {err: "Unexpected end of JSON input", data: response})
    }

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    // courses not found
    if (result.data?.result === 'co_nf'
      || result.data?.result === 'pid_int'
      || result.data?.result === 'p_nf'
      || result.data?.result === 'max_int'
      || result.data?.result === 'rbp_int'
      || result.data?.result === 'pn_int'
      || result.data?.result === 'od_int'
      || result.data?.result === 'ct_nf'
    ) {
      throw CourseError.coursesNotFound()
    }

    return toDomainGetAll(result).courses;
  }

  public async getFilteredCourses({ identifier, category, search, limit, order, target }: {
    identifier?: string,
    category?: string[],
    search?: string,
    limit?: number,
    order?: number,
    target?: number[]
  }): Promise<Courses | undefined> {

    let body: { pid?: string, cl?: string[], qh?: string, rbp?: number } = {
      ...(identifier && { pid: identifier }),
      ...(category && { cl: category }),
      ...(search && { qh: search }),
      ...(limit && { rbp: limit }),
      ...(order && { od: order }),
      ...(target && { tg: target })
    };
    const response = await fetch(`${this.HOST}/courses/getCourses`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK
      },
      body: JSON.stringify(body)
    })

    let result;
    try {
      result = await response.json();
    } catch (error) {
      throw RequestError.generic(this.constructor.name, {err: "Unexpected end of JSON input", data: response})
    }

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    // courses not found
    if (result.data?.result === 'co_nf'
      || result.data?.result === 'pid_int'
      || result.data?.result === 'p_nf'
      || result.data?.result === 'max_int'
      || result.data?.result === 'rbp_int'
      || result.data?.result === 'pn_int'
      || result.data?.result === 'od_int'
      || result.data?.result === 'ct_nf'
    ) {
      throw CourseError.coursesNotFound()
    }

    // if (response.ok === false) {
    //   return
    // }

    return toDomainGetAll(result);
  }

  // public async getByKeyWord(search: string): Promise<Course[] | undefined> {
  //   const response = await fetch(`${this.HOST}/courses/getCourses`, {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //       'pcek': this.PCEK
  //     },
  //     body: JSON.stringify({qh: search})
  //   })    

  //   if(response.ok === false) {
  //     return
  //   }

  //   return toDomainGetAll(await response.json()).courses;
  // }

}
