import { CartRepository } from '../domain/CartRepository'
import { PayConfirmationData } from '../domain/Cart'

export class PayConfirmation {
  private readonly repository: CartRepository

  constructor(repository: CartRepository) {
    this.repository = repository
  }

  public async execute({userToken, saleCode}: { userToken: string, saleCode: string}): Promise<PayConfirmationData> {
    return this.repository.payConfirmation({userToken, saleCode})
  }
}
