import { Component, ElementRef, Input, OnInit, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]

})
export class InputComponent implements OnInit {

  @Input() public type: string = 'text'
  @Input() public placeholder: string = ''
  @Input() public hasError: boolean = false
  @Input() public messageError: string = ''

  @ViewChild('input') input!: ElementRef<HTMLInputElement>;

  public value: string = ''
  onChange = (_: any) => { }
  onTouch = () => { }

  viewPassword: boolean = false;
  privateType: string = 'text';
  constructor() { }

  ngOnInit() {
    this.privateType = this.type;
  }

  onInput(event: Event) {
    const { value } = event.target as HTMLInputElement;
    this.value = value;
    this.onTouch();
    this.onChange(this.value);
  }

  writeValue(value: string): void {
    this.value = value || '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  focus() {
      this.input.nativeElement.focus();
  }

  togglePassword() {
    this.viewPassword = !this.viewPassword;
    this.privateType = this.viewPassword ? 'text' : 'password';
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void { }

}
