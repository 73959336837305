import { AccountRepository } from '../domain/AccountRepository'
import { Account, Logout, SignUp, toDomainChangePassword, toDomainChangeBranch, toDomainGetLmsLoginUrl, toDomainForgotPassword, toDomainCheckBranchName, toDomainLogin, toDomainLogout, toDomainSignUp, toDomainSubscribeNewsLetter, lmsLinks, AutoLoginAccount, toDomainAutoLogin, toDomainAcceptPolicies } from '../domain/Account'
import { environment } from '../../../../environments/environment'
import { AccountError } from '../domain/AccountError'
import { RequestError } from '../../share/domain/RequestError'
import { HttpClient } from '@angular/common/http'
import { tap } from 'rxjs'

export class AccountRepositoryRest implements AccountRepository {
  private readonly HOST = environment.host
  private readonly APP = environment.app
  private readonly PCEK = environment.pcek


  constructor(
  ) {
  }

  public async getLmsLoginUrl(token: string): Promise<lmsLinks> {
    const response = await fetch(`${this.HOST}/account/lmsLoginUrl`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': token
      },
      body: JSON.stringify({})
    })

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    return toDomainGetLmsLoginUrl(result)
  }

  public async signUp(name: string, lastName: string,
    email: string, password: string,
    nick: string, news_letter: boolean,
    type?: 1 | 2 | 3,
    pharmacy_name?: string, branch_name?: string
  ): Promise<SignUp | undefined> {
    const response = await fetch(`${this.HOST}/account/signUp`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK
      },
      body: JSON.stringify({
        f_name: name,
        l_name: lastName,
        email: email,
        pwd: this.cleanSpaces(password),
        nick: nick,
        wn: news_letter,
        ...(pharmacy_name && { pynm: pharmacy_name }),
        ...(branch_name && { bnm: branch_name }),
        typ: type,
        app: this.APP
      })
    })

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    // email invalid field
    if (result.data?.result === 'email-inv') {
      throw AccountError.emailInvalidField()
    }

    // pass invalid field
    if (result.data?.result === 'pwd-inv') {
      throw AccountError.passwordInvalidField()
    }

    // email used
    if (result.data?.result === 'email-au' ||
      result.data?.result === 'email-lms-au') {
      throw AccountError.emailUsed()
    }

    // pharma name used
    if (result.data?.result === 'pynm-au' ||
      result.data?.result === 'pynm-lms-au') {
      throw AccountError.pharmaUsed()
    }

    // username used
    if (result.data?.result === 'nick-au' ||
      result.data?.result === 'nick-lms-au') {
      throw AccountError.nickUsed()
    }

    return toDomainSignUp(result);
  }

  public async login(identifier: string, password: string): Promise<Account> {
    const response = await fetch(`${this.HOST}/account/login`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK
      },
      body: JSON.stringify({ pid: identifier, pwd: this.cleanSpaces(password), app: this.APP })
      // body: JSON.stringify({ pid: identifier, pwd: password, app: this.APP })
    })

    const result = await response.json()
    // Uncaught generic error
    if (response.ok === false || result.ok === false || !result.data) {
      throw RequestError.generic(this.constructor.name, result)
    }

    if (result.data?.result !== 'ok') {
      // Email not found
      if (result.data?.result === 'eof') {
        throw AccountError.emailNotFound()
      }

      // Password incorrect
      if (result.data?.result === 'bpw') {
        throw AccountError.passwordIncorrect()
      }

      throw RequestError.generic(this.constructor.name, result)
    }


    return toDomainLogin(result)
  }

  public async tokenLogin(accessToken: string): Promise<Account> {
    const response = await fetch(`${this.HOST}/account/login`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK
      },
      body: JSON.stringify({ sc: accessToken, app: this.APP })
      // body: JSON.stringify({ pid: identifier, pwd: password, app: this.APP })
    })

    const result = await response.json()
    // Uncaught generic error
    if (response.ok === false || result.ok === false || !result.data) {
      throw RequestError.generic(this.constructor.name, result)
    }

    if (result.data?.result !== 'ok') {
      // Email not found
      if (result.data?.result === 'eof') {
        throw AccountError.emailNotFound()
      }

      // Password incorrect
      if (result.data?.result === 'bpw') {
        throw AccountError.passwordIncorrect()
      }

      throw RequestError.generic(this.constructor.name, result)
    }


    return toDomainLogin(result)
  }

  public async autoLogin(token: string): Promise<AutoLoginAccount> {
    const response = await fetch(`${this.HOST}/account/autoLogin`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK
      },
      body: JSON.stringify({app: this.APP, flc: token })
    })

    const result = await response.json()

    // Uncaught generic error
    if(response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    // invalid flash link
    if(result.data?.result === 'fl_inv') {
      throw AccountError.invalidFlashLink()
    }
        
    return toDomainAutoLogin(result)
  }

  public async forgotPassword(email: string): Promise<boolean> {
    const response = await fetch(`${this.HOST}/account/passwordForgot`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK
      },
      body: JSON.stringify({ pid: email })
    })

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    // Email not found
    if (result.data?.result === 'eof') {
      throw AccountError.emailNotFound()
    }

    return toDomainForgotPassword(result)
  }


  public async changePassword(token: string, email: string, password: string): Promise<boolean> {
    const response = await fetch(`${this.HOST}/account/usrSetAttributes`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': token
      },
      body: JSON.stringify({ pid: email, pwd: this.cleanSpaces(password) })
    })

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    return toDomainChangePassword(result)
  }

  public async updatePassword(token: string, email: string, password: string, customAtributes: any ): Promise<boolean> {    
    const response = await fetch(`${this.HOST}/account/usrSetAttributes`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': token
      },
      body: JSON.stringify({ pid: email, pwd: this.cleanSpaces(password), cas:customAtributes})
    })

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    return toDomainChangePassword(result)
  }
  

  public async acceptPolicies( email: string, token: string): Promise<boolean> {
    const response = await fetch(`${this.HOST}/account/usrSetAttributes`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': token
      },
      body: JSON.stringify({pid: email, policies: false})
    })

    const result = await response.json()

    // Uncaught generic error
    if(response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }
  
    return toDomainAcceptPolicies(result)
  }

  public async logout(identifier: number, token: string): Promise<Logout | undefined> {
    const response = await fetch(`${this.HOST}/account/logout`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK
      },
      body: JSON.stringify({ pid: identifier, ses: token })
    })

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    return toDomainLogout(await result)
  }

  public async subscribeNewsLetter(name: string, email: string, list_id: number[], url: string, user_type?: number): Promise<boolean> {
    const response = await fetch(`${this.HOST}/newsletter/addToList`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK
      },
      body: JSON.stringify({
        app: this.APP,
        email: email,
        f_name: name,
        lid: list_id,
        url: url,
        ...(user_type && { typ: user_type })
      })
    })

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    return toDomainSubscribeNewsLetter(result)
  }

  public async checkBranchName(branch_name: string): Promise<boolean> {

    const response = await fetch(`${this.HOST}/lms/checkBranchName`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK
      },
      body: JSON.stringify({ bnm: branch_name })
    })

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    return toDomainCheckBranchName(result)
  }

  private cleanSpaces(str: string): string {
    return str.replace(/\s/g, '')
  }

  public async changeBranchSelected(customAtributes: any, email:string): Promise<boolean> {
    const response = await fetch(`${this.HOST}/account/usrSetAttributes`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK
      },
      body: JSON.stringify({ pid: email, cas:customAtributes})
    })

    const result = await response.json()

    

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    return toDomainChangeBranch(result)
  }
}
