import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {

  private isBrowser: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: object) { 
    this.isBrowser = isPlatformBrowser(this.platformId)
  }

  public getDocument(callback: any): void{
    if(this.isBrowser) callback(document);
  }

  public getWindow(callback: any): void{
    if(this.isBrowser) callback(window);
  }

  public onlyBrowser(callback: any): void{
    if(this.isBrowser) callback();
  }

  public isMobile(): boolean {
    if(this.isBrowser) {
      return window.innerWidth <= 768;
    }else{
      return false;
    }
  }

  public isTable(): boolean{
    if(this.isBrowser) {
      return window.innerWidth < 1200;
    }else{
      return false;
    }
  }
}
