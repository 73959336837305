import { AccountRepository } from '../domain/AccountRepository'
import { Account } from '../domain/Account'
import { EventAction } from '../../share/domain/EventAction'
import { EventBus } from '../../share/domain/EventBus'

export class AccountLogin {
  private readonly repository: AccountRepository
  private readonly eventBus: EventBus

  constructor(repository: AccountRepository, eventBus: EventBus) {
    this.repository = repository
    this.eventBus = eventBus
  }

  public async execute(email: string, password: string, saleCode?: string): Promise<Account> {
    const result = await this.repository.login(email, password)
    
    if (saleCode) {
      this.eventBus.notify(EventAction.THE_USER_HAS_LOGGED_IN, {
        userToken: result.token,
        saleCode
      })
    }

    return result
  }
}
