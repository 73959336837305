import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorModalService } from 'src/app/services/error-modal.service';
import { TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {

  errorData!: ErrorModalData;
  adviceData!: AdviceModalData;

  modalVisible: boolean = false;
  modalAdviceVisible: boolean = false;


  constructor(
    private errorModalService: ErrorModalService,
    private translateService: TranslateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.errorModalService.getError$().subscribe(
      errorData => this.showModal(errorData)
    )
  }

  showModal(modalData: ModalData){
    switch(modalData.type){
      case 'advice':
        this.adviceData = <AdviceModalData>modalData;
        this.modalAdviceVisible = true;
        break;
        default:
          this.errorData = <ErrorModalData>modalData;
          this.modalVisible = true;
        break;
    }
  }

  // ----- error modal actions -----
  closeModal(){
    this.modalVisible = false;
    this.router.navigate(['/']);
  }
  // ----- * -----

  // ----- advice modal actions -----
  closeAdviceModal(){
    this.modalVisible = false;
    this.router.navigate(['/']);
  }
  // ----- * -----

}

export interface ModalData{
  type: 'advice' | 'error';
  title:string;
  msg: string;
}

export interface AdviceModalData extends ModalData{
  type: 'advice';
  btnText: string;
}

export interface ErrorModalData extends ModalData{
  type: 'error';
  // title:string;
  subTitle: string;
  // msg: string;
  errorCode: string;
  description: string;
  info: string;
  btnText: string;
}
