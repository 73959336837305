import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoggedService {

  public log_update$: Subject<boolean> = new Subject()

  constructor() { 
  }

  login(){
    this.log_update$.next(true);
  }
  logOut(){
    this.log_update$.next(false);
  }
}
