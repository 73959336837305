import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthGuardService } from '../services/auth-guard.service';

@Injectable({
  providedIn: 'root'
})
export class HasPlansGuard  {
  constructor(
    private authGuardService: AuthGuardService,
    private router: Router
  ){

  }

  canActivate(): boolean {
    this.authGuardService.updateStatus().then(()=>{
      if(!(this.authGuardService.isLogin() && this.authGuardService.hasActivePlan())){
        this.router.navigate(['/']);
      }
      return this.authGuardService.isLogin() && this.authGuardService.hasActivePlan()
    })
    return false;
  }

  resolve(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.authGuardService.updateStatus().then(()=>{
      if(!(this.authGuardService.isLogin() && this.authGuardService.hasActivePlan())){
        this.router.navigate(['/']);
      }
      return this.authGuardService.isLogin() && this.authGuardService.hasActivePlan()
    })
  }
  
}
