import { createSelector, SelectorFactoryConfig } from "@ngrx/store/src/selector";

export interface UserData {
    level: string,
    lastBadge: string,
    totalAchievements: number,
    totalKnowledge: string,
    totalCredits: string,
    totalTime: string,
    totalCertificates: number,
    totalCoursesAsigned: number,
    totalCoursesCompleted: number,
    pharmacyName: string,
    groupName: string,
    firstName: string,
    branches: Branch[],
    sponsors: Sponsor[]
}

export interface Branch {
    id: string,
    nm: string
}

export interface CourseProgress {
    id: number;
    talentId: string;
    name: string;
    categoryName: string;
    percentage: string;
    timeSpent: string;
    state: 'completado' | 'incompleto' | 'sin empezar';
    courseLink: string;
    availabilityDate: string;
    description: string,
    contenTypes: {
        typeId: number,
        quantity: number,
        contenName: string
    }[]
    isBlocked: boolean;
    isAvaible: boolean;
    hasEnrollment: boolean;
    certificate: boolean;
    courseDates: string;
    image: string;
    tags: string;
    authorCourse: number;
    disable: boolean;
}

export interface Itinerary {
    name: string;
    isLocked: boolean;
    id: number;
    description: string;
    isExpert: boolean;
    courseList: {
        id: number,
        conten_types: {
            typeId: number,
            quantity: number,
            contenName: string
        }[],
        //local params
        name: string,
        category: string,
        status: 'completado' | 'incompleto' | 'sin empezar',
        time: string,
        percentage: string,
        courseLink: string,
        hasEnrollment: boolean;
        talentId: string,
        availabilityDate: string,
        isBlocked: boolean,
        isAvaible: boolean
    }[];
    sponsors: Sponsor[];
    //local params
    timeSpent: string;
    percentage: number;
}

export interface Sponsor {
    img: string,
    order: number,
}

export interface ResponseReportHolderByUser {
    data: {
        reportHoldersByUser: {
            ac: number,//total courses asigned
            bc: {
                id: string,
                nm: string
            }[],
            sps: {
                img: string,
                od: number,
            }[] | null,
            pnm: string,
            gnm: string,
            bl: string,
            bn: string,//last achievement name
            bp: number,//total achievement
            fn: string,
            id: string,
            ln: string,
            lp: string,
            lv: string,//level
            pt: string, //knoledge? 
            ut: string,
            tt: string,
            cs: number
            //créditos?¿?¿?¿?
        },
        result: 'ok' | 'ko'
    },
    ok: boolean
}

export interface ResponseGetUrlFromCourse {
    data: {
        getUrlFromCourse: string,
        result: 'ok' | 'ko'
    },
    ok: boolean
}

export interface ResponseGetCourses {
    data: {
        getTheCoursesByUser: {
            cp: string,
            ico: string,
            ict: string,
            nc: string,
            no: string,
            des: string,
            tgs: string,
            cnt: {
                cntt: number,
                qt: number,
                cntn: string
            }[]
            tl: string,
            idb: number,
            aut: string,
            en: boolean,
            cr: boolean,
            cd: string,
            img: string,
            atr: number
        }[],
        result: 'ok' | 'ko'
    },
    ok: boolean
}

export interface ResponseGetItineraries {
    data: {
        getItinerariesByUser: {
            nm: string,// itinerary name
            lck: boolean, //is blocked content
            id: number, //itinerary id
            ds: string, //description
            exp: boolean, // NEW is course expert
            cls: {
                id: number, //course id
                od: number, //criterio de orden
                ad: string,
                ay: boolean
            }[],
            sps: {
                img: string,
                od: number,
            }[] | null
        }[]
        result: 'ok' | 'ko'
    },
    ok: boolean
}

export interface PharmacyDashboard {
    average_badges: number,
    average_courses: number,
    average_completed_courses: number,
    average_certificates: number,
    average_level: number,
    average_points: number,
    average_time: string,
    average_progress: number,
    data_date: string,
    pharmacy_name: string,
    user_list: PharmacyDashboardUser[]
}

export interface PharmacyDashboardUser {
    user_badges: number,
    user_courses: number,
    user_completed_courses: number,
    user_certificates: number,
    user_id: string,
    user_last_update: string,
    user_level: number,
    user_name: string,
    user_points: number,
    user_total_time: string,
    user_progress: number,
    user_last_badge_name: string,
    user_rank: number
}

export interface ResponseGetPharmacyDashboard {
    data: {
        getPharmacyDashboard: {
            ab: number, //average_badges
            ac: number, //average_courses
            acc: number, //average_completed_courses
            acs: number, //average_certificates
            alv: number, //average_level
            apt: number, //average_points
            at: string, //average_time
            dd: string, //data_date
            ap: number, //average progress
            pnm: string, //pharmacy_name
            ul: //user_list
            {
                ub: number, //user_badges
                bn: string, //badge_name
                uc: number, //user_courses
                ucc: number, //user_completed_courses
                ucs: number, //user_certificates
                uid: string, //user_id
                ulu: string, //user_last_update
                ulv: number, //user_level
                unm: string, //user_name
                up: number, //progress
                upt: number, //user_points
                utt: string //user_total_time
            }[]
        }
    }
}

//#region GetPharmacyData 
export interface ResponseGetPharmacyData {
    data: {
        getPharmacyStats: {
            fd: { // filter data
                vl: string, // valor del dato
                ds: string, // descripcion del campo
                img: string, // icono del campo
                od: number, // orden del campo
            }[],
            hd: { //header
                nm: string, // nombre de la columna
                img: string, // icono de la columna
                od: number, // orden de la columna
            }[],
            rl: { // listado de filas
                nm: string; // El nombre de la fila.
                rd: { // datos de la fila
                    v: string; // El valor del dato.
                    v2: string | number; // El valor del dato.
                    od: number; // El orden del dato.
                }[]; // Lista de datos del curso.
                dl: {
                    nm: string //listado de detalles
                    dl: { //listado de datos de los detalles
                        v: string; // El valor del dato.
                        v2: string | number; // El valor del dato.
                        od: number; // El orden del dato.
                    }[]
                }[];
            }[],
            resume:{
                acc: string,
                ap: string,
                at: string,
                dd: string,
                pnm: string,
                tu: number
            }
        },
        result: 'ok' | 'ko'
    },
    ok: boolean
}

export interface PharmacyData {
    filterData: FilterData[],
    headers: {
        name: string,
        img: string,
        order: number
    }[],
    rows: {
        name: string,
        data: {
            value: string,
            sortValue: string | number,
            order: number
        }[],
        details: {
            name: string,
            data: {
                value: string,
                sortValue: string | number,
                order: number
            }[]
        }[]
    }[],
    resume: PharmacyResume
}

export interface PharmacyResume{
    averageCompletedCourses: string,
    averageProgress: string,
    averageTime: string,
    dataDate: string,
    pharmacyName: string,
    totalUsers: number
}

export interface FilterData{
    value: string,
    description: string,
    img: string,
    order: number
}

export const toDomainGetPharmacyData = function (response: ResponseGetPharmacyData): PharmacyData {
    return {
        filterData: response.data.getPharmacyStats.fd.map((filter) => ({
            value: filter.vl,
            description: filter.ds,
            img: filter.img,
            order: filter.od
        })),
        headers: response.data.getPharmacyStats.hd.map((header) => ({
            name: header.nm,
            img: header.img,
            order: header.od
        })),
        rows: response.data.getPharmacyStats.rl.map((row) => ({
            name: row.nm,
            data: row.rd.map((data) => ({
                value: data.v,
                sortValue: data.v2,
                order: data.od
            })),
            details: row.dl.map((detail) => ({
            // details: row.dl.slice(0, 10).map((detail) => ({
                name: detail.nm,
                data: detail.dl.map((data) => ({
                    value: data.v,
                    sortValue: data.v2,
                    order: data.od
                }))
            }))
        })),
        resume: {
            averageCompletedCourses: response.data.getPharmacyStats.resume.acc,
            averageProgress: response.data.getPharmacyStats.resume.ap,
            averageTime: response.data.getPharmacyStats.resume.at,
            dataDate: response.data.getPharmacyStats.resume.dd,
            pharmacyName: response.data.getPharmacyStats.resume.pnm,
            totalUsers: response.data.getPharmacyStats.resume.tu
        }
    }
}
//#endregion

//#region GetGroupData 
export interface ResponseGetGroupData {
    data: {
        getPharmacyGroupStats: {
            fd: { // filter data
                vl: string, // valor del dato
                ds: string, // descripcion del campo
                img: string, // icono del campo
                od: number, // orden del campo
            }[]
            hd: { //header
                nm: string, // nombre de la columna
                img: string, // icono de la columna
                od: number, // orden de la columna
            }[],
            rl: { // listado de filas
                nm: string; // El nombre de la fila.
                id: number; 
                rd: { // datos de la fila
                    v: string; // El valor del dato.
                    v2: string | number; // El valor del dato.
                    od: number; // El orden del dato.
                }[]; // Lista de datos del curso.
                dl: {
                    nm: string //listado de detalles
                    dl: { //listado de datos de los detalles
                        v: string; // El valor del dato.
                        v2: string | number; // El valor del dato.
                        od: number; // El orden del dato.
                    }[]
                }[];
            }[],
            resume:{
                acc: string,
                ap: string,
                at: string,
                dd: string,
                pnm: string,
                tp: number,
                tu: number
            }
        },
        result: 'ok' | 'ko'
    },
    ok: boolean
}

export interface GroupData {
    filterData: FilterData[],
    headers: {
        name: string,
        img: string,
        order: number
    }[],
    rows: {
        name: string,
        id: number,
        data: {
            value: string,
            sortValue: string | number,
            order: number
        }[],
        details: {
            name: string,
            data: {
                value: string,
                sortValue: string | number,
                order: number
            }[]
        }[]
    }[],
    resume: GroupResume
}

export interface GroupResume{
    averageCompletedCourses: string,
    averageProgress: string,
    averageTime: string,
    dataDate: string,
    groupName: string,
    totalUsers: number
    totalPharmacies: number
}


export const toDomainGetGroupData = function (response: ResponseGetGroupData): GroupData {
    return {
        filterData: response.data.getPharmacyGroupStats.fd.map((filter) => ({
            value: filter.vl,
            description: filter.ds,
            img: filter.img,
            order: filter.od
        })),
        headers: response.data.getPharmacyGroupStats.hd.map((header) => ({
            name: header.nm,
            img: header.img,
            order: header.od
        })),
        rows: response.data.getPharmacyGroupStats.rl.map((row) => ({
            name: row.nm,
            id: row.id,
            data: row.rd.map((data) => ({
                value: data.v,
                sortValue: data.v2,
                order: data.od
            })),
            details: row.dl.map((detail) => ({
            // details: row.dl.slice(0, 10).map((detail) => ({
                name: detail.nm,
                data: detail.dl.map((data) => ({
                    value: data.v,
                    sortValue: data.v2,
                    order: data.od
                }))
            }))
        })),
        resume: {
            averageCompletedCourses: response.data.getPharmacyGroupStats.resume.acc,
            averageProgress: response.data.getPharmacyGroupStats.resume.ap,
            averageTime: response.data.getPharmacyGroupStats.resume.at,
            dataDate: response.data.getPharmacyGroupStats.resume.dd,
            groupName: response.data.getPharmacyGroupStats.resume.pnm,
            totalUsers: response.data.getPharmacyGroupStats.resume.tu,
            totalPharmacies: response.data.getPharmacyGroupStats.resume.tp
        }
    }
}
//#endregion

//#region DownloadPharmacyStats 
export interface responseDownloadPharmacyStats {
    data: {
        getDownloadPharmacyStats: string,
        result: 'ok' | 'ko'
    },
    ok: boolean
}

export const toDomainDownloadPharmacyStats = function (response: responseDownloadPharmacyStats): string {
    return response.data.getDownloadPharmacyStats;
}
//#endregion

//#region DownloadGroupStats 
export interface responseDownloadGroupStats {
    data: {
        getDownloadPharmacyGroupStats: string,
        result: 'ok' | 'ko'
    },
    ok: boolean
}

export const toDomainDownloadGroupStats = function (response: responseDownloadGroupStats): string {
    return response.data.getDownloadPharmacyGroupStats;
}
//#endregion

//#region GetItineraries >
export interface ResponsePharmacyDashboarFilterItineraries {
    data: {
        getItineraryList: {
            nm: string, // nombre del itinerario
            id: number, // id del itinerario
            od: number, // orden del itinerario
        }[],
        result: 'ok' | 'ko'
    },
    ok: boolean
}

export interface PharmacyDashboardFilterItineraryList {
    itineraryList: {
        name: string,
        id: number,
        order: number
    }[]
}

export const toDomainPharmacyDashboardItineraryList = function (response: ResponsePharmacyDashboarFilterItineraries): PharmacyDashboardFilterItineraryList {
    return {
        itineraryList: response.data.getItineraryList.map((itinerary) => ({
            name: itinerary.nm,
            id: itinerary.id,
            order: itinerary.od
        }))
    }
}
//#endregion

//#region GetCourses
export interface ResponsePharmacyDashboarFilterCourses {
    data: {
        getCourseList: {
                id: string, // id del curso
                nm: string, // nombre del curso
                od: number, // orden del curso
                its: number[] // id de los itinerarios a los que pertenece
        }[],
        result: 'ok' | 'ko'
    },
    ok: boolean
}

export interface PharmacyDashboardFilterCourseList {
    courseList: {
        name: string,
        id: string,
        order: number,
        itineraries: number[]
    }[]
}

export const toDomainPharmacyDashboardCourseList = function (response: ResponsePharmacyDashboarFilterCourses): PharmacyDashboardFilterCourseList {
    return {
        courseList: response.data.getCourseList.map((course) => ({
            name: course.nm,
            id: course.id,
            order: course.od,
            itineraries: course.its
        }))
    }
}
//#endregion

export const toDomainGetPharmacyDashboard = function (response: ResponseGetPharmacyDashboard): PharmacyDashboard {
    return {
        average_badges: response.data.getPharmacyDashboard.ab,
        average_courses: response.data.getPharmacyDashboard.ac,
        average_completed_courses: response.data.getPharmacyDashboard.acc,
        average_certificates: response.data.getPharmacyDashboard.acs,
        average_level: response.data.getPharmacyDashboard.alv,
        average_points: response.data.getPharmacyDashboard.apt,
        average_time: response.data.getPharmacyDashboard.at,
        data_date: response.data.getPharmacyDashboard.dd,
        average_progress: response.data.getPharmacyDashboard.ap,
        pharmacy_name: response.data.getPharmacyDashboard.pnm,
        user_list: response.data.getPharmacyDashboard.ul.map((user) => ({
            user_badges: user.ub,
            user_courses: user.uc,
            user_completed_courses: user.ucc,
            user_certificates: user.ucs,
            user_id: user.uid,
            user_progress: user.up,
            user_last_update: user.ulu,
            user_level: user.ulv,
            user_name: user.unm,
            user_points: user.upt,
            user_total_time: user.utt,
            user_last_badge_name: user.bn,
            user_rank: 0
        }))
    }
}

export interface GroupDashboard {
    dataDate: string,
    groupName: string,
    totalUsers: number,
    totalPharmacies: number,
    averageTime: string,
    averageCourses: number,
    averageCompletedCourses: number,
    averageProgress: number,
    pharmacyList: GroupDashboardPharmacy[]
}

export interface GroupDashboardPharmacy {
    pharmacyId: number,
    pharmacyName: string,
    ownerName: string,
    averageBadges: number,
    averageLevel: string,
    averagePoints: string,
    averageCertificates: number,
    averageCourses: number,
    averageCompletedCourses: number,
    averageTime: string
    averageProgress: number,
}

export interface ResponseGetGroupDashboard {
    data: {
        getGroupDashboard: {
            dd: string, //dataDate
            gnm: string, //groupName
            tu: number, //totalUsers
            tp: number, //totalPharmacies
            at: string, //averageTime
            ac: number, //averageCourses
            acc: number, //averageCompletedCourses
            ap: number, // averageProgress
            pl: { //pharmacyList
                py: number, //pharmacyId
                pnm: string, //pharmacyName
                onm: string, //ownerName
                ab: number, //averageBadges
                alv: string, //averageLevel
                apt: string, //averagePoints
                acs: number, //averageCertificates
                ac: number, //averageCourses
                acc: number, //averageCompletedCourses
                at: string, //averageTime
                ap: number, // averageProgress
            }[]
        },
        result: string
    },
    ok: true
}

export const toDomainGetGroupDashboard = function (response: ResponseGetGroupDashboard): GroupDashboard {
    return {
        dataDate: response.data.getGroupDashboard.dd,
        groupName: response.data.getGroupDashboard.gnm,
        totalUsers: response.data.getGroupDashboard.tu,
        totalPharmacies: response.data.getGroupDashboard.tp,
        averageTime: response.data.getGroupDashboard.at,
        averageCourses: response.data.getGroupDashboard.ac,
        averageCompletedCourses: response.data.getGroupDashboard.acc,
        averageProgress: response.data.getGroupDashboard.ap,
        pharmacyList: response.data.getGroupDashboard.pl.map((user) => ({
            pharmacyId: user.py,
            pharmacyName: user.pnm,
            ownerName: user.onm,
            averageBadges: user.ab,
            averageLevel: user.alv,
            averagePoints: user.apt,
            averageCertificates: user.acs,
            averageCourses: user.ac,
            averageCompletedCourses: user.acc,
            averageTime: user.at,
            averageProgress: user.ap
        }))
    }
}

export const toDomainReportHolderByUser = function (response: ResponseReportHolderByUser): UserData {
    return {
        level: response.data.reportHoldersByUser.lv,
        lastBadge: response.data.reportHoldersByUser.bn,
        totalAchievements: response.data.reportHoldersByUser.bp,
        totalKnowledge: response.data.reportHoldersByUser.pt,
        totalCredits: response.data.reportHoldersByUser.pt,
        totalCoursesAsigned: response.data.reportHoldersByUser.ac,
        totalCoursesCompleted: 0,
        totalTime: response.data.reportHoldersByUser.tt,
        branches: response.data.reportHoldersByUser.bc,
        totalCertificates: response.data.reportHoldersByUser.cs,
        pharmacyName: response.data.reportHoldersByUser.pnm ?? '',
        groupName: response.data.reportHoldersByUser.gnm ?? '',
        firstName: response.data.reportHoldersByUser.fn,
        sponsors: response.data.reportHoldersByUser.sps ? sortSponsors(response.data.reportHoldersByUser.sps.map((sponsor) => ({
            img: sponsor.img,
            order: sponsor.od,
        }))) : [],
    }
}

const sortSponsors = function (sponsors: Sponsor[]): Sponsor[] {
    return sponsors.sort((a, b) => {
        if (a.order > b.order) {
            return 1;
        }
        if (a.order < b.order) {
            return -1;
        }
        return 0;
    })
}

export const toDomainGetCourses = function (response: ResponseGetCourses): CourseProgress[] {
    return response.data.getTheCoursesByUser.map((item) => ({
        id: item.idb,
        name: item.no,
        categoryName: item.nc,
        percentage: item.cp,
        timeSpent: item.tl,
        state: item.cp == '0' ? 'sin empezar' : item.cp == '100' ? 'completado' : 'incompleto',
        courseLink: item.aut,
        talentId: item.ico,
        availabilityDate: '',
        isBlocked: false,
        description: item.des,
        tags: item.tgs ?? '',
        contenTypes: item.cnt ? item.cnt.map((content) => ({
            typeId: content.cntt,
            quantity: content.qt,
            contenName: content.cntn
        })) : [],
        isAvaible: true,
        hasEnrollment: item.en,
        certificate: item.cr,
        courseDates: item.cd,
        image: item.img,
        authorCourse: item.atr,
        disable: false
    }))
}

export const toDomainGetUrlFromCourse = function (response: ResponseGetUrlFromCourse): string {
    return response.data.getUrlFromCourse;
}

export interface ResponseEnrrollInTheCourse {
    data: {
        enrollInTheCourse: string,
        result: 'ok' | 'ko'
    },
    ok: boolean
}

export const toDomainEnrrollInTheCourse = function (response: ResponseEnrrollInTheCourse): string {
    return response.data.enrollInTheCourse;
}

export interface CertificateData {
    certificateImage: string,
    certificatePdf: string,
    urlAddToProfile: string,
    urlToShare: string
}

export interface ResponseGetCertificateData {
    data: {
        getCertificateData: {
            cei: string, //certificate_image, URL de la imagen del certificado en PNG.
            cpdf: string,//certificate_pdf, URL del certificado en PDF.
            uatp: string, //url_add_to_profile, Devuelve la URL que permite al usuario publicar el certificado en su perfil de Linkedin.
            uts: string // url_to_share, URL para publicar el certificado en el feed de Linkedin.
        },
        result: 'ok' | 'ko'
    },
    ok: boolean
}

export const toDomainGetCertificateData = function (response: ResponseGetCertificateData): CertificateData {
    return {
        certificateImage: response.data.getCertificateData.cei,
        certificatePdf: response.data.getCertificateData.cpdf,
        urlAddToProfile: response.data.getCertificateData.uatp,
        urlToShare: response.data.getCertificateData.uts
    };
}

export interface AvailableNew {
    id: number,
    title: string,
    subtitle: string,
    description: string,
    id_course: number,
    url: string,
    image: string
}

export interface ResponseGetAvailableNews {
    data: {
        getAvailableNews: {
            nid: number,
            tit: string,
            stit: string,
            des: string,
            cid: number,
            url: string,
            img: string
        }[],
        result: 'ok' | 'ko'
    },
    ok: boolean
}

export const toDomainGetAvailableNews = function (response: ResponseGetAvailableNews): AvailableNew[] {
    return response.data.getAvailableNews.length > 0 ? response.data.getAvailableNews.map((singleNew) => ({
        id: singleNew.nid,
        title: singleNew.tit,
        subtitle: singleNew.stit,
        description: singleNew.des,
        id_course: singleNew.cid,
        url: singleNew.url,
        image: singleNew.img,
    }))
        : [];
}

export const toDomainGetItineraries = function (response: ResponseGetItineraries): Itinerary[] {
    return response.data.getItinerariesByUser.length > 0 ? response.data.getItinerariesByUser.map((it) => ({
        name: it.nm,
        isLocked: it.lck,
        id: it.id,
        description: it.ds,
        isExpert: it.exp,
        courseList: it.cls.length > 0 ? it.cls.map((crs) => ({
            id: crs.id,
            conten_types: [],
            name: '',
            category: '',
            status: 'incompleto',
            time: '',
            percentage: '0',
            talentId: '',
            courseLink: '',
            isBlocked: true,
            isAvaible: crs.ay,
            hasEnrollment: false,
            availabilityDate: crs.ad
        })) : [],
        sponsors: it.sps ? sortSponsors(it.sps.map((sp) => ({
            img: sp.img,
            order: sp.od
        }))) : [],
        timeSpent: '0',
        percentage: 0
    })) : [];
}
