import { PharmacyDashboardFilterCourseList } from '../domain/Dashboard';
import { DashboardRepository } from '../domain/DashboardRepository'

export class GetPharmacyDashboardFilterCourses {
  private readonly repository: DashboardRepository

  constructor(repository: DashboardRepository) {
    this.repository = repository
  }

  public async execute(accessToken: string, pharmacyId?: number): Promise<PharmacyDashboardFilterCourseList | undefined> {
    if (pharmacyId) {
      return this.repository.GetPharmacyDashboardFilterCourses(accessToken, pharmacyId);
    }
    return this.repository.GetPharmacyDashboardFilterCourses(accessToken);
  }
}