import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { LOCAL_STORAGE } from '@ng-web-apis/common';
import { CookiesService } from './cookies.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public SESSION_TOKEN: string = 'session_token';
  public USER_DATA: string = 'user_data';
  public SALE_CODE: string = 'saleCode';
  public BRANCHES: string = 'branches';
  public CONTACT_NAME: string = 'contact_name';
  public UPPER_LEVEL_NAME: string = 'upper_level_name';

  #cookiesService = inject(CookiesService);

  constructor(
    @Inject(LOCAL_STORAGE) private localStorage: Storage
  ) {}


  public find(key: string): any {
      if(key === this.SESSION_TOKEN){
        return this.#cookiesService.getAccessToken();
      } else {

        return this.localStorage.getItem(key)
      }
  }

  public set(key: string, value: any): void {
      if(key === this.SESSION_TOKEN){
        this.#cookiesService.setAccessToken(value);
      } else {
        this.localStorage.setItem(key, value)
      }
  }

  public remove(key: string): void {
      this.localStorage.removeItem(key)
  }
  
  public clearSessionData(){
      // this.localStorage.removeItem(this.SESSION_TOKEN);
      this.#cookiesService.removeAccessToken();
      this.localStorage.removeItem(this.USER_DATA);
      this.localStorage.removeItem(this.BRANCHES);
      // this.localStorage.removeItem(this.SALE_CODE);
  }
}
