import { CartRepository } from '../domain/CartRepository'
import { Set, LicenseId } from '../domain/Cart'

export class CartSet {
  private readonly repository: CartRepository

  constructor(repository: CartRepository) {
    this.repository = repository
  }

  public async execute({ userToken, license, saleCode }: { userToken?: string, license: LicenseId[], saleCode?: string }): Promise<Set> {
    return this.repository.set({ userToken, license, saleCode })
  }
}
