import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { CartGet } from '../cross/cart/application/CartGet';
import { CartSet } from '../cross/cart/application/CartSet'
import { LicenseId } from '../cross/cart/domain/Cart';
import { updateLicenseSelector } from '../state/cart.selectors';
import { updateCartAction } from '../state/cart.actions';
import { StorageService } from './storage.service';
import { TranslateService } from './translate.service';
import { ErrorModalService } from './error-modal.service';
import { LoggerProviderService } from './logger/logger-provider.service';
import { CartErrorList } from '../cross/cart/domain/CartError';
import { ErrorModalData } from '../components/molecules/error-modal/error-modal.component';

@Injectable({
  providedIn: 'root'
})
export class StateService implements OnDestroy {
  private readonly store: Store
  public cartLicense: LicenseId[]
  private readonly cartSet: CartSet
  private readonly cartGet: CartGet
  private updateLicenseSelector$: Observable<readonly LicenseId[]> = new Observable()
  private subcriptionOffer: Subscription | undefined
  private subcriptionCart: Subscription | undefined
  private initService: boolean

  constructor(
    store: Store,
    cartSet: CartSet,
    cartGet: CartGet,
    private translateService: TranslateService,
    private errorModalService: ErrorModalService,
    private storageService: StorageService,
    private loggerProviderService: LoggerProviderService
  ) {
    this.store = store
    this.cartLicense = []
    this.cartSet = cartSet
    this.cartGet = cartGet
    this.updateLicenseSelector$ = this.store.select(updateLicenseSelector)
    this.initService = true
  }

  ngOnDestroy(): void {
    // If this component destroy remove the subscription
    this.subcriptionOffer?.unsubscribe()
    this.subcriptionCart?.unsubscribe()
  }

  /**
   * @description If return true the user is logged
   */
   hasSaleCode(): boolean {
    return this.storageService.find('saleCode')
      && this.storageService.find('saleCode') !== null
        ? true
        : false
  }

  async getCart() {    
    return this.cartGet.execute({
      userToken: this.storageService.find('session_token'),
      saleCode: this.storageService.find('saleCode'),
    })
  }

  async init() {
    // Subcribe to global state in order to send the offer added into the cart to the cartSet service.
    this.subcriptionOffer = this.updateLicenseSelector$.subscribe(async (license) => {
        // Control that not execute the first app execute
        if(!this.initService) {
          this.cartLicense = license as LicenseId[]

          try {  
            const resultCartSet = await this.cartSet.execute({
              userToken: this.storageService.find('session_token'),
              saleCode: this.storageService.find('saleCode'),
              license: this.cartLicense
            })
            
            if(!resultCartSet) {
              return
            }
            
            //crate cart
            if (!this.hasSaleCode() && resultCartSet.saleCode) {
              this.storageService.set('saleCode', resultCartSet.saleCode)
            }
          } catch (err: any) {
            const { message } = err
            this.loggerProviderService.error(err);

            if (message.includes(CartErrorList.FINISHED_CART)) {
              this.createCart();
              return
            }

            this.errorModalService.dispatchModalError(<ErrorModalData>{
              title:this.translateService.translate('error_modal.title'),
              subTitle: this.translateService.translate('error_modal.sub_title'),
              msg: this.translateService.translate('error_modal.msg'),
              errorCode: '',
              description: this.translateService.translate('error_modal.description'),
              info: this.translateService.translate('error_modal.info'),
              btnText: this.translateService.translate('error_modal.btnText')
            })
          }
        }

        // Get all cart information
        if(this.hasSaleCode()) {
          try {
            const resultCartGet = await this.getCart()
            if(!resultCartGet) {
              return
            }
            this.store.dispatch(updateCartAction({ cart: resultCartGet }));
          } catch (error) {
            this.storageService.remove('saleCode');
            this.createCart();
          }
        }
    })

    this.initService = false
  }

  async createCart(){
    try {
      const resultCartSetTry = await this.cartSet.execute({
        license: this.cartLicense
      })
      if (resultCartSetTry?.saleCode) {
        this.storageService.set('saleCode', resultCartSetTry.saleCode)
      }
    } catch (error) {
      this.errorModalService.dispatchModalError(<ErrorModalData>{
        title:this.translateService.translate('error_modal.title'),
        subTitle: this.translateService.translate('error_modal.sub_title'),
        msg: this.translateService.translate('error_modal.msg'),
        errorCode: '',
        description: this.translateService.translate('error_modal.description'),
        info: this.translateService.translate('error_modal.info'),
        btnText: this.translateService.translate('error_modal.btnText')
      })
      return
    }
    // Get all cart information
    if(this.hasSaleCode()) {
      try {
        
        const resultCartGet = await this.getCart()
  
        if(!resultCartGet) {
          return
        }
        this.store.dispatch(updateCartAction({ cart: resultCartGet }));
      } catch (error) {
        this.storageService.remove('saleCode');
          // this.createCart();
      }
    }
  }
  
}
