import { Injectable } from '@angular/core'
import { es } from 'src/assets/i18n/es'

export type Language = 'es'
interface LocalizationStrings {
  [key: string]: string | LocalizationStrings;
}
@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  private readonly defaultLanguage = 'es'

  private _translations: LocalizationStrings = {}

  get translations(): LocalizationStrings {
    return this._translations
  }

  async setTranslationsLanguage(language?: Language): Promise<void> {
    const finalLanguage = language ?? this.defaultLanguage
    if (finalLanguage === 'es') {
      this._translations = es
    }
    // const translationsPath = `../../assets/i18n/${finalLanguage}.json`
    // const result = await fetch(translationsPath)
    // this._translations = await result.json()
  }

  searchAForEach(key: string): string {
    if(this._translations[key]){
      return this._translations[key].toString();
    }
    let res: any = this._translations
    key.split('.').forEach((k)=>{
      res = res[k];
    })
    return res;
  }

  translate(key: string): string {
    return this.searchAForEach(key).toString();
    // return this._translations ? this._translations[key] : key
  }

  getTranslationsLanguage(): string {
    return this.defaultLanguage
  }
}
