import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountLogin } from 'src/app/cross/account/application/AccountLogin';
import { Account } from 'src/app/cross/account/domain/Account';
import { AccountErrorList } from 'src/app/cross/account/domain/AccountError';
import { ForgotPasswordService } from 'src/app/services/forgot-password/forgot-password.service';
import { LoggedService } from 'src/app/services/logged.service';
import { LoggerProviderService } from 'src/app/services/logger/logger-provider.service';
import { StorageService } from 'src/app/services/storage.service';
import { TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-forgot-password-modal',
  templateUrl: './forgot-password-modal.component.html',
  styleUrls: ['./forgot-password-modal.component.scss']
})
export class ForgotPasswordModalComponent implements OnInit {

  send_email: number = 1;
  temp_pass: number = 2;
  change_pass: number = 3;
  ready: number = 4;
  step: number = this.send_email

  @Input() modalVisible: boolean = false;

  private email: string = '' ;

  // ---- password form ----
  private readonly accountLogin: AccountLogin

  public formGroup!: FormGroup;
  public hasPasswordError: boolean = false
  public passwordMessageError: string = ''
  
  constructor(
    private translateService: TranslateService,
    private forgotPasswordService: ForgotPasswordService,
    private loggedService: LoggedService,
    private storageService: StorageService,
    accountLogin: AccountLogin,      
    private router: Router,
    private loggerProviderService: LoggerProviderService
    ) { 
    this.accountLogin = accountLogin      
    }
    
  // ---- password form ----
  private buildForm() {
    this.formGroup = new FormGroup({
      password: new FormControl(null, [Validators.required]),
    });
  }

  public onSubmit(){
    this.sendForm(this.formGroup?.value.password)
  }

  private async sendForm(pwd: string) {
    try {
      this.storageService.clearSessionData();
      let user = this.email;
      const result = await this.accountLogin.execute(user, pwd)
      this.loggedService.login();
      if (result) {
        this.storageService.set('session_token', result.token);
        this.storageService.set('user_data', JSON.stringify(result));
        this.step = this.change_pass;
      }
    } catch (err: any) {
      const { message } = err
      if(message.includes(AccountErrorList.PASSWORD_INCORRECT)) {
        this.hasPasswordError = true
        this.passwordMessageError =  this.translateService.translate('error.account.passwordIncorrect')
        return
      }
      this.loggerProviderService.error(err);
    }
  }

  checkErrorInputpassword(): void {
    this.hasPasswordError = this.formGroup.get('password')?.hasError('required') || 
      this.formGroup.get('password')?.hasError('minlength') || false

    this.passwordMessageError = this.formGroup.get('password')?.hasError('required')
      ? this.translateService.translate('login.input_email_message_empty_error')
        : this.formGroup.get('password')?.hasError('minlength')
        ? 'La contraseña debe tener una langitud mínima de 8 caracteres'
            :''
  }
  // ---- * ----

  ngOnInit(): void {
    this.forgotPasswordService.getModal$().subscribe(data => {
      this.showModal();
    })
    this.buildForm();
    this.checkUserStatus();
  }
  
  async checkUserStatus(){
    const userData: Account | undefined = await JSON.parse(this.storageService.find(this.storageService.USER_DATA))
    if(userData != undefined){
      this.step = this.change_pass;
    }
  }

  showModal(){
    this.modalVisible = true;
    this.step = this.send_email
    this.checkUserStatus();
  }

  closeModal(){
    this.modalVisible = false;
  }

  onForgotPasswordSubmit(res: {emailSended: boolean, email: string}){
    // this.forgotPassMenu = !emailSended; email enviado
    this.email = res.email;
    this.step = this.temp_pass;
  }

  async onSubmitChangePassword(ischanged: boolean){
    this.step = this.ready;
    const userData: Account | undefined = await JSON.parse(this.storageService.find(this.storageService.USER_DATA))
    if(userData){
      userData.mustChangePwd = false;
      this.storageService.set(this.storageService.USER_DATA,JSON.stringify(userData))
    }
  }

}
