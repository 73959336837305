import { DashboardRepository } from '../domain/DashboardRepository'
import { CourseProgress } from '../domain/Dashboard'

export class GetUrlFromCourse {
  private readonly repository: DashboardRepository

  constructor(repository: DashboardRepository) {
    this.repository = repository
  }

  public async execute(branchName: string, courseId: string, accessToken: string): Promise<string | undefined> {
    return this.repository.GetUrlFromCourse(branchName, courseId, accessToken)
  }
}