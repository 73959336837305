import { NgModule } from '@angular/core';
import { ModalErrorComponent } from './modal-error.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        ModalErrorComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ModalErrorComponent
    ],
})
export class ModalErrorModule { }