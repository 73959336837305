//#region getUserCourses 
export interface ResponseGetUserCourses {
    data: {
        getUserCourses: {
            cid: number,
            nc: string,
            no: string
        }[]
        result: 'ok' | 'ko'
    }
    ok: boolean
}

export const toDomaingetUserCourses = function (response: ResponseGetUserCourses): Course[] {
    return response.data.getUserCourses.map((item) => ({
        idCourse: item.cid,
        name: item.no,
        category: item.nc,
    }))
}

export interface Course {
    idCourse: number,
    name: string,
    category: string
}

//#endregion

//#region  getPharmacies 
export interface Branch {
    id: string,
    pharmacies: Pharmacy[]
}
export interface Pharmacy {
    name: string,
}

export interface PharmacyUserModel {
    hasItinerary: boolean,
    name: string,
    lmsId: number,
}
export interface PharmacyModel {
    id: number,
    name: string,
    users: PharmacyUserModel[],
}
export const toDomainGetPharmacies = (response: any): PharmacyModel[] => {
    return response.data.getPharmacies.map((pharmacy: any) => {
        return {
            id: pharmacy.id,
            name: pharmacy.nm,
            users: pharmacy.usl.map((user: any) => ({
                hasItinerary: user.hi,
                name: user.nm,
                lmsId: user.lms,
            })),
        }
    });
}
//#endregion

//#region  getBranchItineraries 
export const ITINERARY_STATUS_PUBLISHED = 0;
export const ITINERARY_STATUS_DRAFT = 1;
export const ITINERARY_TEMPLATE = 2;

export interface ResponseGetBranchItineraries {
    data: {
        getBranchItineraries: {
            its: {
                id: number,
                nm: string,
                lck: boolean,
                ds: string | null,
                lu: string,
                st: number,
                cls: {
                    cid: number,
                    no: string,
                    nc: string,
                    od: number,
                    ad: string | null,
                    cnt: {
                        cntt: number,
                        qt: number,
                        cntn: string
                    }[]
                }[],
                usl: number,
                pl: number
            }[],
            usl: number
            pl: number,
        },
        result: 'ok' | 'ko'
    },
    ok: true
}

export interface ItineraryData {
    itineraries: Itinerary[],
    totalPharmacies: number,
    totalUsers: number
}

export interface Itinerary {
    id: number,
    description: string,
    name: string,
    lastUpdate?: string,
    blocked: boolean,
    courseList: ItineraryCourse[],
    userList: number,
    pharmacyList: number,
    // totalUser: number
    status: 0 | 1 | 2;
}

export interface ItineraryUser {
    lms: number,
    name: string,
    self?: boolean
}

export interface ItineraryPharmacy {
    id: number,
    name: string
}
export interface ItineraryCourse {
    id: number,
    name: string,
    category: string,
    order: number,
    availabilityDate: string,
    content: ContentType[]
}

export interface ContentType {
    typeId: number,
    quantity: number,
    contenName: string
}

export const toDomainGetBranchItineraries = function (response: ResponseGetBranchItineraries): ItineraryData {
    return {
        itineraries: response.data.getBranchItineraries.its.map((itinerary) => ({
            id: itinerary.id,
            name: itinerary.nm,
            lastUpdate: itinerary.lu,
            description: itinerary.ds ?? '',
            blocked: itinerary.lck,
            courseList: itinerary.cls.map((course) => ({
                id: course.cid,
                name: course.no,
                category: course.nc,
                order: course.od,
                availabilityDate: course.ad ?? '',
                content: course.cnt ? course.cnt.map((ctn) => ({
                    typeId: ctn.cntt,
                    quantity: ctn.qt,
                    contenName: ctn.cntn
                })) : []
            })),
            userList: itinerary.usl,
            pharmacyList: itinerary.pl,
            status: itinerary.st == ITINERARY_STATUS_PUBLISHED ? ITINERARY_STATUS_PUBLISHED : ITINERARY_STATUS_DRAFT
        })),
        totalPharmacies: response.data.getBranchItineraries.pl,
        totalUsers: response.data.getBranchItineraries.usl

    }
}
//#endregion

//#region  create Itinerary 

export interface ResponseCreateItinerary {
    data: {
        createItinerary: number,
        result: 'ok' | 'ko'
    },
    ok: true
}

export interface ItineraryCreate {
    nm: string,
    ds: string | null,
    lck: boolean,
    st: number
    cls: {
        cid: number,
        od: number,
        ad?: string,
    }[],
    usl: number[],
    // usl: {
    //     lms: number,
    //     nm: string
    // }[],
    // pl: {
    //     id: number,
    //     nm: string
    // }[]
}

export const toDomainCreateItinerary = function (response: ResponseCreateItinerary): number {
    return response.data.createItinerary;
}
//#endregion

//#region  edit Itinerary 

export interface ResponseEditItinerary {
    data: {
        editItinerary: number,
        result: 'ok' | 'ko'
    },
    ok: true
}

export interface ItineraryEdit {
    iid: number,
    ds: string | null,
    nm: string | null,
    lck: boolean,
    st: number
    cls: {
        cid: number,
        od: number,
        ad?: string,
    }[],
    usl: number[],
    // usl: {
    //     lms: number,
    //     nm: string
    // }[],
    // pl: {
    //     id: number,
    //     nm: string
    // }[]
}

export const toDomainEditItinerary = function (response: ResponseEditItinerary): number {
    return response.data.editItinerary;
}
//#endregion

//#region  removeItinerary 

export interface ResponseRemoveItinerary {
    data: {
        removeItinerary: 'ok' | 'ko',
        result: 'ok' | 'ko'
    },
    ok: true
}

export const toDomainRemoveItinerary = function (response: ResponseRemoveItinerary): 'ok' | 'ko' {
    return response.data.removeItinerary;
}
//#endregion

//#region  GetCourseContentTypes 

export interface ResponseGetCourseContentTypes {
    data: {
        getCourseContentTypes: {
            cntt: number,
            qt: number,
            cntn: string
        }[]
        result: 'ok' | 'ko'
    },
    ok: true
}

export const toDomainGetCourseContentTypes = function (response: ResponseGetCourseContentTypes): ContentType[] {
    return response.data.getCourseContentTypes.map((ctn) => ({
        typeId: ctn.cntt,
        quantity: ctn.qt,
        contenName: ctn.cntn
    }));
}
//#endregion

//#region  GetCourseContentTypes 

export interface ResponseDuplicateItinerary {
    data: {
        duplicateItinerary: 'ok' | 'ko',
        result: 'ok' | 'ko'
    },
    ok: true
}

export const toDomainDuplicateItinerary = function (response: ResponseDuplicateItinerary): 'ok' | 'ko' {
    return response.data.duplicateItinerary;
}
//#endregion

//#region  getItineraryTemplates 

export interface ResponseGetItineraryTemplates {
    data: {
        getItineraryTemplates: {
            its: {
                cls: {
                    ad: string,
                    ay: boolean,
                    cnt:
                    {
                        cntn: string,
                        cntt: number,
                        qt: number
                    }[]
                    cid: number,
                    nc: string,
                    no: string,
                    od: number
                }[],
                id: number,
                lck: boolean,
                ds: string,
                nm: string,
                st: number
                pl: number,
                usl: number
            }[],
            tu: number
        },
        result: 'ok' | 'ko'
    },
    "ok": boolean
}

export interface ItineraryTemplate extends Omit<Itinerary, 'userList' | 'pharmacyList'> {
    userList: ItineraryUser[],
    pharmacyList: ItineraryPharmacy[]
}

export interface ItineraryDataTemplate {
    itineraries: ItineraryTemplate[],
    totalPharmacies: ItineraryPharmacy[],
    totalUsers: ItineraryUser[]
}

export const toDomainGetItineraryTemplates = function (response: ResponseGetItineraryTemplates): Itinerary[] {
    return response.data.getItineraryTemplates.its.map((itinerary) => ({
        id: itinerary.id,
        name: itinerary.nm,
        lastUpdate: '',
        description: itinerary.ds ?? '',
        blocked: itinerary.lck,
        courseList: itinerary.cls.map((course) => ({
            id: course.cid,
            name: course.no,
            category: course.nc,
            order: course.od,
            availabilityDate: course.ad ?? '',
            content: course.cnt.map((ctn) => ({
                typeId: ctn.cntt,
                quantity: ctn.qt,
                contenName: ctn.cntn
            }))
        })),
        userList: itinerary.usl,
        pharmacyList: itinerary.pl,
        totalUser: response.data.getItineraryTemplates.tu,
        status: ITINERARY_TEMPLATE
    }))
}


//#endregion

//#region  getUsers
export interface UserModel {
    lms: number,
    name: string,
    hasItinerary: boolean,
    self: boolean
}

export const toDomainGetUsers = function (response: any): UserModel[] {
    return response.data.getUsers.map((user: any) => ({
        lms: user.id,
        name: user.nm,
        hasItinerary: user.hi,
        self: user.sf
    }))
}
//#endregion