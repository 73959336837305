
export abstract class CrmRepository {
    abstract addToNewsLetter(
        app: number,
        email: string,
        firstName: string,
        type?: number,): Promise<boolean | undefined>
    abstract addToLeadList(app: number,
        licenseId: number,
        funnel: number,
        type: number,
        email: string,
        firstName: string): Promise<boolean | undefined>
}