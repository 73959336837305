import { CustomError } from "../../share/domain/CustomError"

export enum ItineraryErrorList {
    ITINERARY_NAME_ALREADY_EXISTS = 'itinerary name already exists',
}

export class ItineraryError extends Error implements CustomError {
  public override readonly name: string = 'itinerary.error'

  public static itineraryNameAlreadyExist (): ItineraryError {
    return new this(ItineraryErrorList.ITINERARY_NAME_ALREADY_EXISTS)
  }
}
