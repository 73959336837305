import { NgModule } from '@angular/core';
import { LfTextAreaComponent } from './lf-text-area.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        LfTextAreaComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        LfTextAreaComponent
    ],
})
export class LfTextAreaModule { }