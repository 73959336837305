import { NgModule } from '@angular/core';
import { LfInputTextComponent } from './lf-input-text.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        LfInputTextComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        LfInputTextComponent
    ],
})
export class LfInputTextModule { }