import { response } from "express"

export type LicenseId = number

export interface License {
  stripeId: string
  licenseId: LicenseId
  price: string
  title: string
  description: string
  discount: number
}

export interface Cart {
  saleCode: string
  license: License[],
  coupon?: {
    result: string
    message: string
    number: string
    stop: 'true' | 'false'
  }
}

export interface Set {
  saleCode: string
  coupon?: {
    result: string
    message: string
    number: string
    stop: 'true' | 'false'
  }
}

export interface SetOwnerCart {
  setOwnerCart: 'ok' | 'ko'
}

export interface Paiment {
  client_secret?: string
  currency?: string,
  items?: [
    {
      item_id: number,
      item_name: string,
      price: number
    }
  ],
  transaction_id?: string,
  value?: number
  result: 'ok' | 'ko'
}

export interface ResponseCartSet {
  data: {
    setCart: {
      foff_p: number,
      icpn: {
        cpn: string
        msg: string
        res: string
        stop: 'true' | 'false'
      },
      itl: {
        dis: number
        li: number
        off: number
        off_p: number
      }[]
      scd: string // Identificador de Carrito
    }
    result: 'ok' | 'ko'
  }
  ok: boolean
}

export const toDomainCartSet = function (response: ResponseCartSet): Set {
  return {
    saleCode: response.data.setCart.scd,
    coupon: {
      result: response.data.setCart.icpn.res,
      message: response.data.setCart.icpn.msg,
      number: response.data.setCart.icpn.cpn,
      stop: response.data.setCart.icpn.stop,
    }
  }
}

export interface ResponseCartPaid {
  data: {
    paidCart: {
      client_secret?: string
      currency?: string,
      items?: [
        {
          item_id: number,
          item_name: string,
          price: number
        }
      ],
      transaction_id?: string,
      value?: number
    }
    result: 'ok' | 'ko'
  }
  ok: boolean
}

export interface ResponsePayConfirmation {
  data: {
    payConfirmation: {
      currency: string,
      items: [
        {
          item_id: number,
          item_name: string,
          price: number
        }
      ],
      transaction_id: string,
      value: number
    }
    result: 'ok' | 'ko'
  }
  ok: boolean
}

export interface PayConfirmationData {
  currency: string,
  items: [
    {
      item_id: number,
      item_name: string,
      price: number
    }
  ],
  transaction_id: string,
  value: number
}


export interface ResponseCheckUsedDemo {
  data: {
    checkUsedDemo: boolean,
    result: 'ok' | 'ko'
  }
  ok: boolean
}


export const toDomainCartPaid = function (response: ResponseCartPaid): Paiment {
  return {
    ...(response.data.paidCart.client_secret && { client_secret: response.data.paidCart.client_secret }),
    ...(response.data.paidCart.currency && { currency: response.data.paidCart.currency }),
    ...(response.data.paidCart.items && { items: response.data.paidCart.items }),
    ...(response.data.paidCart.transaction_id && { transaction_id: response.data.paidCart.transaction_id }),
    result: response.data.result
  }
}

export interface ResponseCartGet {
  data: {
    getCart: {
      foff_p: number,
      icpn: {
        cpn: string
        msg: string
        res: string
        stop: 'true' | 'false'
      },
      itl: {
        stp: string
        dis: number
        lid: number
        off_nm: string
        off_dp: string
        pr: string
      }[]
      scd: string
    }
    result: 'ok' | 'ko'
  }
  ok: boolean
}

export const toDomainCartGet = function (response: ResponseCartGet): Cart {
  return {
    saleCode: response.data.getCart.scd,
    license: response.data.getCart.itl.map((license) => ({
      stripeId: license.stp,
      licenseId: license.lid,
      price: license.pr,
      title: license.off_nm,
      description: license.off_dp,
      discount: license.dis
    })),
    coupon: {
      result: response.data.getCart.icpn.res,
      message: response.data.getCart.icpn.msg,
      number: response.data.getCart.icpn.cpn,
      stop: response.data.getCart.icpn.stop,
    }
  }
}

export interface ResponseCartSetOwnerCart {
  data: {
    setOwnerCart: 'ok' | 'ko'
    result: 'ok' | 'ko'
  }
  ok: boolean
}

export const toDomainCartSetOwnerCart = function (response: ResponseCartSetOwnerCart): SetOwnerCart {
  return {
    setOwnerCart: response.data.setOwnerCart
  }
}

export const toDomainCheckUsedDemo = function (response: ResponseCheckUsedDemo): boolean {
  return response.data.checkUsedDemo;
}

export const toDomainPayConfirmation = function (response: ResponsePayConfirmation): PayConfirmationData {
  return {
    currency: response.data.payConfirmation.currency,
    items: response.data.payConfirmation.items,
    transaction_id: response.data.payConfirmation.transaction_id,
    value: response.data.payConfirmation.value,
  }
}

export interface ICouponValues {
  amountSubtotal: number,
  amountTotal: number,
  amountDiscount: number,
  percentOff: number
}

export interface ApplyCouponsResponse {
  ok: boolean
  data: ICouponValues | string
}

export const toDomainApplyCoupons = (response: any): ApplyCouponsResponse => {
  let data;
  if(response.ok) {
    data = {
      amountSubtotal: response.data.applyCoupons.amount_subtotal,
      amountTotal: response.data.applyCoupons.amount_total,
      amountDiscount: response.data.applyCoupons.amount_discount,
      percentOff: response.data.applyCoupons.percent_off
    }
  }else{
    data = response.msg;
  }
  return { ok: response.ok, data };
}

export interface CheckCouponsResponse {
  ok: boolean,
  data?: string
}

export const toDomainCheckCoupons = (response: any): CheckCouponsResponse => {
  let data = response.msg;
  if (response.data && response.data.CheckCoupons) data = String(response.data.CheckCoupons.percent);

  return { ok: response.ok, data };
}
