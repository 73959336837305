import { environment } from '../../../../environments/environment'
import { RequestError } from '../../share/domain/RequestError'
import { ContentType, Course, Itinerary, ItineraryCreate, ItineraryData, ItineraryDataTemplate, ItineraryEdit, ItineraryTemplate, PharmacyModel, toDomainCreateItinerary, toDomainDuplicateItinerary, toDomainEditItinerary, toDomainGetBranchItineraries, toDomainGetCourseContentTypes, toDomainGetItineraryTemplates, toDomainGetPharmacies, toDomaingetUserCourses, toDomainGetUsers, toDomainRemoveItinerary, UserModel } from '../domain/Itinerary'
import { ItineraryError } from '../domain/ItineraryError'
import { ItineraryRepository } from '../domain/ItineraryRepository'

export class ItineraryRepositoryRest extends ItineraryRepository {
  private readonly HOST = environment.host
  private readonly PCEK = environment.pcek

  public async getUserCourses(token: string): Promise<Course[] | undefined> {
    const response = await fetch(`${this.HOST}/itineraries/getUserCourses`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': token
      },
      body: JSON.stringify({})
    })    

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false || !result.data) {
      throw RequestError.generic(this.constructor.name, result)
    }
    
    return toDomaingetUserCourses(result);
  }

  public async getPharmacies(token: string, itineraryId: number): Promise<PharmacyModel[]> {
    const response = await fetch(`${this.HOST}/itineraries/getPharmacies`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': token
      },
      body: JSON.stringify({ iid: itineraryId })
    })    

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false || !result.data) {
      throw RequestError.generic(this.constructor.name, result)
    }
    
    return toDomainGetPharmacies(result);
  }

  public async getBranchItineraries(token: string): Promise<ItineraryData | undefined> {
    const response = await fetch(`${this.HOST}/itineraries/getBranchItineraries`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': token
      },
      body: JSON.stringify({})
    })    

    const result = await response.json()
    // Uncaught generic error
    if (response.ok === false || result.ok === false || !result.data) {
      throw RequestError.generic(this.constructor.name, result)
    }
    
    return toDomainGetBranchItineraries(result);
  }

  public async getItineraryTemplates(token: string): Promise<Itinerary[] | undefined> {
    const response = await fetch(`${this.HOST}/itineraries/getItineraryTemplates`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': token
      },
      body: JSON.stringify({})
    })    

    const result = await response.json()
    // Uncaught generic error
    if (response.ok === false || result.ok === false || !result.data) {
      throw RequestError.generic(this.constructor.name, result)
    }
    
    return toDomainGetItineraryTemplates(result);
  }

  public async createItinerary(token: string, itinerary:ItineraryCreate): Promise<number | undefined> {
    const response = await fetch(`${this.HOST}/itineraries/createItinerary`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': token
      },
      body: JSON.stringify(itinerary)
    })    

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false || !result.data) {
      throw RequestError.generic(this.constructor.name, result)
    }

    // plans not avaible
    if(result.data?.result === 'nm_au') {
      throw ItineraryError.itineraryNameAlreadyExist()
    }
    
    return toDomainCreateItinerary(result);
  }

  public async editItinerary(token: string, itinerary:ItineraryEdit): Promise<number | undefined> {
    const response = await fetch(`${this.HOST}/itineraries/editItinerary`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': token
      },
      body: JSON.stringify(itinerary)
    })    

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false || !result.data) {
      throw RequestError.generic(this.constructor.name, result)
    }
    
    return toDomainEditItinerary(result);
  }

  public async removeItinerary(token:string, id: number): Promise<'ok' | 'ko' | undefined> {
    const response = await fetch(`${this.HOST}/itineraries/removeItinerary`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': token
      },
      body: JSON.stringify({iid: id})
    })    

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false || !result.data) {
      throw RequestError.generic(this.constructor.name, result)
    }
    
    return toDomainRemoveItinerary(result);
  }
  
  public async getCourseContentTypes(id: number, token: string): Promise<ContentType[] | undefined> {
    const response = await fetch(`${this.HOST}/itineraries/getCourseContentTypes`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': token
      },
      body: JSON.stringify({cid: id})
    })    

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false || !result.data) {
      throw RequestError.generic(this.constructor.name, result)
    }
    
    return toDomainGetCourseContentTypes(result);
  }

  public async duplicateItinerary(token:string, id: number): Promise<'ok' | 'ko' | undefined> {
    const response = await fetch(`${this.HOST}/itineraries/duplicateItinerary`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': token
      },
      body: JSON.stringify({iid: id})
    })    

    const result = await response.json()

    // Uncaught generic error
    if(response.ok === false || result.ok === false || !result.data) {
      throw RequestError.generic(this.constructor.name, result)
    }

    if(result.data?.result !== 'ok') {
      throw ItineraryError.itineraryNameAlreadyExist()
    }
    
    return toDomainDuplicateItinerary(result);
  }

  public async getUsers(token: string, itineraryId: number): Promise < UserModel[] > {
    const response = await fetch(`${this.HOST}/itineraries/getUsers`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': token
      },
      body: JSON.stringify({ iid: itineraryId })
    })    
  
    const result = await response.json()
  
    // Uncaught generic error
    if(response.ok === false || result.ok === false || !result.data) {
      throw RequestError.generic(this.constructor.name, result)
    }
  
    return toDomainGetUsers(result);
  }
}
