<div class="bk-gradient opacity07">
    <div class="bk-gradient-green"></div>
    <div class="bk-gradient-blue"></div>
    <div class="bk-gradient-blue2"></div>
</div>
<app-nav [scrolled]="scrolled" *ngIf="!hasRoute('cart') && showNav"></app-nav>
<router-outlet [ngClass]="{promo: !showNav}" (window:scroll)="doSomethingOnInternalScroll($event)"></router-outlet>
<app-modal-error></app-modal-error>
<app-error-modal></app-error-modal>
<app-forgot-password-modal></app-forgot-password-modal>
<app-support></app-support>
<app-footer *ngIf="showFooter"></app-footer>
<app-side-popup></app-side-popup>
<app-loading-screen></app-loading-screen>
<app-info-btn-popup></app-info-btn-popup>
