import { ApplyCouponsRequest } from '../application/ApplyCoupons'
import { IPaidCart } from '../application/CartPaid'
import { CheckCouponsRequest } from '../application/CheckCoupons'
import { PharmacyPlanQuery } from '../application/PharmacyPlanRequest'
import { LicenseId, Cart, Paiment, Set, SetOwnerCart, PayConfirmationData, ApplyCouponsResponse, CheckCouponsResponse } from './Cart'

export abstract class CartRepository {
  abstract set({ userToken, identifier, license, saleCode }: { userToken?:string, identifier?: number, license: LicenseId[], saleCode?: string }): Promise<Set>
  abstract paid(params: IPaidCart): Promise<Paiment>
  abstract payConfirmation({ userToken, saleCode}: { userToken: string, saleCode: string}): Promise<PayConfirmationData>
  abstract get({ userToken, saleCode }: { userToken?: string, saleCode: string }): Promise<Cart>
  abstract setOwnerCart({ userToken, saleCode }: { userToken: string, saleCode: string }): Promise<SetOwnerCart>
  abstract pharmacyPlanRequest(params: PharmacyPlanQuery): Promise<boolean>
  abstract checkUsedDemo(userToken: string): Promise<boolean>
  abstract applyCoupons(params: ApplyCouponsRequest): Promise<ApplyCouponsResponse>
  abstract checkCoupons(params: CheckCouponsRequest): Promise<CheckCouponsResponse>
}
