import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PasswordForgot } from 'src/app/cross/account/application/PasswordForgot';
import { AccountErrorList } from 'src/app/cross/account/domain/AccountError';
import { LoggerProviderService } from 'src/app/services/logger/logger-provider.service';
import { TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  @Output() forgotPasswordSubmit = new EventEmitter<{emailSended: boolean, email: string}>();

  public formGroup!: FormGroup;
  public hasEmailError: boolean = false
  public emailMessageError: string = ''

  private readonly _accountForgotPass: PasswordForgot;

  constructor(
    private translateService: TranslateService,
    private loggerProviderService: LoggerProviderService,
    accountForgotPass: PasswordForgot
  ) { 
    this._accountForgotPass = accountForgotPass;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm() {
    this.formGroup = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
  }

  public onSubmit(){
    this.sendForm(this.formGroup?.value.email,)
  }

  public async sendForm(email: string) {
    this.checkErrorInputEmail()

    if(!this.formGroup.valid) {
      return
    }

    try {
      const result = await this._accountForgotPass.execute(email)

      if(result){
        //MOSTRAR EL LOGIN DE NUEVO
        this.forgotPasswordSubmit.emit({emailSended:result, email:email});
      }
    } catch (err: any) {
      const { message } = err
      this.loggerProviderService.error(err);

      if(message.includes(AccountErrorList.EMAIL_NOT_FOUND)) {
        this.hasEmailError = true
        this.emailMessageError =  this.translateService.translate('error.account.emailNotFound')
        return
      }
    }
  }

  checkErrorInputEmail(): void {
    this.hasEmailError = this.formGroup.get('email')?.hasError('required')
      || this.formGroup.get('email')?.hasError('email')
      || false

    this.emailMessageError = this.formGroup.get('email')?.hasError('required')
      ? this.translateService.translate('login.input_email_message_empty_error')
      : this.formGroup.get('email')?.hasError('email')
        ? this.translateService.translate('login.input_email_message_type_error')
        : ''
  }
}
