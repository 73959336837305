import { SubcriptionRepository } from '../domain/SubcriptionRepository'
import { Unsubscription } from '../domain/Subcription'

export class Unsubscribe {
  private readonly repository: SubcriptionRepository

  constructor(repository: SubcriptionRepository) {
    this.repository = repository
  }

  public async execute(
    accessToken: string,
    enrollmentId: number,
  ): Promise<Unsubscription | undefined> {
    return this.repository.unsubscribe(
      accessToken,
      enrollmentId
    )
  }
}
