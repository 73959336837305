import { SupportRepository } from '../domain/SupportRepository'

export class SupportRequest {
  private readonly repository: SupportRepository

  constructor(repository: SupportRepository) {
    this.repository = repository
  }

  public async execute(token: string, subject: string, description: string): Promise<boolean> {
    return this.repository.supportRequest(token, subject, description);
  }
}
