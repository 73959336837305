import { createAction, props } from '@ngrx/store';
import { Cart } from '../cross/cart/domain/Cart';
import { LicenseId } from '../cross/cart/domain/Cart';
 
export const addLicenseAction = createAction(
  '[License List] Add',
  props<{ licenseId: LicenseId }>()
);
 
export const removeLicenseAction = createAction(
  '[License List] Remove',
  props<{ licenseId: LicenseId }>()
);

export const emptyCartAction = createAction(
  '[License List] RemoveAll'
);

export const updateCartAction = createAction(
  '[Cart] Update',
  props<{ cart: Cart }>()
);