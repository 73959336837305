import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {

    isBrowser: boolean;

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
    ) {
        this.isBrowser = isPlatformBrowser(this.platformId);
    }

    public getElementById(id: string): HTMLElement | null {
        if(!this.isBrowser){
            return null
        }
        return document.getElementById(id) as HTMLElement | null;
    }

    public getElementsByTagName(tag: string): HTMLCollectionOf<Element> | null {
        if(!this.isBrowser){
            return null
        }
        return document.getElementsByTagName(tag) as HTMLCollectionOf<Element> | null;
    }

}