import { NgModule } from '@angular/core';
import { SupportComponent } from './support.component';
import { LfInputTextModule } from '../../atoms/lf_inputs/lf-input-text/lf-input.text.module';
import { LfTextAreaModule } from '../../atoms/lf_inputs/lf-text-area/lf-text-area.module';
import { ButtonModule } from '../../atoms/button/button.module';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        SupportComponent
    ],
    imports: [
        LfInputTextModule,
        LfTextAreaModule,
        ButtonModule,
        CommonModule
    ],
    exports: [
        SupportComponent
    ],
})
export class SupportModule { }