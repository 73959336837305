import { ItineraryRepository } from "../domain/ItineraryRepository"

export class RemoveItinerary {
  private readonly repository: ItineraryRepository

  constructor(repository: ItineraryRepository) {
    this.repository = repository
  }

  public async execute(token:string, id: number): Promise<'ok' | 'ko' | undefined> {
    return this.repository.removeItinerary(token, id)
  }
}
