// import { AbstractControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { BehaviorSubject, from, map, Observable, of, Subject, tap } from 'rxjs';
import { CheckBranchName } from '../cross/account/application/CheckBranchName';

@Injectable({
  providedIn: 'root'
})

export class CustomValidators {


  constructor(private http: HttpClient){}

  isNameSpaceValid(serv: CheckBranchName){
    return (control: AbstractControl)=>{
      const value = this.makeReadable(control.value);
      // if(value.length > 50)return null
      const observable = new Subject<any>();
      return from(serv.execute(value)).pipe(
          map((resp)=>{
            return resp? null : {avaible: true}
          })
        );
    }
  }

  makeReadable(val: string):string {
    return val.replace(/Ñ|ñ/g, 'n')
                .replace(/á|Á/g, 'a')
                .replace(/à|À/g, 'a')
                .replace(/_/g, '-')
                .replace(/ä|Ä/g, 'a')
                .replace(/â|Â/g, 'a')
                .replace(/é|É/g, 'e')
                .replace(/è|È/g, 'e')
                .replace(/ë|Ë/g, 'e')
                .replace(/ê|Ê/g, 'e')
                .replace(/í|Í/g, 'i')
                .replace(/ì|Ì/g, 'i')
                .replace(/ï|Ï/g, 'i')
                .replace(/î|Î/g, 'i')
                .replace(/ó|Ó/g, 'o')
                .replace(/ò|Ò/g, 'o')
                .replace(/ö|Ö/g, 'o')
                .replace(/ô|Ô/g, 'o')
                .replace(/ú|Ú/g, 'u')
                .replace(/ù|Ù/g, 'u')
                .replace(/ü|Ü/g, 'u')
                .replace(/û|Û/g, 'u')
                .replace(/ç|Ç/g, 'c')
                .replace(/[^a-z0-9_]+/gi, '-')
                .replace(/^-|-$/g, '').toLowerCase();
  }

}
