import { NgModule } from '@angular/core';
import { SidePopupComponent } from './side-popup.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        SidePopupComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        SidePopupComponent
    ],
})
export class SidePopupModule { }