import { NgModule } from '@angular/core';
import { InfoBtnPopupComponent } from './info-btn-popup.component';
import { TranslateModule } from 'src/app/pipes/translate.module';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        InfoBtnPopupComponent
    ],
    imports: [
        TranslateModule,
        CommonModule
    ],
    exports: [
        InfoBtnPopupComponent
    ],
})
export class InfoBtnPopupModule { }