import { Injectable } from '@angular/core';
import { StripeService } from "ngx-stripe";
import {
  StripeElements,
  StripeCardElement,
  StripeCardElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';
import { firstValueFrom } from 'rxjs';
import { PaymentProviderService } from './payment-provider.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentStripeProviderService implements PaymentProviderService {
  private stripeService: StripeService
  private card!: StripeCardElement
  private readonly visualConfiguration: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#000000'
        }
      }
    }
  }
  private readonly options: StripeElementsOptions = { locale: 'es' }

  constructor(
    stripeService: StripeService,
  ) {
    this.stripeService = stripeService
  }

  async createForm(divIdContainer: string) {
    const result: StripeElements = await firstValueFrom(this.stripeService.elements(this.options))

    if(!result) {
      throw new Error('ERROR: stripe html element not found')
    }

    this.card = result.create('card', this.visualConfiguration);
    this.card.mount(divIdContainer);
    return this.card
  }

  async createPaymentMethod() {
    const result = await firstValueFrom(this.stripeService.createPaymentMethod({type: 'card', card: this.card}))

    if(!result) {
      throw new Error('ERROR: stripe not return a token')
    }

    return result
  }
}
