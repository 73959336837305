import { NgModule } from '@angular/core';
import { ForgotPasswordModalComponent } from './forgot-password-modal.component';
import { ForgotPasswordModalRoutingModule } from './forgot-password-modal-router.module';
import { CommonModule } from '@angular/common';
import { InputModule } from '../../atoms/inputs/input/input.module';
import { TranslateModule } from 'src/app/pipes/translate.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '../../atoms/button/button.module';
import { ForgotPasswordModule } from '../../molecules/forgot-password/forgot-password.module';
import { ChangePasswordModule } from '../../molecules/change-password/change-password.module';

@NgModule({
    declarations: [
        ForgotPasswordModalComponent
    ],
    imports: [
        CommonModule,
        InputModule,
        TranslateModule,
        ReactiveFormsModule,
        ButtonModule,
        ForgotPasswordModule, 
        ChangePasswordModule
    ],
    exports: [
        ForgotPasswordModalComponent
    ]
})
export class ForgotPasswordModalModule { }