<form  [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div class="paragraph space-between ">
        <app-input
            type="text"
            [hasError]="hasEmailError"
            [messageError]="emailMessageError"
            [placeholder]="'login.input_mail' | translate"
            (change)="checkErrorInputEmail()"
            formControlName="email"
        ></app-input>
    </div>
    <app-button [type]="1" [submit]="true" text="{{'forgot_password.recover_btn' | translate}}"></app-button>
</form>