import { CrmRepository } from '../domain/CrmRepository'

export class AddToNewsLetter {
  private readonly repository: CrmRepository

  constructor(repository: CrmRepository) {
    this.repository = repository
  }

  public async execute(app: number, email: string, firstName: string, type?: number,): Promise<boolean | undefined> {
    if (type){
      return this.repository.addToNewsLetter(app, email, firstName, type);
    }
    return this.repository.addToNewsLetter(app, email, firstName);
  }
}
