import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LottieAnimationComponent } from './lottie-animation.component';

@NgModule({
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  declarations: [
    LottieAnimationComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LottieAnimationComponent
  ]
})
export class LottieAnimationModule { }
