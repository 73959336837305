<input
  #input
  [type]="type"
  [class.error]="hasError"
  [placeholder]="placeholder"
  [value]="value"
  (input)="onInput($event)"
  class="glass-2 lf-p-5 regular"
  type="text"
  autocomplete="on"
  [attr.maxlength]="limitCharacter ? limitCharacter : null"
>
<p *ngIf="hasError" class="msg_error lf-p-5 semibold">{{messageError}}</p>
