import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LfInputTextComponent } from '../../atoms/lf_inputs/lf-input-text/lf-input-text.component';
import { LfTextAreaComponent } from '../../atoms/lf_inputs/lf-text-area/lf-text-area.component';
import { SupportRequest } from 'src/app/cross/support/application/SupportRequest';
import { StorageService } from 'src/app/services/storage.service';
import { MaganeSidePopupService } from '../../atoms/side-popup/magane-side-popup.service';
import { LoggerProviderService } from 'src/app/services/logger/logger-provider.service';
import { ManageSupportChatService } from 'src/app/services/manageSupportChat/manage-support-chat.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  @ViewChild('themeInput') themeInput!: LfInputTextComponent;
  @ViewChild('descInput') descInput!: LfTextAreaComponent;

  constructor(
    private supportRequest: SupportRequest,
    private popupService: MaganeSidePopupService,
    private storageService: StorageService,
    private manageSupportChatService: ManageSupportChatService,
    private loggerProviderService: LoggerProviderService,
    public router: Router
  ) { }

  isVisibleSelectSpaces: boolean = false;
  buttonAvaible: boolean = false;
  sendAvaible: boolean = false;
  modalOpen: boolean = false;
  iconSrc: string = '../../../../assets/svg/liceo-icons/soporte1.svg'

  ngOnInit(): void {
    this.manageSupportChatService.getShowSupportBtn$().subscribe(
      showBtn => this.buttonAvaible = showBtn
    );
  }

  onChangeTheme(event: any) {
    this.checkSendAvaible();
  }

  onChangeDescription(event: any) {
    this.checkSendAvaible();
  }

  checkSendAvaible() {
    this.sendAvaible = this.themeInput.getValue() != '' && this.descInput.getValue() != '';
  }

  toggleModal() {
    this.modalOpen = !this.modalOpen;
  }

  onMouseEnter() {
    this.iconSrc = '../../../../assets/svg/liceo-icons/soporte2.svg'
  }
  onMouseLeave() {
    this.iconSrc = '../../../../assets/svg/liceo-icons/soporte1.svg'
  }

  onSendSupportRequest() {
    this.sendSupportRequest();
  }

  resetForm() {
    this.themeInput.setValue('');
    this.descInput.setValue('');
    this.sendAvaible = false;
    this.toggleModal();
  }

  async sendSupportRequest() {
    try {
      const token = this.storageService.find(this.storageService.SESSION_TOKEN);
      if (token == undefined) {
        this.router.navigate(['/login']);
        return;
      }
      const result = await this.supportRequest.execute(token, this.themeInput.getValue(), this.descInput.getValue());
      if (result != undefined) {

        this.popupService.openPopup({
          text: '¡Consulta enviada con éxito!',
          iconName: 'success',
          underlineColor: 'green',
        });
        this.resetForm();
      }
    } catch (err: any) {
      const { message } = err
      this.loggerProviderService.error(err);
      this.popupService.openPopup({
        text: '¡Lo sentimos! Ha habido un error',
        iconName: 'error',
        underlineColor: 'pink',
      });
      this.resetForm();
    }
  }
}


