import { CourseRepository } from '../domain/CourseRepository'
import {  Courses } from '../domain/Course'

export class GetAllCourses {
  private readonly repository: CourseRepository

  constructor(repository: CourseRepository) {
    this.repository = repository
  }

  public async execute(limit?: number): Promise<Courses | undefined> {
    if(limit){
      return this.repository.getAll(limit)
    }
    return this.repository.getAll()
  }
}
