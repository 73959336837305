import { Component, OnInit } from '@angular/core';
import { InfoBtnPopupService } from './info-btn-popup.service';
import { Sponsor } from 'src/app/cross/dashboard/domain/Dashboard';
import { Environments } from 'src/app/utils/Environments';

@Component({
  selector: 'app-info-btn-popup',
  templateUrl: './info-btn-popup.component.html',
  styleUrls: ['./info-btn-popup.component.scss']
})
export class InfoBtnPopupComponent implements OnInit {

  constructor(
    private manageInfoPopup: InfoBtnPopupService,
  ) { }

  popupData: InfoBtnPopupData = {
    title: '',
    description: '',
    sponsors: []
  }
  popupOpen: boolean = false;
  imgSponsorUrl: string = Environments.getS3UrlEnvironment()+'/assets/webp/sponsors/mobile/';

  ngOnInit(): void {
    this.manageInfoPopup.actioninfoPopUp$().subscribe(
      showInfoPopUp => {  
        if(!showInfoPopUp.show){
          this.closePopup();
          this.activateScroll();
        }else{
          if(showInfoPopUp.data)
          this.openPopup(showInfoPopUp.data);
          this.deactivateScroll();
        }
    })
  }

  closeModal(){
    this.closePopup();
    this.activateScroll();
  }

  openPopup(data: InfoBtnPopupData){
    this.popupData = data;
    this.popupOpen = true;
  }

  closePopup(){
    this.popupOpen = false;
    this.resetPopup();
  }

  resetPopup(){
    this.popupData = {
      title: '',
      description: '',
      sponsors: []
    }
  }

  activateScroll(){
    document.getElementsByTagName('body')[0].setAttribute('style', 'overflow: inherit;')
    document.getElementsByTagName('html')[0].setAttribute('style', 'overflow: inherit;')
  }
  
  deactivateScroll(){
    document.getElementsByTagName('body')[0].setAttribute('style', 'overflow: hidden;')
    document.getElementsByTagName('html')[0].setAttribute('style', 'overflow: hidden;')
  }
}

export interface InfoBtnPopupData {
  title: string;
  description: string;
  sponsors: Sponsor[];
}
