import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AccountLogout } from '../cross/account/application/AccountLogout';
import { Account, GROUP_RESPONSIBLE, PHARMACY_HOLDER_NO_GROUP, PHARMACY_HOLDER_GROUP, STUDENT, SUPER_ADMIN } from '../cross/account/domain/Account';
import { GetSubscriptions } from '../cross/subcription/application/GetSubscriptions';
import { LoggedService } from './logged.service';
import { LoggerProviderService } from './logger/logger-provider.service';
import { SubcriptionErrorList } from 'src/app/cross/subcription/domain/SubcriptionError';
import { StorageService } from './storage.service';
import { Observable, Subject } from 'rxjs';
import { CheckSubscriptions } from '../cross/subcription/application/CheckSubscriptions';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  private readonly loggerProviderService: LoggerProviderService;
  private readonly _checkSubscription: CheckSubscriptions;
  private readonly _accountLogout: AccountLogout;
  private readonly router: Router;
  private isLog$: Subject<boolean>;
  private activePlans$: Subject<boolean>;

  constructor(
    router: Router,
    loggerProviderService: LoggerProviderService,
    private loggedService: LoggedService,
    private storageService: StorageService,
    checkSubscription: CheckSubscriptions,
    accountLogout: AccountLogout,
  ) {
    this.isLog$ = new Subject();
    this.activePlans$ = new Subject();
    this.router = router
    this._checkSubscription = checkSubscription;
    this._accountLogout = accountLogout;
    this.loggerProviderService = loggerProviderService
  }

  private userData: Account | undefined = undefined  
  private isLogged: boolean = false;
  private hasActivePlans: boolean = true;

  public getToken(){  
    return !!this.storageService.find('token')
  }  

  public isLogin(): boolean{
    return this.isLogged
  }

  public hasActivePlan(): boolean{
    return this.hasActivePlans;
  }

  public isLoginChange(): Observable<boolean>{
    return this.isLog$.asObservable();
  }
  public hasActivePlanChange(): Observable<boolean>{
    return this.activePlans$.asObservable();
  }

  public async isPharmacyHolder():Promise<boolean>{
    const user = await JSON.parse(this.storageService.find(this.storageService.USER_DATA))
    return user.typeLms === PHARMACY_HOLDER_NO_GROUP || user.typeLms === PHARMACY_HOLDER_GROUP;
  }
  
  public async isPharmacyHolderWithoutGroup():Promise<boolean>{
    const user = await JSON.parse(this.storageService.find(this.storageService.USER_DATA))
    return user.typeLms === PHARMACY_HOLDER_NO_GROUP;
  }
  
  public async isPharmacyHolderWithGroup():Promise<boolean>{
    const user = await JSON.parse(this.storageService.find(this.storageService.USER_DATA))
    return user.typeLms === PHARMACY_HOLDER_GROUP;
  }
  
  public async isStudent(): Promise<boolean>{
    const user = await JSON.parse(this.storageService.find(this.storageService.USER_DATA))
    return user.typeLms === STUDENT;
  }
  
  public async isGroupResponsible(): Promise<boolean>{
    const user = await JSON.parse(this.storageService.find(this.storageService.USER_DATA))
    return user.typeLms === GROUP_RESPONSIBLE;
  }
  
  public async isSuperAdmin(): Promise<boolean>{
    const user = await JSON.parse(this.storageService.find(this.storageService.USER_DATA))
    return user.typeLms === SUPER_ADMIN;
  }

  public async updateStatus(){
    await this.setIsLogged();
    const token = await this.storageService.find(this.storageService.SESSION_TOKEN);
    await this.getActivePlans(token);
  }

  async setIsLogged(){
    this.userData = await JSON.parse(this.storageService.find(this.storageService.USER_DATA))
    this.isLogged = this.userData != undefined;
    this.isLog$.next(this.isLogged);
  }

  private async getActivePlans(token: string) {
    try {
      if(!token){
        this.hasActivePlans = false
        this.activePlans$.next(this.hasActivePlans);
        return;
      } 
      const result = await this._checkSubscription.execute(token)
      this.hasActivePlans = (result != undefined && result)
      this.activePlans$.next(this.hasActivePlans);
    } catch (err: any) {
      const { message } = err
      this.hasActivePlans = false
      this.activePlans$.next(this.hasActivePlans);
      this.loggerProviderService.error(err);
      if(message.includes(SubcriptionErrorList.EXPIRED_SESSION) && this.userData) {
        this.isLogged = false;
        return
      }
    }
  }

  private async logOut(personId: number, token: string) {
    const result = await this._accountLogout.execute(personId, token)
    if (result) {
      this.storageService.clearSessionData();
      this.loggedService.logOut();
    }
  }
}  
