import { environment } from '../../../../environments/environment'
import { RequestError } from '../../share/domain/RequestError'
import { Certificate, toDomainGetCategories } from '../domain/Certificate'
import { CertificateRepository } from '../domain/CertificateRepository'

export class CertificateRepositoryRest extends CertificateRepository {
  private readonly HOST = environment.host
  private readonly PCEK = environment.pcek

  public async getCertificatePublicData(certificateId: string): Promise<Certificate | undefined> {
    const response = await fetch(`${this.HOST}/lms/getCertificatePublicData`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK
      },
      body: JSON.stringify({ ceid: certificateId })
    })

    let result;
    try {
      result = await response.json();
    } catch (error) {
      throw RequestError.generic(this.constructor.name, {err: "Unexpected end of JSON input", data: response})
    }

    // Uncaught generic error
    if(response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    return toDomainGetCategories(result);
  }
}
