import { NgModule } from '@angular/core';
import { ChangePasswordComponent } from './change-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputModule } from '../../atoms/inputs/input/input.module';
import { ButtonModule } from '../../atoms/button/button.module';
import { TranslateModule } from 'src/app/pipes/translate.module';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        ChangePasswordComponent
    ],
    imports: [
        FormsModule,
        InputModule,
        CommonModule,
        ButtonModule,
        ReactiveFormsModule,
        TranslateModule
    ],
    exports: [
        ChangePasswordComponent
    ],
})
export class ChangePasswordModule { }