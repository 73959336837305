import { PlanRepository } from '../domain/PlanRepository'
import { Plan } from '../domain/Plan'

export class GetAllPlans {
  private readonly repository: PlanRepository

  constructor(repository: PlanRepository) {
    this.repository = repository
  }

  public async execute(): Promise<Plan[] | undefined> {
    return this.repository.getAll()
  }
}
