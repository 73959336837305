export interface Unsubscription {
  expirationDate: string
}

export interface ActivePlans {
  licenses: {
    status: 'trialing' | 'active',
    start?: string,
    end: string,
    lid: number;
    cancel_at_period_end?: boolean,
    enrollment_id: number,
    name: string,
    periodicity: number,//1: anual, 2: mensual
    price: string,
    info: string[]
  }[]
}

export interface ResponseGetSubscription {
  data: {
    getSubscriptions:{
      ens: {
        status: 'trialing' | 'active',
        start?: string,
        lid: number,
        end: string,
        cancel_at_period_end?: boolean,
        eid: number,
        off_dp: string,
        off_fs: string[],
        off_nm: string,
        per: number,
        pr: string
      }[]
      result: 'ok' | 'ko'
    }
  }
  ok: boolean
}

export interface ResponseUnsubscription {
  data: {
    unsubscribe: {
      exd: string
    },
    result: 'ok' | 'ko'
  }
  ok: boolean
}
export interface ResponseCheckSubscriptions {
  data: {
    checkSubscriptions: {
      ten: number
    },
    result: 'ok' | 'ko'
  }
  ok: boolean
}

export const toDomainCheckSubscriptions = function(response: ResponseCheckSubscriptions): boolean {
  return response.data.checkSubscriptions.ten > 0;
}
export const toDomainUnsubscription = function(response: ResponseUnsubscription): Unsubscription {
  return {
    expirationDate: response.data.unsubscribe.exd
  }
}

export const toDomainActivePlans = function(response: ResponseGetSubscription): ActivePlans {
  const plans: ActivePlans = {licenses: []};
  response.data.getSubscriptions.ens.forEach((plan)=>{
    plans.licenses.push({
      status: plan.status,
      end: plan.end,
      ...(plan.start && {start: plan.start}),
      ...(plan.cancel_at_period_end && {cancel_at_period_end: plan.cancel_at_period_end}),
      enrollment_id: plan.eid,
      name: plan.off_nm,
      lid: plan.lid,
      periodicity: plan.per,
      price: plan.pr,
      info: plan.off_fs
    })
  });
  return plans;
}
