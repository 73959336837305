import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent {

  @Input() userName: string = ''
  @Input() options: string[] = [];
  @Input() alignRight: boolean = false;
  @Input() isReducedDesign: boolean = false;

  @Output() optionSelected: EventEmitter<string> = new EventEmitter();

  @ViewChild('menu') menu: ElementRef | undefined;

  showMenu: boolean = false;

  private globalClickListener: Function | null = null;

  constructor(
    private renderer: Renderer2,
    private GoogleAnalyticsService: GoogleAnalyticsService
  ) { }

  public toggleMenu() {
    this.showMenu = !this.showMenu;
    if (this.showMenu) {
      this.GoogleAnalyticsService.eventEmitter('click', { area: 'aula', pag: 'stats', subsec: 'profile', act: 'open' })
      // Start listening for a click outside the menu
      this.globalClickListener = this.renderer.listen('window', 'click', (e: Event) => {
        if (!this.menu?.nativeElement.contains(e.target)) {
          this.showMenu = false;
          this.GoogleAnalyticsService.eventEmitter('click', { area: 'aula', pag: 'stats', subsec: 'profile', act: 'close' })
          // Stop listening for a click outside the menu
          if (this.globalClickListener) {
            this.globalClickListener();
            this.globalClickListener = null;
          }
        }
      });
    } else {
      this.GoogleAnalyticsService.eventEmitter('click', { area: 'aula', pag: 'stats', subsec: 'profile', act: 'close' })
      // Stop listening for a click outside the menu
      if (this.globalClickListener) {
        this.globalClickListener();
        this.globalClickListener = null;
      }
    }
  }

  public onClickOption(opt: string) {
    this.optionSelected.emit(opt);
  }

}
