import { DashboardRepository } from '../domain/DashboardRepository'
import { Itinerary } from '../domain/Dashboard'

export class GetItineraries {
  private readonly repository: DashboardRepository

  constructor(repository: DashboardRepository) {
    this.repository = repository
  }

  public async execute(accessToken: string, idLms?: number): Promise<Itinerary[] | undefined> {
    if(idLms){
      return this.repository.GetItineraries(accessToken, idLms)
    }
    return this.repository.GetItineraries(accessToken)
  }
}