import { AccountRepository } from '../domain/AccountRepository'
import { Account, AutoLoginAccount } from '../domain/Account'
import { EventAction } from '../../share/domain/EventAction'

export class AutoLogin {
  private readonly repository: AccountRepository

  constructor(repository: AccountRepository) {
    this.repository = repository
  }

  public async execute(token: string): Promise<AutoLoginAccount | undefined> {
    return this.repository.autoLogin(token)
  }
}
