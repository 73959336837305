import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class VisibilityService {

    private showNav$: Subject<boolean>;
    private showFooter$: Subject<boolean>;

    constructor() {
        this.showNav$ = new Subject();
        this.showFooter$ = new Subject();
    }

    getShowNav$(): Observable<boolean> {
        return this.showNav$.asObservable();
    }
    getShowFooter$(): Observable<boolean> {
        return this.showFooter$.asObservable();
    }
    
    hideNav() {
        this.showNav$.next(false);
    }
    
    showNav() {
        this.showNav$.next(true);
    }

    hideFooter() {
        this.showFooter$.next(false);
    }
    
    showFooter() {
        this.showFooter$.next(true);
    }

}
