import { CustomError } from "../../share/domain/CustomError"

export enum DashboardErrorList {
  WITHOUT_CERTIFICATE = 'Course without certificate',
}

export class DashboardError extends Error implements CustomError {
  public override readonly name: string = 'dashboard.error'

  public static withoutCertificate (): DashboardError {
    return new this(DashboardErrorList.WITHOUT_CERTIFICATE)
  }
}