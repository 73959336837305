import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { WINDOW } from '@ng-web-apis/common';
@Injectable({
    providedIn: 'root'
})
export class ManageSupportChatService {

    private showSupportBtn$: Subject<boolean>;

    enableUrl: string[] = [
        'aula'
    ]

    constructor(
        @Inject(WINDOW) private window: Window
    ) {
        this.showSupportBtn$ = new Subject();
    }

    checkUrl() {
        const url = this.window.location.href;
        const enable = this.enableUrl.some(u => url.includes(u));
        if (enable) {
            this.show();
        } else {
            this.hide();
        }
    }

    getShowSupportBtn$(): Observable<boolean> {
        return this.showSupportBtn$.asObservable();
    }
    
    hide() {
        this.showSupportBtn$.next(false);
    }
    
    show() {
        this.showSupportBtn$.next(true);
    }

}
