import { CheckboxProperties } from "src/app/components/atoms/inputs/input-checkbox/input-checkbox.component"

export interface Course {
  courseId: number
  name: string
  categoryId: string
  description: string
  lastUpdated: string
  path: string
}

export interface Courses {
  courses: Course[]
  total_query_rows: number
}

export interface Category {
  categoryId: string
  position: number
}

export interface Content {
  position: number;
  topic: string;
  content: {
    name: string;
    type: number;
    position: number;
  }[];
}

export interface CourseDetails {
  courseId: number
  name: string
  categoryId: string
  description: string
  content: {
    type: number
    quantity: number
    name: string
  }[],
  video: string
  target: number[]
  created: string
  createdBio: string
  imparted: string
  impartedBio: string
  goals: string[]
  lastUpdated: string
}

export interface ResponseCourseGetAll {
  data: {
    getCourses: {
      tp: number
      tqr: number
      courses: {
        cid: number
        cnm: string
        ctid: string
        des: string
        lu: string
        path: string
      }[]
    }
    result: 'ok' | 'ko'
  }
  ok: boolean
}
export interface ResponseCourseGetCategories {
  data: {
    getCategories: {
      cl: {
        ctid: string,
        pos: number
      }[]
    },
    result: 'ok' | 'ko'
  },
  ok: boolean
}

export interface ResponseCourseGetProgram {
  data: {
    getProgram: {
      prg: {
        cnt: {
          cntn: string,
          cntt: number,
          pos: number
        }[],
        pos: number,
        top: string
      }[]
    },
    result: 'ok' | 'ko'
  },
  ok: boolean
}

export interface ResponseCourseGetById {
  data: {
    getCourse: {
      cid: number
      cnm: string
      ctid: string
      des: string
      cnt: {
        cntt: number
        qt: number
        cntn: string
      }[],
      vi: string
      tg: number[]
      cr: string
      cr_b: string
      im: string
      im_b: string
      go: string[]
      lu: string
    },
    result: 'ok' | 'ko'
  }
  ok: boolean
}

export const setCategoriesByCategoryList = function (availableCategories: string[]): CheckboxProperties[] {
  // const categories = availableCategories.filter((c)=>c != null).map((category) => {
  //     return {
  //       value: category.charAt(0).toUpperCase() + category.toLowerCase().slice(1),
  //       checkbox_state: false,
  //     }
  // });
  const categories = availableCategories
  .sort((a, b) => a.localeCompare(b)) // Ordenar el array por orden alfabético
  .filter((c) => c != null)
  .map((category) => {
    return {
      value: category.charAt(0).toUpperCase() + category.toLowerCase().slice(1),
      checkbox_state: false,
    };
  });
  return categories?? [];
}

export const setAuthorsByAuthorsList = function (availableAuthors: string[]): CheckboxProperties[] {
  const authors = availableAuthors
  .sort((a, b) => a.localeCompare(b))
  .filter((c) => c != null)
  .map((author) => {
    return {
      value: author,
      checkbox_state: false,
    };
  });
  return authors?? [];
}

export const toDomainGetAll = function (response: ResponseCourseGetAll): Courses {
  const courses: Course[] = response.data.getCourses.courses.map((item) => ({
    courseId: item.cid,
    name: item.cnm,
    categoryId: item.ctid,
    description: item.des,
    lastUpdated: item.lu,
    path: item.path
  }))
  return {
    courses: courses,
    total_query_rows: response.data.getCourses.tqr
  }
}

export const toDomainGetCategories = function (response: ResponseCourseGetCategories): Category[] {
  return response.data.getCategories.cl.map((item) => ({
    categoryId: item.ctid,
    position: item.pos
  }))
}

export const toDomainGetProgram = function (response: ResponseCourseGetProgram): Content[] {
  return response.data.getProgram.prg.map(program => ({
    position: program.pos,
    topic: program.top,
    content: program.cnt.map(item => ({
      name: item.cntn,
      type: item.cntt,
      position: item.pos
    }))
  }))
}

export const toDomainGetById = function (response: ResponseCourseGetById): CourseDetails {
  return {
    courseId: response.data.getCourse.cid,
    name: response.data.getCourse.cnm,
    categoryId: response.data.getCourse.ctid,
    description: response.data.getCourse.des,
    content: response.data.getCourse.cnt.map(item => ({
      type: item.cntt,
      quantity: item.qt,
      name: item.cntn
    })),
    video: response.data.getCourse.vi,
    target: response.data.getCourse.tg,
    created: response.data.getCourse.cr,
    createdBio: response.data.getCourse.cr_b,
    imparted: response.data.getCourse.im,
    impartedBio: response.data.getCourse.im_b,
    goals: response.data.getCourse.go,
    lastUpdated: response.data.getCourse.lu
  }
}
