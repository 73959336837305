export interface ResponseSupportRequest {
    data: {
        getCertificatePublicData: string,
        result: 'ok' | 'ko'
    }
    ok: boolean
}

export const toDomainsupportRequest = function (response: ResponseSupportRequest): boolean {
    return response.data.getCertificatePublicData === 'ok';
}
