import { Category, Content, Course, CourseDetails, Courses } from './Course'

export abstract class CourseRepository {
  abstract getCategories(): Promise<Category[] | undefined>
  abstract getAll(limit?: number): Promise<Courses | undefined>
  abstract getLastCourses(limit?: number): Promise<Courses | undefined>
  abstract getById(path: string): Promise<CourseDetails | undefined>
  abstract getProgram(courseId: number): Promise<Content[] | undefined>
  abstract getByCategory({ identifier, category, limit }: { identifier?: string, category: string[], limit?: number }): Promise<Course[] | undefined>
  // abstract getByKeyWord(search: string): Promise<Course[] | undefined>
  abstract getFilteredCourses({ identifier, category, search, limit, order, target }: { identifier?: string, category?: string[], search?: string, limit?: number, order?: number, target?: number[]}): Promise<Courses | undefined>
}
