import { DashboardRepository } from '../domain/DashboardRepository'
import { AvailableNew, CertificateData, CourseProgress, GroupDashboard, GroupData, Itinerary, PharmacyDashboard, PharmacyDashboardFilterCourseList, PharmacyDashboardFilterItineraryList, PharmacyData, toDomainDownloadGroupStats, toDomainDownloadPharmacyStats, toDomainEnrrollInTheCourse, toDomainGetAvailableNews, toDomainGetCertificateData, toDomainGetCourses, toDomainGetGroupDashboard, toDomainGetGroupData, toDomainGetItineraries, toDomainGetPharmacyDashboard, toDomainGetPharmacyData, toDomainGetUrlFromCourse, toDomainPharmacyDashboardCourseList, toDomainPharmacyDashboardItineraryList, toDomainReportHolderByUser, UserData } from '../domain/Dashboard'
import { environment } from '../../../../environments/environment'
import { RequestError } from '../../share/domain/RequestError'
import { DashboardError } from '../domain/DashboardError'

export class DashboardRepositoryRest extends DashboardRepository {
  private readonly HOST = environment.host
  private readonly PCEK = environment.pcek

  public async ReportHolderByUser(accessToken: string, personId?: number): Promise<UserData | undefined> {
    const response = await fetch(`${this.HOST}/lms/reportHoldersByUser`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': accessToken
      },
      body: JSON.stringify({ ...(personId && { pid_lms: personId }) })//de donde saco el id de talent lms
    })

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    return toDomainReportHolderByUser(result);
  }

  public async GetCourses(accessToken: string, personId?: number): Promise<CourseProgress[] | undefined> {

    // const response1 = await fetch(`${this.HOST}/itineraries/getUserCourses`, {
    //   method: 'POST',
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json',
    //     'pcek': this.PCEK,
    //     '_at': accessToken
    //   },
    //   body: JSON.stringify({})
    // })    
    const response = await fetch(`${this.HOST}/lms/getTheCoursesByUser`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': accessToken
      },
      body: JSON.stringify({ ...(personId && { pid_lms: personId }) })//de donde saco el id de talent lms
    })

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    return toDomainGetCourses(result);
  }

  public async GetUrlFromCourse(branchName: string, courseId: string, accessToken: string): Promise<string | undefined> {
    const response = await fetch(`${this.HOST}/lms/getUrlFromCourse`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': accessToken
      },
      body: JSON.stringify({ bnm: branchName, cid: courseId })//de donde saco el id de talent lms
    })
    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    return toDomainGetUrlFromCourse(result);
  }

  public async EnrrollInTheCourse(branchName: string, courseId: string, accessToken: string): Promise<string | undefined> {
    const response = await fetch(`${this.HOST}/lms/enrollInTheCourse`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': accessToken
      },
      body: JSON.stringify({ bnm: branchName, cid: +courseId })
    })
    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    return toDomainEnrrollInTheCourse(result);
  }

  public async GetCertificateData(courseId: string, accessToken: string): Promise<CertificateData | undefined> {
    const response = await fetch(`${this.HOST}/lms/getCertificateData`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': accessToken
      },
      body: JSON.stringify({ cid: +courseId })
    })
    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    // email invalid field
    if (result.data?.result === 'wc') {
      throw DashboardError.withoutCertificate()
    }

    return toDomainGetCertificateData(result);
  }

  public async GetItineraries(accessToken: string, idLms?: number): Promise<Itinerary[] | undefined> {
    const response = await fetch(`${this.HOST}/lms/getItinerariesByUser`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': accessToken
      },
      body: JSON.stringify({ ...(idLms && { pid_lms: idLms }) })//de donde saco el id de talent lms
    })

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    return toDomainGetItineraries(result);
  }

  public async GetPharmacyDashboard(accessToken: string, pharmacyId?: string): Promise<PharmacyDashboard | undefined> {
    const response = await fetch(`${this.HOST}/lms/getPharmacyDashboard`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': accessToken
      },
      body: JSON.stringify({ ...(pharmacyId && { py: pharmacyId }) })
    })

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    return toDomainGetPharmacyDashboard(result);
  }

  public async GetGroupDashboard(accessToken: string): Promise<GroupDashboard | undefined> {
    const response = await fetch(`${this.HOST}/lms/getGroupDashboard`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': accessToken
      },
      body: JSON.stringify({})
    })

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    return toDomainGetGroupDashboard(result);
  }

  public async GetAvailableNews(accessToken: string): Promise<AvailableNew[] | undefined> {
    const response = await fetch(`${this.HOST}/lms/getAvailableNews`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': accessToken
      },
      body: JSON.stringify({})
    })

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    return toDomainGetAvailableNews(result);
  }

  public async MarkNewsAsViewed(accessToken: string, listNews: number[]): Promise<boolean> {
    const response = await fetch(`${this.HOST}/lms/markNewsAsViewed`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': accessToken
      },
      body: JSON.stringify({ nl: listNews })
    })

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    return result;
  }

  public async GetPharmacyData(accessToken: string, showFilter: string, pharmacyId: number, filterData?: { order?: string, search?: string, itinerary?: string, course?: string }): Promise<PharmacyData | undefined> {
    const pyid = pharmacyId != -1 ? pharmacyId : undefined;
    const response = await fetch(`${this.HOST}/lms/getPharmacyStats`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': accessToken
      },
      body: JSON.stringify({
        sw: showFilter,
        ...(pyid && { py: pyid }),
        ...(filterData?.itinerary && { iid: filterData.itinerary }),
        ...(filterData?.course && { cid: filterData.course }),
        ...(filterData?.search && { qh: filterData.search }),
      })
    })

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    return toDomainGetPharmacyData(result);
  }

  public async GetGroupData(accessToken: string, showFilter: string, filterData?: { order?: string, search?: string, itinerary?: string, course?: string }): Promise<GroupData | undefined> {
    // const pyid = pharmacyId != -1 ? pharmacyId : undefined;
    const response = await fetch(`${this.HOST}/lms/getPharmacyGroupStats`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': accessToken
      },
      body: JSON.stringify({
        sw: showFilter,
        // ...(pyid && {py: pyid}),
        ...(filterData?.itinerary && { iid: filterData.itinerary }),
        ...(filterData?.course && { cid: filterData.course }),
        ...(filterData?.search && { qh: filterData.search }),
      })
    })

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }
    try {
      return toDomainGetGroupData(result);
    } catch (error) {
      return undefined;
    }
  }

  public async DownloadGroupStats(accessToken: string, showFilter: string, filterData?: { order?: string, search?: string, itinerary?: string, course?: string }): Promise<string | undefined> {
    const response = await fetch(`${this.HOST}/lms/getDownloadPharmacyGroupStats`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': accessToken
      },
      body: JSON.stringify({
        sw: showFilter,
        ...(filterData?.itinerary && { iid: filterData.itinerary }),
        ...(filterData?.course && { cid: filterData.course }),
        ...(filterData?.search && { qh: filterData.search }),
      })
    })

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    return toDomainDownloadGroupStats(result);
  }

  public async DownloadPharmacyStats(accessToken: string, showFilter: string, pharmacyId: number, filterData?: { order?: string, search?: string, itinerary?: string, course?: string }): Promise<string | undefined> {
    const pyid = pharmacyId != -1 ? pharmacyId : undefined;
    const response = await fetch(`${this.HOST}/lms/getDownloadPharmacyStats`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': accessToken
      },
      body: JSON.stringify({
        sw: showFilter,
        ...(pyid && { py: pyid }),
        ...(filterData?.itinerary && { iid: filterData.itinerary }),
        ...(filterData?.course && { cid: filterData.course }),
        ...(filterData?.search && { qh: filterData.search }),
      })
    })

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    return toDomainDownloadPharmacyStats(result);
  }

  public async GetPharmacyDashboardFilterItinerary(accessToken: string, pharmacyId?: number): Promise<PharmacyDashboardFilterItineraryList | undefined> {

    const response = await fetch(`${this.HOST}/lms/getItineraryList`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': accessToken
      },
      body: JSON.stringify({ ...(pharmacyId && { py: pharmacyId }) })
    })

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    return toDomainPharmacyDashboardItineraryList(result);
  }

  public async GetPharmacyDashboardFilterCourses(accessToken: string, pharmacyId?: number): Promise<PharmacyDashboardFilterCourseList | undefined> {
    const response = await fetch(`${this.HOST}/lms/getCourseList`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pcek': this.PCEK,
        '_at': accessToken
      },
      body: JSON.stringify({ ...(pharmacyId && { py: pharmacyId }) })
    })

    const result = await response.json()

    // Uncaught generic error
    if (response.ok === false || result.ok === false) {
      throw RequestError.generic(this.constructor.name, result)
    }

    return toDomainPharmacyDashboardCourseList(result);
  }

}
