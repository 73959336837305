import { NgModule } from '@angular/core';
import { LoadingScreenComponent } from './loading-screen.component';
import { CommonModule } from '@angular/common';
import { LottieAnimationModule } from '../lottie-animation/lottie-animation.module';

@NgModule({
    declarations: [
        LoadingScreenComponent
    ],
    imports: [
        CommonModule,
        LottieAnimationModule
    ],
    exports: [
        LoadingScreenComponent
    ],
})
export class LoadingScreenModule { }